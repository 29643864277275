import React, {ReactElement} from "react";
import Select from "react-select";
import {Props} from "react-select/dist/declarations/src/Select";
import {GroupBase} from "react-select/dist/declarations/src/types";
import {omit} from "lodash";
import classNames from "classnames";

function FrameOneReactSelect<T, U extends boolean, V extends GroupBase<T>>(props: Partial<Props<T, U, V>>): ReactElement {


	return (
		<Select
			{...omit(props, "className")}
			className={classNames("frame-one-react-select", props.className)}
		/>
	);
}

export default FrameOneReactSelect;
