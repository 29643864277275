import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {GetPartnerAdminsResponse, Partner, PartnerAdmin, Token, UsersApi} from "client";
import {IStore} from "../redux/defaultStore";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import {Input} from "reactstrap";
import FrameButton from "../components/buttons/FrameButton";
import {AiOutlinePlus} from "react-icons/all";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import {generateUserFullName} from "../utils/generateUserFullName";
import ManagePartnerAdminsAddModal from "../components/modals/ManagePartnerAdminsAddModal";
import {valueOrBustDynamic} from "../utils/valueOrBust";
import ManagePartnerAdminsActionsCell from "../components/tables/cells/ManagePartnerAdminsActionsCell";
import {convertDisabledStatusToString} from "../utils/convertDisabledStatusToString";

interface IProps {
	dispatch?: any;
	fullToken: Token;
}

const ManagePartnerAdmins: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>("");
	const [firstRender, setFirstRender] = useState(true);
	const [showAddNewPartnerAdminModal, setShowAddNewPartnerAdminModal] = useState<boolean>(false);
	const [partnerAdmins, setPartnerAdmins] = useState<GetPartnerAdminsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readPartnerAdmins().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input.
	 * After timeout completes API is called with search property.
	 *
	 */
	useEffect(() => {
		if (firstRender) {
			return;
		}

		const searchTimeout = setTimeout(() => {
			readPartnerAdmins().then().catch();
		}, 500);

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e?.target.value);
	}

	/**
	 * Show or Hide the Create Partner Admin Modal.
	 *
	 */
	function toggleAddPartnerAdminModal(): void {
		setShowAddNewPartnerAdminModal(!showAddNewPartnerAdminModal);
	}

	/**
	 * Hide the Add New Partner Admin Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddPartnerAdminModal(): void {
		setShowAddNewPartnerAdminModal(false);
		readPartnerAdmins().then().catch();
	}

	/**
	 * Call API to get all the Partner Admins.
	 *
	 */
	async function readPartnerAdmins(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getPartnerAdmins({
				search: searchValue,
				...frontendPagination,
			});

			setPartnerAdmins(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Action Cells.
	 *
	 * @param value
	 * @param partnerAdmin
	 */
	function makeActionsCell(value: never, partnerAdmin: PartnerAdmin): ReactNode {
		return (
			<ManagePartnerAdminsActionsCell
				partnerAdmin={partnerAdmin}
				onDone={readPartnerAdmins}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManagePartnerAdminsAddModal
				isOpen={showAddNewPartnerAdminModal}
				onClose={toggleAddPartnerAdminModal}
				onDone={onDoneAddPartnerAdminModal}
			/>

			<div>
				<PageHeader>
					<h3>
						Manage Partner Admins
					</h3>

					<div className="row gy-3">
						<div className="col col-12 col-md-6">
							<label>
								Search
							</label>
							<Input
								placeholder="Search for Partner Admins"
								value={searchValue}
								onChange={searchOnChange}
							/>
						</div>

						<div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
							<label className="ghost-label d-none d-md-block">Add</label>
							<FrameButton
								color="darkPurple"
								icon={AiOutlinePlus}
								onClick={toggleAddPartnerAdminModal}
								className="w-100"
							>
								Create New Partner Admin
							</FrameButton>
						</div>
					</div>
				</PageHeader>
			</div>

			<div className="p-3">
				<FrameOneTableContainer
					data={partnerAdmins?.partnerAdmins}
					pagination={{
						...partnerAdmins?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "",
							headerValue: "Name",
							showSortIcons: false,
							sortable: false,
							valueFormatter: (f: never, u: PartnerAdmin) => generateUserFullName(u),
						},
						{
							key: "email",
							headerValue: "Email",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "partner",
							headerValue: "Partner",
							showSortIcons: false,
							sortable: false,
							valueFormatter: (p: Partner) => p.name,
						},
						{
							key: "phoneNumber",
							headerValue: "Phone Number",
							showSortIcons: false,
							sortable: false,
							valueFormatter: valueOrBustDynamic(formatPhoneNumber),
							headerCellClassName: "justify-content-end",
							rowCellClassName: "text-end",
						},
						{
							key: "disabled",
							headerValue: "Status",
							showSortIcons: false,
							sortable: false,
							valueFormatter: convertDisabledStatusToString,
						},
						{
							key: undefined,
							headerValue: "Actions",
							showSortIcons: false,
							sortable: false,
							cellRender: makeActionsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManagePartnerAdmins);
