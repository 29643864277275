import React from "react";
import CompanyPhoto from "../../CompanyPhoto";
import {Equipment} from "client";

interface IProps {
	equipment: Equipment;
}

const CreateBundleAddItemEquipmentImageCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<div className="make-image-cell">
				<CompanyPhoto
					src={props.equipment?.thumbnail?.url}
					alt="Equipment Picture"
					className="make-image-cell_company-profile-picture"
				/>
			</div>
		</div>
	);
};

export default CreateBundleAddItemEquipmentImageCell;
