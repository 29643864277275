import React, {ReactNode} from "react";
import {PaginationInfo} from "client";
import FrameOnePaginatorButton from "./FrameOnePaginatorButton";
import {FiChevronLeft, FiChevronRight} from "react-icons/all";
import {isNil} from "lodash";

interface IProps extends PaginationInfo {
	onChangeOffset: (offset: number) => void;
}

const FrameOnePaginationButtons: React.FC<IProps> = (props) => {

	function changePageHelper(offset: number): () => void {
		return () => props.onChangeOffset(offset);
	}

	function renderPaginatorButton(offset: number, i: number): ReactNode {
		return (
			<FrameOnePaginatorButton
				key={`table-paginator-offset_${offset}_${i}`}
				active={props.offset === i}
				onClick={props.offset !== i ? changePageHelper(i) : undefined}
			>
				{offset + 1}
			</FrameOnePaginatorButton>
		);
	}

	return (
		<div className="frame-one-paginator_buttons-container">
			<div className="frame-one-paginator_buttons-container_buttons">
				<FrameOnePaginatorButton
					active={false}
					onClick={props.enablePrevious ? changePageHelper(0) : undefined}
				>
					<FiChevronLeft/>
				</FrameOnePaginatorButton>

				{props.availableOffsets?.filter((i) => !isNil(i)).map(renderPaginatorButton)}

				<FrameOnePaginatorButton
					active={false}
					onClick={props.enableNext ? changePageHelper(props.offset + 1) : undefined}
				>
					<FiChevronRight/>
				</FrameOnePaginatorButton>
			</div>
		</div>
	);
};

export default FrameOnePaginationButtons;
