import React, {ChangeEventHandler, useState} from "react";
import {ChangeUserPasswordBody, Token, User, UsersApi} from "client";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Input, Label, ModalFooter} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	user: User;
	onClose: () => void;
	onDone: () => void;
}

type ChangeUserPasswordBodyFrontend = Pick<ChangeUserPasswordBody, "password" | "confirmPassword">;

const defaultUpdateUserPasswordForm: ChangeUserPasswordBodyFrontend = {
	password: "",
	confirmPassword: "",
};

const ManageUsersUpdatePasswordModal: React.FC<IProps> = (props) => {

	const [form, setForm] = useState<ChangeUserPasswordBodyFrontend>(defaultUpdateUserPasswordForm);

	/**
	 * Reset form & close modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultUpdateUserPasswordForm);
		props.onClose();
	}

	/**
	 * Dynamic on change for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof ChangeUserPasswordBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call api to update password,
	 * then reset the form & close the modal.
	 *
	 */
	async function submitUpdateUserPassword(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).changeUserPassword({
				id: props.user?._id,
				changeUserPasswordBody: {
					password: form.password,
					confirmPassword: form.confirmPassword,
				},
			});

			setForm(defaultUpdateUserPasswordForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Update User Password"
			/>

			<form onSubmit={submitUpdateUserPassword}>
				<FrameModalBody className="p-3">
					<div className="mb-3">
						<Label>
							New Password*
						</Label>
						<Input
							type="password"
							value={form.password}
							placeholder="Password"
							onChange={createOnChange("password")}
						/>
					</div>

					<div>
						<Label>
							Confirm New Password*
						</Label>
						<Input
							type="password"
							value={form.confirmPassword}
							placeholder="Confirm Password"
							onChange={createOnChange("confirmPassword")}
						/>
					</div>
				</FrameModalBody>

				<ModalFooter>
					<Button
						color="darkPurple"
						onClick={submitUpdateUserPassword}
						type="submit"
					>
						Update Password
					</Button>
				</ModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageUsersUpdatePasswordModal)
