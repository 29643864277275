import React, {useState} from "react";
import {BundleItem} from "client";
import ViewBundleInventorySelectionEquipmentOptionsModal
	from "../../bundle/ViewBundleInventorySelectionEquipmentOptionsModal";

interface IProps {
	bundleItem: BundleItem;
}

const ViewBundleInventorySelectionViewInventoryCell: React.FC<IProps> = (props) => {

	const [showEquipmentOptionsModal, setShowEquipmentOptionsModal] = useState(false);

	/**
	 * Hide or show the Equipment Options Modal.
	 *
	 */
	function toggleEquipmentOptionsModal(): void {
		setShowEquipmentOptionsModal(!showEquipmentOptionsModal);
	}

	return (
		<React.Fragment>
			<ViewBundleInventorySelectionEquipmentOptionsModal
				isOpen={showEquipmentOptionsModal}
				onClose={toggleEquipmentOptionsModal}
				bundleItem={props.bundleItem}
			/>

			<div className="frame-one-table-cell">
				{props.bundleItem?.equipmentOptions?.length > 0 ? (
					<a
						href="#"
						onClick={toggleEquipmentOptionsModal}
					>
						{`${props.bundleItem?.equipmentOptions?.length} (View)`}
					</a>
				) : (
					<span className="empty-message">
						No Equipment Options.
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default ViewBundleInventorySelectionViewInventoryCell;
