"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObject2ToJSON = exports.InlineObject2FromJSONTyped = exports.InlineObject2FromJSON = void 0;
function InlineObject2FromJSON(json) {
    return InlineObject2FromJSONTyped(json, false);
}
exports.InlineObject2FromJSON = InlineObject2FromJSON;
function InlineObject2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'companyID': json['companyID'],
        'csv': json['csv'],
    };
}
exports.InlineObject2FromJSONTyped = InlineObject2FromJSONTyped;
function InlineObject2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'companyID': value.companyID,
        'csv': value.csv,
    };
}
exports.InlineObject2ToJSON = InlineObject2ToJSON;
