import React from "react";
import {FiChevronDown, FiChevronUp} from "react-icons/all";
import {Equipment} from "client";

interface IProps {
	equipment: Equipment;
	priority: number;
	onUp: () => void;
	onDown: () => void;
}

/**
 * For displaying & reordering equipment options for a Bundle Item.
 * Priority is handled literally as array indexes in the data, so add 1 to that number to prevent displaying 0 to admins.
 *
 * @param props
 * @constructor
 */
const CreateBundleAddItemEquipmentPriorityCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell bundles-item-priority-cell">
			<div className="bundles-item-priority-cell_label-container">
				<p>
					{props.priority + 1}
				</p>
			</div>

			<div className="bundles-item-priority-cell_controls-container">
				<div onClick={props.onUp}>
					<FiChevronUp/>
				</div>

				<div onClick={props.onDown}>
					<FiChevronDown/>
				</div>
			</div>
		</div>
	);
};

CreateBundleAddItemEquipmentPriorityCell.defaultProps = {
	priority: 0,
}

export default CreateBundleAddItemEquipmentPriorityCell;
