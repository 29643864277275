"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeUserPasswordBodyToJSON = exports.ChangeUserPasswordBodyFromJSONTyped = exports.ChangeUserPasswordBodyFromJSON = void 0;
function ChangeUserPasswordBodyFromJSON(json) {
    return ChangeUserPasswordBodyFromJSONTyped(json, false);
}
exports.ChangeUserPasswordBodyFromJSON = ChangeUserPasswordBodyFromJSON;
function ChangeUserPasswordBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'password': json['password'],
        'confirmPassword': json['confirmPassword'],
    };
}
exports.ChangeUserPasswordBodyFromJSONTyped = ChangeUserPasswordBodyFromJSONTyped;
function ChangeUserPasswordBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'password': value.password,
        'confirmPassword': value.confirmPassword,
    };
}
exports.ChangeUserPasswordBodyToJSON = ChangeUserPasswordBodyToJSON;
