"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetEquipmentListResponseToJSON = exports.GetEquipmentListResponseFromJSONTyped = exports.GetEquipmentListResponseFromJSON = void 0;
const _1 = require("./");
function GetEquipmentListResponseFromJSON(json) {
    return GetEquipmentListResponseFromJSONTyped(json, false);
}
exports.GetEquipmentListResponseFromJSON = GetEquipmentListResponseFromJSON;
function GetEquipmentListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.PaginatedResponseFromJSONTyped(json, ignoreDiscriminator),
        'equipment': (json['equipment'].map(_1.EquipmentFromJSON)),
    };
}
exports.GetEquipmentListResponseFromJSONTyped = GetEquipmentListResponseFromJSONTyped;
function GetEquipmentListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.PaginatedResponseToJSON(value),
        'equipment': (value.equipment.map(_1.EquipmentToJSON)),
    };
}
exports.GetEquipmentListResponseToJSON = GetEquipmentListResponseToJSON;
