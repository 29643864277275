import React, {useState} from "react";
import {Equipment} from "client";
import CreateBundleAddItemSchedulingModal from "../../bundle/CreateBundleAddItemSchedulingModal";

interface IProps {
	equipment: Equipment;
}

const CreateBundleAddItemSchedulingCell: React.FC<IProps> = (props) => {

	const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);

	/**
	 * Hide or show the Availability Modal.
	 *
	 */
	function toggleAvailabilityModal(): void {
		setShowAvailabilityModal(!showAvailabilityModal);
	}

	return (
		<React.Fragment>
			<CreateBundleAddItemSchedulingModal
				isOpen={showAvailabilityModal}
				onClose={toggleAvailabilityModal}
				equipment={props.equipment}
			/>

			<div className="frame-one-table-cell">
				{props.equipment?.availability?.length > 0 ? (
					<a
						href="#"
						onClick={toggleAvailabilityModal}
					>
						{`${props.equipment?.availability?.length} (View)`}
					</a>
				) : (
					<span className="empty-message">
						No Availability.
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default CreateBundleAddItemSchedulingCell;
