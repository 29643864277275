import {Admin} from "client";

/**
 * Parse admin display name,
 * attempting to use first/last names if available,
 * or else falling back to username.
 *
 * @param admin
 */
export function getAdminDisplayName(admin: Admin): string {
	if (admin?.firstName && admin?.lastName) {
		return admin?.firstName + " " + admin?.lastName;
	} else if (admin?.firstName) {
		return admin?.firstName;
	} else if (admin?.lastName) {
		return admin?.lastName;
	} else {
		return admin?.username;
	}
}
