"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerBodyToJSON = exports.PartnerBodyFromJSONTyped = exports.PartnerBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function PartnerBodyFromJSON(json) {
    return PartnerBodyFromJSONTyped(json, false);
}
exports.PartnerBodyFromJSON = PartnerBodyFromJSON;
function PartnerBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'phoneNumber': !runtime_1.exists(json, 'phoneNumber') ? undefined : _1.PhoneNumberBodyFromJSON(json['phoneNumber']),
        'placeID': !runtime_1.exists(json, 'placeID') ? undefined : json['placeID'],
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
    };
}
exports.PartnerBodyFromJSONTyped = PartnerBodyFromJSONTyped;
function PartnerBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'phoneNumber': _1.PhoneNumberBodyToJSON(value.phoneNumber),
        'placeID': value.placeID,
        'image': value.image,
    };
}
exports.PartnerBodyToJSON = PartnerBodyToJSON;
