import React, {useState} from "react";
import {FiEdit2, FiTrash} from "react-icons/all";
import FrameButton from "../../buttons/FrameButton";
import {IBundleItemBodyFrontend} from "../../../pages/CreateBundle";
import CreateBundleEditItemModal from "../../bundle/CreateBundleEditItemModal";

interface IProps {
	item: IBundleItemBodyFrontend;
	onUpdate: (item: IBundleItemBodyFrontend) => void;
	onDelete: () => void;
}

const CreateBundleItemsActionsCell: React.FC<IProps> = (props) => {

	const [showEditModal, setShowEditModal] = useState(false);

	/**
	 * Hide or show the Edit Modal.
	 *
	 */
	function toggleShowEditModal(): void {
		setShowEditModal(!showEditModal);
	}

	/**
	 * Handle when the Admin finishes editing the bundle item by passing the info back up another level to the parent,
	 * and close the modal afterwards.
	 *
	 */
	function onDoneEditing(item: IBundleItemBodyFrontend): void {
		setShowEditModal(false);
		props.onUpdate(item);
	}

	return (
		<React.Fragment>
			<CreateBundleEditItemModal
				isOpen={showEditModal}
				onClose={toggleShowEditModal}
				onDone={onDoneEditing}
				existingItem={props.item}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					className="frame-one-button-small"
					color="mediumGray"
					icon={FiEdit2}
					onClick={toggleShowEditModal}
				/>

				<FrameButton
					className="frame-one-button-small"
					color="red"
					icon={FiTrash}
					onClick={props.onDelete}
				/>
			</div>
		</React.Fragment>
	);
};

export default CreateBundleItemsActionsCell;
