import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {FiEdit2, FiRefreshCw, FiTrash} from "react-icons/all";
import {CompanyAdmin, Token} from "client";
import ManageCompanyAdminsDeleteModal from "../../modals/ManageCompanyAdminsDeleteModal";
import ManageCompanyAdminsEnableModal from "../../modals/ManageCompanyAdminsEnableModal";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    companyAdmin: CompanyAdmin;
    onDone: () => Promise<void>;
}

const ManageCompanyAdminsActionsCell: React.FC<IProps> = (props) => {

    const [viewEditModal, setViewEditModal] = useState(false);
    const [viewDeleteModal, setViewDeleteModal] = useState(false);
    const [viewEnableModal, setViewEnableModal] = useState(false);

    /**
     * Hide or show the edit
     *
     */
    function toggleEditModal(): void {
        setViewEditModal(!viewEditModal);
    }

    /**
     * Hide or show the delete modal.
     *
     */
    function toggleDeleteModal(): void {
        setViewDeleteModal(!viewDeleteModal);
    }

    /**
     * Hide or show the delete modal.
     *
     */
    function toggleEnableModal(): void {
        setViewEnableModal(!viewEnableModal);
    }

    /**
     * When edit or delete is successful, close modal and recall api.
     *
     */
    function onDoneModals() {
        setViewEditModal(false);
        setViewDeleteModal(false);
        setViewEnableModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageCompanyAdminsDeleteModal
                companyAdmin={props.companyAdmin}
                isOpen={viewDeleteModal}
                onClose={toggleDeleteModal}
                onDone={onDoneModals}
            />

            <ManageCompanyAdminsEnableModal
                companyAdmin={props.companyAdmin}
                isOpen={viewEnableModal}
                onClose={toggleEnableModal}
                onDone={onDoneModals}
            />

            <div className="frame-one-table-cell">
                {/*<FrameButton*/}
                {/*	className="frame-one-button-small"*/}
                {/*	color="mediumGray"*/}
                {/*	outline={true}*/}
                {/*	icon={FiEdit2}*/}
                {/*	onClick={toggleEditModal}*/}
                {/*/>*/}

                {props.companyAdmin?.disabled ? (
                    <FrameButton
                        className="frame-one-button-small"
                        color="success"
                        icon={FiRefreshCw}
                        onClick={toggleEnableModal}
                    />
                ) : (
                    <FrameButton
                        className="frame-one-button-small"
                        color="red"
                        icon={FiTrash}
                        onClick={toggleDeleteModal}
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default ManageCompanyAdminsActionsCell;
