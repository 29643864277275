import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Bundle, BundlesApi, Token} from "client";
import {IStore} from "../redux/defaultStore";
import {useHistory} from "react-router-dom";
import {RouteProps} from "react-router";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import BreadCrumbs from "../components/BreadCrumbs";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import FrameOneContainer from "../components/FrameOneContainer";
import FrameOneButtonActions from "../components/FrameOneButtonActions";
import {FiEdit2} from "react-icons/all";
import ViewBundleDetails from "../components/bundle/ViewBundleDetails";
import ViewBundlesRequiredCharacteristics from "../components/bundle/ViewBundlesRequiredCharacteristics";
import ViewBundlesBlockerCharacteristics from "../components/bundle/ViewBundlesBlockerCharacteristics";
import ViewBundleInventorySelection from "../components/bundle/ViewBundleInventorySelection";

interface IProps extends RouteProps {
	dispatch?: any;
	fullToken?: Token;
}

const ViewBundle: React.FC<IProps> = (props) => {

	const history = useHistory();
	const query = new URLSearchParams(props.location.search);
	const bundleID: string = query.get("bundleID");

	const [bundle, setBundle] = useState<Bundle>(undefined);

	useEffect(() => {
		getBundle().then().catch();
	}, [bundleID]);

	/**
	 * Call the api to get the existing bundle as it is in the system for our viewing pleasure.
	 *
	 */
	async function getBundle(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BundlesApi(getConfig(props.fullToken)).getBundle({
				id: bundleID,
			});

			setBundle(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Go to the edit page for this Bundle.
	 *
	 */
	function goToEdit(): void {
		history.push(`/manage-bundles/edit?bundleID=${bundleID}`);
	}

	if (!bundle) {
		return null;
	}

	return (
		<div>
			<PageHeader>
				<BreadCrumbs
					crumbs={[
						{
							label: "Manage Bundles",
							route: "/manage-bundles",
						},
						{
							label: "View Bundle",
							route: `/manage-bundles/view?bundleID=${bundleID}`,
						},
					]}
				/>

				<h3 className="mt-3">
					Viewing Bundle
				</h3>
			</PageHeader>

			<FrameOneContainer>
				<div className="row g-3">
					<div className="col col-12">
						<ViewBundleDetails
							thumbnail={bundle.thumbnail}
							images={bundle.images}
							name={bundle.name}
							days={bundle.bookingTime / 24 / 60 / 60 / 1000}
							description={bundle.description}
							active={bundle.active}
						/>
					</div>

					<div className="col col-12">
						<ViewBundlesRequiredCharacteristics requiredCharacteristics={bundle.requiredCharacteristics}/>
					</div>

					<div className="col col-12">
						<ViewBundlesBlockerCharacteristics blockedCharacteristics={bundle.blockingCharacteristics}/>
					</div>

					<div className="col col-12">
						<ViewBundleInventorySelection items={bundle.items}/>
					</div>
				</div>

				<div className="mt-3">
					<FrameOneButtonActions>
						<FrameButton
							color="darkBlue"
							onClick={goToEdit}
							icon={FiEdit2}
						>
							Edit this Bundle
						</FrameButton>
					</FrameOneButtonActions>
				</div>
			</FrameOneContainer>
		</div>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ViewBundle);
