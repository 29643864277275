import React, {ReactNode} from "react";
import {Button, ButtonProps} from "reactstrap";
import {omit} from "lodash";
import classNames from "classnames";

interface IProps extends ButtonProps {
	icon?: ReactNode;
}

const FrameButton: React.FC<IProps> = (props) => {

	const Icon: any = props.icon;

	return (
		<Button
			{...omit(props, "icon", "children")}
			className={classNames("frame-one-button", props.className)}
		>
			{Icon && (
				<Icon/>
			)}

			{props.children}
		</Button>
	);
};

export default FrameButton;
