import React from "react";
import {Characteristic, Equipment, Question} from "client";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	question: Question;
}

const ManageQuestionsAnswersModal: React.FC<IProps> = (props) => {

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title="Viewing Answers"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container mb-4">
					<FrameOneTableContainer
						data={props.question?.answerOptions}
						columnOptions={[
							{
								key: "answer",
								headerValue: "Answer",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "points",
								headerValue: "Points",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "characteristic",
								headerValue: "Associated Characteristic",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (c: Characteristic) => c?.name,
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default ManageQuestionsAnswersModal;
