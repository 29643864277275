"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyAdminToJSON = exports.CompanyAdminFromJSONTyped = exports.CompanyAdminFromJSON = void 0;
const _1 = require("./");
function CompanyAdminFromJSON(json) {
    return CompanyAdminFromJSONTyped(json, false);
}
exports.CompanyAdminFromJSON = CompanyAdminFromJSON;
function CompanyAdminFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ..._1.UserFromJSONTyped(json, ignoreDiscriminator),
        'company': _1.CompanyFromJSON(json['company']),
    };
}
exports.CompanyAdminFromJSONTyped = CompanyAdminFromJSONTyped;
function CompanyAdminToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ..._1.UserToJSON(value),
        'company': _1.CompanyToJSON(value.company),
    };
}
exports.CompanyAdminToJSON = CompanyAdminToJSON;
