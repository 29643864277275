import React, {ReactNode, useState} from "react";
import LabelWithIcon from "../LabelWithIcon";
import {FiCheck, FiInfo, FiPlus, FiTrash} from "react-icons/all";
import FrameOneCard from "../FrameOneCard";
import CreateBundleBrowseCharacteristicsModal from "./CreateBundleBrowseCharacteristicsModal";
import FrameButton from "../buttons/FrameButton";
import FrameOneButtonActions from "../FrameOneButtonActions";
import {Characteristic} from "client";
import {cloneDeep, findIndex} from "lodash";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import ManageCharacteristicsImageCell from "../tables/cells/ManageCharacteristicsImageCell";

interface IProps {
	requiredCharacteristics: Array<Characteristic>;
	blockedCharacteristics: Array<Characteristic>;
	onChangeCharacteristics: (requiredCharacteristics: Array<Characteristic>, blockingCharacteristics: Array<Characteristic>) => void;
}

const CreateBundlesRequiredCharacteristics: React.FC<IProps> = (props) => {

	const [showCharacteristicsModal, setShowCharacteristicsModal] = useState(false);

	/**
	 * Hide or show the modal for browsing & selecting characteristics.
	 *
	 */
	function toggleShowCharacteristicsModal(): void {
		setShowCharacteristicsModal(!showCharacteristicsModal);
	}

	function onRequiredCharacteristicsChangeInterceptor(requiredCharacteristics: Array<Characteristic>): void {
		const blockersCopy: Array<Characteristic> = cloneDeep(props.blockedCharacteristics);

		requiredCharacteristics.forEach((r) => {
			const foundIndex: number = findIndex(blockersCopy, ["_id", r._id]);
			if (foundIndex > -1) {
				blockersCopy.splice(foundIndex, 1);
			}
		});

		props.onChangeCharacteristics(requiredCharacteristics, blockersCopy);
	}

	/**
	 * Renderer for the Characteristic Image Cells.
	 *
	 * @param value
	 * @param characteristic
	 */
	function makeImageCell(value: never, characteristic: Characteristic): ReactNode {
		return (
			<ManageCharacteristicsImageCell characteristic={characteristic}/>
		);
	}

	/**
	 * Renderer for the remove button cells.
	 *
	 * @param value
	 * @param characteristic
	 */
	function makeRemoveCell(value: never, characteristic: Characteristic): ReactNode {
		function onDeleteHelper(): void {
			const selectionsCopy: Array<Characteristic> = cloneDeep(props.requiredCharacteristics);
			selectionsCopy.splice(findIndex(selectionsCopy, ["_id", characteristic._id]), 1);
			props.onChangeCharacteristics(selectionsCopy, props.blockedCharacteristics);
		}

		return (
			<div className="frame-one-table-cell">
				<FrameButton
					className="frame-one-button-small"
					color="red"
					icon={FiTrash}
					onClick={onDeleteHelper}
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<CreateBundleBrowseCharacteristicsModal
				title="Add Required Characteristics"
				selectedCharacteristics={props.requiredCharacteristics}
				onChange={onRequiredCharacteristicsChangeInterceptor}
				isOpen={showCharacteristicsModal}
				onClose={toggleShowCharacteristicsModal}
			/>

			<FrameOneCard>
				<LabelWithIcon
					icon={FiCheck}
					iconClassName="text-green"
				>
					Required Characteristics
				</LabelWithIcon>

				<hr/>

				{(props.requiredCharacteristics && props.requiredCharacteristics.length > 0) ? (
					<div className="narrow-frame-one-table-container mb-4">
						<FrameOneTableContainer
							data={props.requiredCharacteristics}
							columnOptions={[
								{
									key: "image",
									headerValue: "Picture",
									showSortIcons: false,
									sortable: false,
									cellRender: makeImageCell,
									headerCellClassName: "justify-content-center",
									rowCellClassName: "justify-content-center",
								},
								{
									key: "name",
									headerValue: "Name",
									showSortIcons: false,
									sortable: false,
								},
								{
									key: "description",
									headerValue: "Description",
									showSortIcons: false,
									sortable: false,
								},
								{
									key: "minimumPointValue",
									headerValue: "Min. point value",
									showSortIcons: false,
									sortable: false,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
								{
									key: "maximumPointValue",
									headerValue: "Max. point value",
									showSortIcons: false,
									sortable: false,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
								{
									key: undefined,
									headerValue: "",
									showSortIcons: false,
									sortable: false,
									cellRender: makeRemoveCell,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "justify-content-end",
								},
							]}
						/>
					</div>
				) : (
					<div className="my-5">
						<p className="empty-message">
							No Required Characteristics.
						</p>
					</div>
				)}

				<FrameOneButtonActions>
					<FrameButton
						color="green"
						onClick={toggleShowCharacteristicsModal}
						icon={FiPlus}
					>
						Add
					</FrameButton>
				</FrameOneButtonActions>
			</FrameOneCard>
		</React.Fragment>
	);
};

export default CreateBundlesRequiredCharacteristics;
