import React, {useState} from "react";
import {Token} from "client";
import FrameButton from "../../buttons/FrameButton";
import {FiEdit2, FiEye, FiTrash} from "react-icons/all";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import ManageBundlesDeleteModal from "../../modals/ManageBundlesDeleteModal";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    bundle: any;
    onDone: () => Promise<void>;
}

const ManageBundlesActionsCell: React.FC<IProps> = (props) => {

    const history = useHistory();
    const [viewDeleteModal, setViewDeleteModal] = useState<boolean>(false);

    /**
     * Hide or Show the toggle modal.
     *
     */
    function toggleDeleteModal(): void {
        setViewDeleteModal(!viewDeleteModal);
    }

    function viewBundleClickHandler(): void {
        history.push(`/manage-bundles/view?bundleID=${props.bundle?._id}`);
    }

    function editBundleClickHandler(): void {
        history.push(`/manage-bundles/edit?bundleID=${props.bundle?._id}`);
    }

    /**
     * When finished deleting, close modal & call props function to get updated table data.
     *
     */
    function onDoneDeleteModal(): void {
        setViewDeleteModal(!viewDeleteModal);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageBundlesDeleteModal
                bundle={props.bundle}
                isOpen={viewDeleteModal}
                onClose={onDoneDeleteModal}
                onDone={onDoneDeleteModal}
            />

            <div className="frame-one-table-cell">
                <FrameButton
                    className="frame-one-button-small"
                    color="mediumGray"
                    outline={true}
                    icon={FiEye}
                    onClick={viewBundleClickHandler}
                />

                <FrameButton
                    className="frame-one-button-small"
                    color="mediumGray"
                    outline={true}
                    icon={FiEdit2}
                    onClick={editBundleClickHandler}
                />

                <FrameButton
                    className="frame-one-button-small"
                    color="red"
                    icon={FiTrash}
                    onClick={toggleDeleteModal}
                />
            </div>
        </React.Fragment>
    );
}

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(ManageBundlesActionsCell);
