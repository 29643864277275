import React, {useState} from "react";
import {Question} from "client";
import FrameButton from "../../buttons/FrameButton";
import ManageQuestionsAnswersModal from "../../modals/ManageQuestionsAnswersModal";

interface IProps {
	question: Question;
}

const ManageQuestionsAnswersCell: React.FC<IProps> = (props) => {

	const [showAnswersModal, setShowAnswersModal] = useState(false);

	/**
	 * Hide or show the Answers Modal.
	 *
	 */
	function toggleAnswersModal(): void {
		setShowAnswersModal(!showAnswersModal);
	}

	return (
		<React.Fragment>
			<ManageQuestionsAnswersModal
				isOpen={showAnswersModal}
				onClose={toggleAnswersModal}
				question={props.question}
			/>

			<div className="frame-one-table-cell">
				<a
					href="#"
					onClick={toggleAnswersModal}
				>
					{`${props.question?.answerOptions?.length} (View)`}
				</a>
			</div>
		</React.Fragment>
	);
};

export default ManageQuestionsAnswersCell;
