import React from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {toggleGlobalContactModalVisible} from "../../redux/meta/MetaActions";
import ContactModal from "./ContactModal";

interface IProps {
	dispatch?: any;
	isOpen?: boolean;
}

const GlobalContactModalManager: React.FC<IProps> = (props) => {

	function dismissHelper(): void {
		props.dispatch(toggleGlobalContactModalVisible(false));
	}

	return (
		<ContactModal
			isOpen={props.isOpen}
			toggle={dismissHelper}
		/>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		isOpen: store.metaStore.globalContactModalVisible,
		...props,
	}
})(GlobalContactModalManager)
