import React, {ReactNode, useState} from "react";
import {useHistory, Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {logout, toggleSideBar} from "../../redux/meta/MetaActions";
import {Token} from "client";
import SideBarFrameOneSocialInfo from "./SideBarFrameOneSocialInfo";
import SideBarVersionDisplay from "./SideBarVersionDisplay";
import SideBarButton from "../buttons/SideBarButton";
import {
	BsBriefcaseFill, BsClipboardData, DiAtom,
	FaHandshake,
	FaUserShield, FiAperture,
	FiCoffee,
	FiLogOut,
	FiUsers, HiOutlineCollection, IoIosPeople, MdOutlineChecklistRtl,
	RiDashboardLine
} from "react-icons/all";
import classNames from "classnames";

interface ISideBarNavigationLink {
	title: string;
	route: string;
	icon: ReactNode;
	activeClassName?: string;
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	sideBarVisible?: boolean;
}

const navigationLinks: Array<ISideBarNavigationLink> = [
	{
		title: "Demo Page",
		route: "/demo",
		icon: FiCoffee,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Dashboard",
		route: "/dashboard",
		icon: RiDashboardLine,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Admins",
		route: "/manage-admins",
		icon: FaUserShield,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Users",
		route: "/manage-users",
		icon: FiUsers,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Partners",
		route: "/manage-partners",
		icon: FaHandshake,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Partner Admins",
		route: "/manage-partner-admins",
		icon: IoIosPeople,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Companies",
		route: "/manage-companies",
		icon: BsBriefcaseFill,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Company Admins",
		route: "/manage-company-admins",
		icon: IoIosPeople,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Characteristics",
		route: "/manage-characteristics",
		icon: MdOutlineChecklistRtl,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Questions",
		route: "/manage-questions",
		icon: BsClipboardData,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
	{
		title: "Manage Bundles",
		route: "/manage-bundles",
		icon: HiOutlineCollection,
		activeClassName: "custom-sidebar-nav-classes_demo-page-animator"
	},
];

const SideBarInner: React.FC<IProps> = (props: IProps) => {

	const history = useHistory();
	const location = useLocation();
	const [showAssetManager, setShowAssetManager] = useState(false);

	/**
	 * Hide or Show the admin portal.
	 *
	 * @param e
	 */
	function toggleAssetManager(e): void {
		if (e) {
			e.preventDefault();
		}

		setShowAssetManager(!showAssetManager);
	}

	/**
	 * Helps toggle the side bar.
	 *
	 */
	function closeSideBarHelper(): void {
		if (props.sideBarVisible) {
			props.dispatch(toggleSideBar(false));
		}
	}

	/**
	 * Handle the "Log Out" button click.
	 * Dispatch redux logout action,
	 * send user to "/" route.
	 *
	 * @param e
	 */
	function logoutDispatcher(e?): void {
		if (e) {
			e.preventDefault();
		}

		closeSideBarHelper();
		props.dispatch(logout());
		history.push("/");
	}

	/**
	 * Render each of the navigation links.
	 *
	 * @param link
	 * @param i
	 */
	function createNavigationButtons(link: ISideBarNavigationLink, i: number): ReactNode {
		const selected: boolean = location.pathname?.indexOf(link.route) > -1;

		return (
			<Link
				key={`side-bar-navigation-link_${i}`}
				to={link.route}
				className={classNames("sidebar_inner_content_button-list-container_link", selected && link.activeClassName)}
			>
				<SideBarButton
					className="sidebar_inner_content_button-list-container_button"
					icon={link.icon}
					active={selected}
					onClick={closeSideBarHelper}
				>
					{link.title}
				</SideBarButton>
			</Link>
		);
	}

	return (
		<React.Fragment>
			{/*<AssetManager*/}
			{/*	isOpen={showAssetManager}*/}
			{/*	allowSelect={false}*/}
			{/*	onClose={toggleAssetManager}*/}
			{/*/>*/}

			<div className="sidebar_inner">
				<div className="sidebar_inner_content">
					<div className="sidebar_inner_content_title-container">
						<h5 className="sidebar_inner_content_title-container_title">
							Admin Portal
						</h5>
					</div>

					<SideBarVersionDisplay className="sidebar_inner_content_version-info-container"/>

					<div className="sidebar_inner_content_button-list-container">
						{navigationLinks?.map(createNavigationButtons)}

						<SideBarButton
							className="sidebar_inner_content_button-list-container_button"
							icon={FiLogOut}
							onClick={logoutDispatcher}
						>
							Log Out
						</SideBarButton>
					</div>
				</div>

				<SideBarFrameOneSocialInfo className="sidebar_inner_frame-one-info"/>
			</div>
		</React.Fragment>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		fullToken: store.metaStore.fullToken,
		sideBarVisible: store.metaStore.sidebarVisible,
	}
})(SideBarInner);
