import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {Admin} from "client";
import ManageAdminsToggleEnableModal from "../../modals/ManageAdminsToggleEnableModal";

interface IProps {
	admin: Admin;
	onDone: () => Promise<void>;
}

const ManageAdminsToggleEnableCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the toggle modal and call the
	 * props.onDone function to update the table.
	 *
	 */
	function onDoneToggleModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageAdminsToggleEnableModal
				isOpen={showModal}
				admin={props.admin}
				onClose={toggleShowToggleModal}
				onDone={onDoneToggleModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color={props.admin?.disabled ? "darkBlue" : "darkPurple"}
					onClick={toggleShowToggleModal}
				>
					{`${props.admin?.disabled ? "Enable" : "Disable"} Admin`}
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default ManageAdminsToggleEnableCell;
