import React from "react";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import ErrorManager from "./components/modals/ErrorManager";
import LoadingManager from "./components/LoadingManager";
import TokenManager from "./components/TokenManager";
import SideBarManager from "./components/sideBar/SideBarManager";
import Login from "./pages/Login";
import NotFoundPage from "./pages/NotFoundPage";
import Dashboard from "./pages/Dashboard";
import ManageAdmins from "./pages/ManageAdmins";
import ManageUsers from "./pages/ManageUsers";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import ComponentDemoPage from "./pages/ComponentDemoPage";
import GlobalContactModalManager from "./components/modals/GlobalContactModalManager";
import ManageCompanyAdmins from "./pages/ManageCompanyAdmins";
import ManageCompanies from "./pages/ManageCompanies";
import ManageCharacteristics from "./pages/ManageCharacteristics";
import ManageBundles from "./pages/ManageBundles";
import ManagePartners from "./pages/ManagePartners";
import ManagePartnerAdmins from "./pages/ManagePartnerAdmins";
import CreateBundle from "./pages/CreateBundle";
import ManageQuestionnaire from "./pages/ManageQuestionnaire";
import CreateQuestion from "./pages/CreateQuestion";
import EditBundle from "./pages/EditBundle";
import ViewBundle from "./pages/ViewBundle";


const App: React.FC = () => {

	return (
		<BrowserRouter>

			<ErrorManager/>
			<GlobalContactModalManager/>
			<LoadingManager/>
			<TokenManager/>

			<SideBarManager>
				<main className="mh-100">
					<Switch>
						{process.env.NODE_ENV === "development" && <Route exact path="/demo" component={ComponentDemoPage}/>}

						<Route exact path="/" component={Login}/>
						<AuthenticatedRoute exact path="/dashboard" component={Dashboard}/>
						<AuthenticatedRoute exact path="/manage-admins" component={ManageAdmins}/>
						<AuthenticatedRoute exact path="/manage-users" component={ManageUsers}/>

						<AuthenticatedRoute exact path="/manage-partners" component={ManagePartners}/>
						<AuthenticatedRoute exact path="/manage-partner-admins" component={ManagePartnerAdmins}/>

						<AuthenticatedRoute exact path="/manage-companies" component={ManageCompanies}/>
						<AuthenticatedRoute exact path="/manage-company-admins" component={ManageCompanyAdmins}/>

						<AuthenticatedRoute exact path="/manage-characteristics" component={ManageCharacteristics}/>

						<AuthenticatedRoute exact path="/manage-bundles" component={ManageBundles}/>
						<AuthenticatedRoute exact path="/manage-bundles/create" component={CreateBundle}/>
						<AuthenticatedRoute exact path="/manage-bundles/edit" component={EditBundle}/>
						<AuthenticatedRoute exact path="/manage-bundles/view" component={ViewBundle}/>

						<AuthenticatedRoute exact path="/manage-questions" component={ManageQuestionnaire}/>
						<AuthenticatedRoute exact path="/manage-questions/create" component={CreateQuestion}/>

						<Route exact path="/404" component={NotFoundPage}/>
						<Redirect to="/404"/>
					</Switch>
				</main>
			</SideBarManager>
		</BrowserRouter>
	);
};

export default App;
