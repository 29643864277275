"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentBookingToJSON = exports.EquipmentBookingFromJSONTyped = exports.EquipmentBookingFromJSON = void 0;
const _1 = require("./");
function EquipmentBookingFromJSON(json) {
    return EquipmentBookingFromJSONTyped(json, false);
}
exports.EquipmentBookingFromJSON = EquipmentBookingFromJSON;
function EquipmentBookingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'equipment': _1.EquipmentFromJSON(json['equipment']),
        'name': json['name'],
        'quantity': json['quantity'],
        'times': _1.TimeIntervalFromJSON(json['times']),
    };
}
exports.EquipmentBookingFromJSONTyped = EquipmentBookingFromJSONTyped;
function EquipmentBookingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'equipment': _1.EquipmentToJSON(value.equipment),
        'name': value.name,
        'quantity': value.quantity,
        'times': _1.TimeIntervalToJSON(value.times),
    };
}
exports.EquipmentBookingToJSON = EquipmentBookingToJSON;
