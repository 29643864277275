import React, {ReactNode} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	className?: string;
}

const PageHeader: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("page-header", props.className)}>
			<Container className="page-header_container">
				{props.children}
			</Container>
		</div>
	);
};

export default PageHeader;
