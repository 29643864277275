import React, {ReactNode} from "react";
import {Container} from "reactstrap";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	className?: string;
}

const FrameOneContainer: React.FC<IProps> = (props) => {

	return (
		<Container className={classNames("frame-one-container", props.className)}>
			{props.children}
		</Container>
	);
};

export default FrameOneContainer;
