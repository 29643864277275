import React, {useState} from "react";
import {BundleItem} from "client";
import ManageBundlesItemsModalInventoryItemsModal from "../../bundle/ManageBundlesItemsModalInventoryItemsModal";

interface IProps {
	bundleItem: BundleItem
}

const ManageBundlesItemsModalInventoryItemsCell: React.FC<IProps> = (props) => {

	const [showInventoryModal, setShowInventoryModal] = useState(false);

	/**
	 * Show or Hide the Inventory Items Modal.
	 *
	 */
	function toggleInventoryModal(): void {
		setShowInventoryModal(!showInventoryModal);
	}

	return (
		<React.Fragment>
			<ManageBundlesItemsModalInventoryItemsModal
				isOpen={showInventoryModal}
				onClose={toggleInventoryModal}
				bundleItem={props.bundleItem}
			/>

			<div className="frame-one-table-cell">
				{props.bundleItem?.equipmentOptions?.length > 0 ? (
					<a
						href="#"
						onClick={toggleInventoryModal}
					>
						{`${props.bundleItem?.equipmentOptions?.length} (View)`}
					</a>
				) : (
					<span className="empty-message">
						No Inventory.
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default ManageBundlesItemsModalInventoryItemsCell;
