import {CSSProperties} from "react";

/**
 * Function for sizing svgs when we want them dynamic.
 * We have an identical mixin in the scss as well.
 *
 * @param size
 */
export function svgSizer(size: number | string = "1em"): CSSProperties {
	return {
		height: size,
		width: size,
		minHeight: size,
		minWidth: size,
		maxHeight: size,
		maxWidth: size,
	}
}
