import React, {ReactNode} from "react";
import {Characteristic} from "client";
import LabelWithIcon from "../LabelWithIcon";
import {FiCheck} from "react-icons/all";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameOneCard from "../FrameOneCard";
import ManageCharacteristicsImageCell from "../tables/cells/ManageCharacteristicsImageCell";

interface IProps {
	requiredCharacteristics: Array<Characteristic>;
}

const ViewBundlesRequiredCharacteristics: React.FC<IProps> = (props) => {

	/**
	 * Renderer for the Characteristic Image Cells.
	 *
	 * @param value
	 * @param characteristic
	 */
	function makeImageCell(value: never, characteristic: Characteristic): ReactNode {
		return (
			<ManageCharacteristicsImageCell characteristic={characteristic}/>
		);
	}

	return (
		<FrameOneCard>
			<LabelWithIcon
				icon={FiCheck}
				iconClassName="text-green"
			>
				Required Characteristics
			</LabelWithIcon>

			<hr/>

			{(props.requiredCharacteristics && props.requiredCharacteristics.length > 0) ? (
				<div className="narrow-frame-one-table-container mb-4">
					<FrameOneTableContainer
						data={props.requiredCharacteristics}
						columnOptions={[
							{
								key: "image",
								headerValue: "Picture",
								showSortIcons: false,
								sortable: false,
								cellRender: makeImageCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "name",
								headerValue: "Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "description",
								headerValue: "Description",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "minimumPointValue",
								headerValue: "Min. point value",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "maximumPointValue",
								headerValue: "Max. point value",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
						]}
					/>
				</div>
			) : (
				<div className="my-5">
					<p className="empty-message">
						No Required Characteristics.
					</p>
				</div>
			)}
		</FrameOneCard>
	);
}

export default ViewBundlesRequiredCharacteristics;
