import React, {ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {AdminsApi, CreateAdminBody, Token, UserType} from "client";
import getConfig from "../../utils/getConfig";
import {Input, Label} from "reactstrap";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const defaultAddAdminForm: CreateAdminBody = {
	username: "",
	email: "",
	firstName: "",
	lastName: "",
	userType: "" as UserType,
};

const AddAdminModal: React.FC<IProps> = (props: IProps) => {

	const {fullToken, isOpen} = props;
	const [form, setForm] = useState<CreateAdminBody>(defaultAddAdminForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultAddAdminForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function createOnChange(key: keyof CreateAdminBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Call api to create new admin,
	 * reset form & close modal on success.
	 *
	 */
	async function submitAddNewAdmin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new AdminsApi(getConfig(fullToken)).createAdmin({
				createAdminBody: {
					email: form?.email || undefined,
					firstName: form?.firstName || undefined,
					lastName: form?.lastName || undefined,
					userType: form?.userType,
					username: form?.username || undefined,
				},
			});

			setForm(defaultAddAdminForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={isOpen}
			toggle={closeHelper}
			size="md"
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New Admin"
			/>

			<form onSubmit={submitAddNewAdmin}>
				<FrameModalBody>
					<div className="mb-3">
						<Label>
							Username*
						</Label>
						<Input
							value={form.username}
							placeholder="Username..."
							onChange={createOnChange("username")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Email*
						</Label>
						<Input
							value={form.email}
							placeholder="Email..."
							onChange={createOnChange("email")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Admin Role*
						</Label>
						<Input
							type="select"
							value={form.userType}
							placeholder="Admin Role..."
							onChange={createOnChange("userType")}
						>
							<option value="" selected disabled>Select Admin Role</option>
							<hr/>
							<option value={UserType.GODADMIN}>God Admin</option>
							<option value={UserType.STANDARDADMIN}>Standard Admin</option>
						</Input>
					</div>

					<div className="mb-3">
						<Label>
							First Name*
						</Label>
						<Input
							value={form.firstName}
							placeholder="First Name..."
							onChange={createOnChange("firstName")}
						/>
					</div>

					<div>
						<Label>
							Last Name*
						</Label>
						<Input
							value={form.lastName}
							placeholder="Last Name..."
							onChange={createOnChange("lastName")}
						/>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="darkPurple"
						onClick={submitAddNewAdmin}
						type="submit"
					>
						Add Admin
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(AddAdminModal);
