"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacteristicBodyToJSON = exports.CharacteristicBodyFromJSONTyped = exports.CharacteristicBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function CharacteristicBodyFromJSON(json) {
    return CharacteristicBodyFromJSONTyped(json, false);
}
exports.CharacteristicBodyFromJSON = CharacteristicBodyFromJSON;
function CharacteristicBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'minimumPointValue': json['minimumPointValue'],
        'maximumPointValue': !runtime_1.exists(json, 'maximumPointValue') ? undefined : json['maximumPointValue'],
        'image': !runtime_1.exists(json, 'image') ? undefined : json['image'],
    };
}
exports.CharacteristicBodyFromJSONTyped = CharacteristicBodyFromJSONTyped;
function CharacteristicBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'minimumPointValue': value.minimumPointValue,
        'maximumPointValue': value.maximumPointValue,
        'image': value.image,
    };
}
exports.CharacteristicBodyToJSON = CharacteristicBodyToJSON;
