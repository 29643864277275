"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenLogToJSON = exports.TokenLogFromJSONTyped = exports.TokenLogFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function TokenLogFromJSON(json) {
    return TokenLogFromJSONTyped(json, false);
}
exports.TokenLogFromJSON = TokenLogFromJSON;
function TokenLogFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': _1.TokenLogTypeFromJSON(json['type']),
        'ipAddress': !runtime_1.exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'date': json['date'],
        'note': !runtime_1.exists(json, 'note') ? undefined : json['note'],
    };
}
exports.TokenLogFromJSONTyped = TokenLogFromJSONTyped;
function TokenLogToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': _1.TokenLogTypeToJSON(value.type),
        'ipAddress': value.ipAddress,
        'date': value.date,
        'note': value.note,
    };
}
exports.TokenLogToJSON = TokenLogToJSON;
