import React, {useEffect, useState} from "react";
import {Container} from "reactstrap";
import AdminInfo from "./AdminInfo";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Admin, Token, UsersApi} from "client";
import {addError, updateCurrentUser} from "../redux/meta/MetaActions";
import {getAdminDisplayName} from "../utils/getAdminDisplayName";
import {parseUserType} from "../utils/parseUserType";
import classNames from "classnames";
import getConfig from "../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	currentUser?: Admin;
	className?: string;
}

const AdminPageHeader: React.FC<IProps> = (props) => {

	const [admin, setAdmin] = useState<Admin>(props.currentUser);

	useEffect(() => {
		if (!props.currentUser) {
			getCurrentUser().then().catch();
		}
	}, [props.fullToken]);

	async function getCurrentUser(): Promise<void> {
		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getProfile();
			setAdmin(res?.user as Admin);
			props.dispatch(updateCurrentUser(res?.user as Admin));
		} catch (e) {
			props.dispatch(addError(e));
		}
	}

	return (
		<div className={classNames("admin-page-header", props.className)}>
			<Container className="admin-page-header_container">
				<div
					className={classNames("admin-page-header_container_info", {
						"admin-page-header_container_info_hidden":  admin === undefined,
						"admin-page-header_container_info_visible": admin !== undefined,
					})}
				>
					<AdminInfo
						name={getAdminDisplayName(admin)}
						title={parseUserType(admin?.type)}
					/>
				</div>
			</Container>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(AdminPageHeader);
