import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {FiEdit2, FiTrash} from "react-icons/all";
import {useHistory} from "react-router-dom";
import ManageCharacteristicsDeleteModal from "../../modals/ManageCharacteristicsDeleteModal";
import ManageCharacteristicsEditModal from "../../modals/ManageCharacteristicsEditModal";

interface IProps {
    characteristic: any;
    onDone: () => Promise<void>;
}

const ManageCompanyAdminsActionsCell: React.FC<IProps> = (props) => {

    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    /**
     * Hide or show the edit
     *
     */
    function toggleEditModal(): void {
        setShowEditModal(!showEditModal);
    }

    /**
     * Hide or show the delete modal.
     *
     */
    function toggleDeleteModal(): void {
        setShowDeleteModal(!showDeleteModal);
    }

    /**
     * When edit or delete is successful, close modal and recall api.
     *
     */
    function onDoneEditOrDeleteModal() {
        setShowEditModal(false);
        setShowDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManageCharacteristicsEditModal
                characteristic={props.characteristic}
                isOpen={showEditModal}
                onClose={toggleEditModal}
                onDone={onDoneEditOrDeleteModal}
            />

            {/*<ManageCharacteristicsDeleteModal*/}
            {/*    characteristic={props.characteristic}*/}
            {/*    isOpen={viewModal}*/}
            {/*    onClose={toggleModal}*/}
            {/*/>*/}

            <div className="frame-one-table-cell">
                <FrameButton
                    className="frame-one-button-small"
                    color="mediumGray"
                    outline={true}
                    icon={FiEdit2}
                    onClick={toggleEditModal}
                />

                {/*<FrameButton*/}
                {/*    className="frame-one-button-small"*/}
                {/*    color="danger"*/}
                {/*    icon={FiTrash}*/}
                {/*    onClick={onDoneDeleteModal}*/}
                {/*/>*/}
            </div>
        </React.Fragment>
    );
}

export default ManageCompanyAdminsActionsCell;
