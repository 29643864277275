import React, {ReactNode} from "react";

interface IProps {
	href: string;
	children: ReactNode;
}

const SocialIconButton: React.FC<IProps> = (props) => {

	return (
		<a
			href={props.href}
			target="_blank"
			rel="noopener noreferrer"
			className="social-icon-button"
		>
			{props.children}
		</a>
	);
};

export default SocialIconButton;
