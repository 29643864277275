import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Asset, AssetsApi, CharacteristicBody, CharacteristicsApi, Token} from "client";
import {Input, Label} from "reactstrap";
import {AiOutlineInfoCircle} from "react-icons/all";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import LabelWithIcon from "../LabelWithIcon";
import CompanyPhoto from "../CompanyPhoto";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import FileInputButton from "../inputs/FileInputButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import {isValidNumber} from "../../utils/isValidNumber";

interface ICreateCharacteristicsRequestFrontend extends Omit<CharacteristicBody, "image"> {
	image: FileWithSRC;
}

const defaultAddNewCharacteristic: ICreateCharacteristicsRequestFrontend = {
	name: "",
	description: "",
	minimumPointValue: undefined,
	maximumPointValue: undefined,
	image: undefined,
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCharacteristicsAddModal: React.FC<IProps> = (props: IProps) => {

	const [newCharacteristicForm, setNewCharacteristicForm] = useState<ICreateCharacteristicsRequestFrontend>(defaultAddNewCharacteristic);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setNewCharacteristicForm(defaultAddNewCharacteristic);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ICreateCharacteristicsRequestFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setNewCharacteristicForm({
				...newCharacteristicForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof ICreateCharacteristicsRequestFrontend): (values: NumberFormatValues) => void {
		return (values) => {
			setNewCharacteristicForm({
				...newCharacteristicForm,
				[key]: values.value,
			});
		}
	}

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setNewCharacteristicForm({
			...newCharacteristicForm,
			image: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Call api to create new Characteristic,x
	 * reset form & close modal on success.
	 *
	 */
	async function submitAddNewCharacteristic(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let imageRes: Asset;
			if (newCharacteristicForm?.image) {
				imageRes = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: newCharacteristicForm?.image,
				});
			}

			await new CharacteristicsApi(getConfig(props.fullToken)).createCharacteristic({
				characteristicBody: {
					name: newCharacteristicForm?.name || undefined,
					description: newCharacteristicForm?.description || undefined,
					minimumPointValue: isValidNumber(newCharacteristicForm?.minimumPointValue) ? Number(newCharacteristicForm.minimumPointValue) : undefined,
					maximumPointValue: isValidNumber(newCharacteristicForm?.maximumPointValue) ? Number(newCharacteristicForm.maximumPointValue) : undefined,
					image: imageRes?._id,
				},
			});

			setNewCharacteristicForm(defaultAddNewCharacteristic);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={resetAndClose}
		>
			<FrameModalHeader
				title="Add New Characteristic"
				toggle={resetAndClose}
			/>

			<form onSubmit={submitAddNewCharacteristic}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Characteristic Details
					</LabelWithIcon>

					<div className="mb-3">
						<label>
							Name
						</label>
						<Input
							placeholder="Name..."
							value={newCharacteristicForm.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Description
						</label>
						<Input
							placeholder="Description..."
							value={newCharacteristicForm.description}
							onChange={dynamicOnChange("description")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Minimum Point Value
						</label>
						<NumberFormat
							placeholder="Minimum Points..."
							value={newCharacteristicForm.minimumPointValue}
							customInput={Input}
							allowNegative={false}
							decimalScale={0}
							onValueChange={numberFormatOnChange("minimumPointValue")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Maximum Point Value (Optional)
						</label>
						<NumberFormat
							placeholder="Maximum Points..."
							value={newCharacteristicForm.maximumPointValue}
							customInput={Input}
							allowNegative={false}
							decimalScale={0}
							onValueChange={numberFormatOnChange("maximumPointValue")}
						/>
					</div>

					<div>
						<Label>
							Characteristic Image
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={newCharacteristicForm.image && isFileWithSRC(newCharacteristicForm.image) && newCharacteristicForm.image.imageSRC as string}
								alt="Characteristic image"
								className="w-50 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onFileChange}
							>
								<FrameButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</FrameButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitAddNewCharacteristic}
					>
						Add Characteristic
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCharacteristicsAddModal);
