import React from "react";
import LabelWithIcon from "../LabelWithIcon";
import {FiCheck, FiInfo, FiX} from "react-icons/all";
import {Label} from "reactstrap";
import FrameOneCard from "../FrameOneCard";
import {Asset} from "client";
import {isAsset, isFileWithSRC} from "../../utils/fileTypeChecks";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import CreateBundleAdditionalImagesGallery from "../inputs/CreateBundleAdditionalImagesGallery";

interface IProps {
	thumbnail: Asset;
	images: Array<Asset>;
	name: string;
	days: number;
	description: string;
	active: boolean;
}

const ViewBundleDetails: React.FC<IProps> = (props) => {

	return (
		<FrameOneCard>
			<LabelWithIcon icon={FiInfo}>
				Details
			</LabelWithIcon>

			<hr/>

			<div className="row g-3">
				<div className="col col-12 col-md-6">
					<Label>
						Bundle Image
					</Label>
					{props.thumbnail ? (
						<div className="create-bundle-details_thumbnail-section">
							<div className="create-bundle-details_thumbnail-section_img-container">
								<img
									src={props.thumbnail.url}
									alt="bundle thumbnail"
								/>
							</div>
						</div>
					) : (
						<p className="empty-message my-3">No Thumbnail</p>
					)}
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Additional Bundle Gallery Images
					</Label>
					{(props.images && props.images.length > 0) ? (
						<div className="create-bundle-details_gallery-section">
							<CreateBundleAdditionalImagesGallery images={props.images}/>
						</div>
					) : (
						<p className="empty-message my-3">
							No Additional Images.
						</p>
					)}
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Bundle Name
					</Label>
					<div>
						<b>
							{props.name}
						</b>
					</div>
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Rental Duration (In Days)
					</Label>
					<div>
						<b>
							{props.days}
						</b>
					</div>
				</div>

				<div className="col col-12">
					<Label>
						Description
					</Label>
					<div>
						<b>
							{props.description}
						</b>
					</div>
				</div>

				<div className="col col-12">
					<Label>
						Active?
					</Label>
					<div>
						{props.active ? (
							<FiCheck className="text-green"/>
						) : (
							<FiX className="text-red"/>
						)}
					</div>
				</div>
			</div>
		</FrameOneCard>
	);
};

export default ViewBundleDetails;
