import React, {ReactNode} from "react";
import FrameModal from "../modals/modalComponents/FrameModal";
import {BundleItem, Equipment, Partner} from "client";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import CreateBundleAddItemEquipmentImageCell from "../tables/cells/CreateBundleAddItemEquipmentImageCell";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import {TableData, TableDataEntryArray, TableDataEntryJSON} from "frame-one-table/build/contextTypes";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	bundleItem: BundleItem;
}

const ManageBundlesItemsModalInventoryItemsModal: React.FC<IProps> = (props) => {
//todo
	/**
	 * Sort the Equipment Options by priority.
	 *
	 * @param equipmentOptions
	 */
	function getSortedBundleItemEquipmentOptions(equipmentOptions: Array<Equipment> = []): Array<Equipment> {
		return equipmentOptions.sort((a, b) => {
			return 1;
			// return a.priority - b.priority;
		});
	}

	/**
	 * Renderer for the Equipment Image Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function makeImageCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemEquipmentImageCell equipment={equipment}/>
		);
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title={`Viewing Inventory Items for "${props.bundleItem.name}"`}
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container mb-4">
					<FrameOneTableContainer
						data={getSortedBundleItemEquipmentOptions(props.bundleItem?.equipmentOptions)}
						columnOptions={[
							{
								key: "",
								headerValue: "Picture",
								showSortIcons: false,
								sortable: false,
								cellRender: makeImageCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "name",
								headerValue: "Equipment",
								showSortIcons: false,
								sortable: false,
								rowCellClassName: "w-100",
							},
							{
								key: "partner",
								headerValue: "Partner",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (p: Partner) => p?.name,
							},
							{
								key: "priority",
								headerValue: "Priority",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (value: any, row: TableDataEntryArray | TableDataEntryJSON, key: string | number, data: TableData, column: ColumnOption, index?: number) => index + 1, // add 1, as we save priorities as array indexes; don't want to display "0" in the UI.
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default ManageBundlesItemsModalInventoryItemsModal;
