import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Equipment, EquipmentApi, GetEquipmentListResponse, Partner, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../tables/FrameOnePaginator";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import CreateBundleAddItemEquipmentImageCell from "../tables/cells/CreateBundleAddItemEquipmentImageCell";
import CreateBundleAddItemEquipmentSelectionCell from "../tables/cells/CreateBundleAddItemEquipmentSelectionCell";
import {Input} from "reactstrap";
import CreateBundleAddItemSchedulingCell from "../tables/cells/CreateBundleAddItemSchedulingCell";
import CreateBundleAddItemAttributesCell from "../tables/cells/CreateBundleAddItemAttributesCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onAdd: (equipment: Equipment) => void;
	equipmentOptions: Array<Equipment>;
}

const CreateBundleAddItemBrowseEquipmentModal: React.FC<IProps> = (props) => {

	const [search, setSearch] = useState(undefined);
	const [equipment, setEquipment] = useState<GetEquipmentListResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	/**
	 * Call the api to get list of equipment when this modal opens, if there is no existing response
	 * (should only call on first open, and any subsequent calls will be the result of filtering or pagination changes).
	 *
	 */
	useEffect(() => {
		if (props.isOpen && equipment === undefined) {
			getEquipment().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Handle the user typing in the search field.
	 *
	 * @param e
	 */
	function searchOnChange(e: ChangeEvent<HTMLInputElement>): void {
		setSearch(e.target.value);
	}

	/**
	 * Call the api with the specified search value & save the results.
	 *
	 */
	async function getEquipment(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new EquipmentApi(getConfig(props.fullToken)).getEquipmentList({
				search,
				...frontendPagination,
			});

			setEquipment(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Bundle Image Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function makeImageCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemEquipmentImageCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Scheduling Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderSchedulingCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemSchedulingCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Attributes Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderAttributesCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemAttributesCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Selection Cells.
	 * Attach functionality for selecting the Equipment.
	 *
	 * @param value
	 * @param equipment
	 */
	function renderSelectCell(value: never, equipment: Equipment): ReactNode {
		function onDoneHelper(): void {
			props.onAdd(equipment);
		}

		return (
			<CreateBundleAddItemEquipmentSelectionCell onSelect={onDoneHelper}/>
		);
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="xl"
		>
			<FrameModalHeader
				title="Select Equipment"
				toggle={props.onClose}
			>
				<form onSubmit={getEquipment}>
					<div className="d-flex flex-column flex-sm-row col-12 col-lg-9 col-xl-6">
						<Input
							placeholder="Search Equipment..."
							value={search}
							onChange={searchOnChange}
							className="mb-3 mb-sm-0 me-0 me-sm-3"
						/>

						<FrameButton
							type="submit"
							onClick={getEquipment}
							color="darkPurple"
						>
							Search
						</FrameButton>
					</div>
				</form>
			</FrameModalHeader>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container">
					<FrameOneTableContainer
						data={equipment?.equipment}
						pagination={{
							...equipment?.paginationInfo,
							...frontendPagination,
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "image",
								headerValue: "Picture",
								showSortIcons: false,
								sortable: false,
								cellRender: makeImageCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "name",
								headerValue: "Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "partner",
								headerValue: "Partner",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (p: Partner) => p?.name,
							},
							{
								key: "quantity",
								headerValue: "Stock Count",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "availability",
								headerValue: "Scheduling",
								showSortIcons: false,
								sortable: false,
								cellRender: renderSchedulingCell,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "justify-content-end",
							},
							{
								key: "attributes",
								headerValue: "Attributes",
								showSortIcons: false,
								sortable: false,
								cellRender: renderAttributesCell,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "justify-content-end",
							},
							{
								key: "",
								headerValue: "Select",
								showSortIcons: false,
								sortable: false,
								cellRender: renderSelectCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="darkBlue"
					onClick={props.onClose}
				>
					Done
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateBundleAddItemBrowseEquipmentModal);
