import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Card, CardBody, CardHeader, Col, Container, Input, Label, Row, Spinner} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Admin, Token, User, UsersApi} from "client";
import getConfig from "../utils/getConfig";
import FrameOneCard from "../components/FrameOneCard";
import SectionLabel from "../components/SectionLabel";
import {parseUserType} from "../utils/parseUserType";
import AdminPageHeader from "../components/AdminPageHeader";
import PageHeader from "../components/PageHeader";
import FrameOneContainer from "../components/FrameOneContainer";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const Dashboard: React.FC<IProps> = (props: IProps) => {

	const [currentAdmin, setCurrentAdmin] = useState<Admin>();

	useEffect(() => {
		getCurrentAdmin().then().catch();
	}, []);

	async function getCurrentAdmin(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getProfile();

			setCurrentAdmin(res.user as Admin);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function makeAdminInfoColumn(label: string, value: string): ReactNode {
		return (
			<div className="col col-12 col-xl-6 mb-2 mb-xl-0">
				<Label>{label}</Label>
				<Input value={value ? value : "Not Available"} readOnly={true} disabled={true} className={value ? "" : "text-muted"}/>
			</div>
		)
	}

	if (!currentAdmin) {
		return (
			<div className="vh-100 w-100 d-flex justify-content-center align-items-center">
				<div className="d-flex align-items-center">
					<Spinner className="mr-3"/>
					<h4 className="mb-0">
						Loading...
					</h4>
				</div>
			</div>
		);
	}

	return (
		<div>
			<PageHeader>
				<h3 className="mb-0">
					Admin Dashboard
				</h3>
			</PageHeader>

			<FrameOneContainer>
				<FrameOneCard>
					<SectionLabel title="Dashboard & Admin Info">
						<div className="row mb-4">
							{makeAdminInfoColumn("Admin Role", parseUserType(currentAdmin.type))}
							{makeAdminInfoColumn("Username", currentAdmin.username)}
						</div>

						<div className="row">
							{makeAdminInfoColumn("First Name", currentAdmin.firstName)}
							{makeAdminInfoColumn("Last Name", currentAdmin.lastName)}
						</div>
					</SectionLabel>
				</FrameOneCard>
			</FrameOneContainer>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		fullToken: store.metaStore.fullToken,
	}
})(Dashboard);
