import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {Admin} from "client";
import ManageAdminsUpdatePasswordModal from "../../modals/ManageAdminsUpdatePasswordModal";

interface IProps {
	admin: Admin;
	onDone: () => Promise<void>;
}

const ManageAdminsEditPasswordCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowPasswordModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the password modal and call the
	 * props.onDone function to update the table.
	 *
	 */
	function onDonePasswordModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageAdminsUpdatePasswordModal
				isOpen={showModal}
				admin={props.admin}
				onClose={toggleShowPasswordModal}
				onDone={onDonePasswordModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color="darkPurple"
					outline={true}
					onClick={toggleShowPasswordModal}
				>
					Edit Password
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default ManageAdminsEditPasswordCell;
