import React, {ReactNode} from "react";
import {svgSizer} from "../../utils/svgSizer";
import {FiEdit2} from "react-icons/all";

interface IProps {
	size?: string | number;
	icon?: ReactNode;
	onClick: () => void;
}

const IconButton: React.FC<IProps> = (props) => {

	const Icon: any = props.icon;

	return (
		<div className="icon-button-container">
			<div
				className="icon-button-container_button"
				onClick={props.onClick}
				style={svgSizer(props.size)}
			>
				<Icon className="icon-button-container_button_icon"/>
			</div>
		</div>
	);
};

IconButton.defaultProps = {
	size: 52,
	icon: FiEdit2,
};

export default IconButton;
