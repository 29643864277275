import {find} from "lodash";

export interface IReactSelectOption<T = string> {
	value: T;
	label: string;
}

interface IListItemBase {
	_id: string;
}

/**
 * Creates array of options for the react-select component
 * in a format that the library can work with.
 *
 * @param items
 * @param labelField
 */
export function makeReactSelectOptions<T extends IListItemBase>(items: Array<T> = [], labelField: keyof T): Array<IReactSelectOption> {
	return items.map((item: T) => {
		return {
			value: item._id,
			label: item[labelField] as unknown as string,
		}
	});
}

/**
 * Utility for passing the controlled value back to the react-select input,
 * as for some reason the library wants you to pass the whole object back instead of just the raw value (_id in our case).
 *
 * @param items
 * @param selectedID
 * @param labelField
 */
export function reverseLookUpReactSelectValue<T extends IListItemBase>(items: Array<T> = [], selectedID: string = undefined, labelField: keyof T): IReactSelectOption {
	if (items.length === 0 || selectedID === undefined) {
		return undefined;
	}

	const item: T = find(items, ["_id", selectedID]);

	return {
		value: item._id,
		label: item[labelField] as unknown as string,
	}
}
