import React, {ChangeEvent, ChangeEventHandler} from "react";
import FrameOneCard from "../FrameOneCard";
import LabelWithIcon from "../LabelWithIcon";
import {FiInfo} from "react-icons/all";
import {Input, Label} from "reactstrap";
import {IBundleBodyFrontend} from "../../pages/CreateBundle";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameOneCheckbox from "../FrameOneCheckbox";
import FrameButton from "../buttons/FrameButton";
import FileInputButton from "../inputs/FileInputButton";
import {FileWithSRC} from "../../utils/renderAssetsHelper";
import {Asset} from "client";
import {isAsset, isFileWithSRC} from "../../utils/fileTypeChecks";
import CreateBundleAdditionalImagesGallery from "../inputs/CreateBundleAdditionalImagesGallery";

interface IProps {
	thumbnail: Asset | FileWithSRC;
	images: Array<Asset | FileWithSRC>;
	name: string;
	days: number;
	description: string;
	active: boolean;
	onChangeThumbnail: (e: ChangeEvent<HTMLInputElement>) => void;
	onRemoveThumbnail: () => void;
	onAdditionalImagesChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	onRemoveAdditionalImage: (index: number) => void;
	onChangeText: (key: keyof IBundleBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
	onChangeNumber: (key: keyof IBundleBodyFrontend) => (values: NumberFormatValues) => void;
	onChangeCheckbox: (key: keyof IBundleBodyFrontend) => () => void;
}

const CreateBundleDetails: React.FC<IProps> = (props) => {

	return (
		<FrameOneCard className="create-bundle-details">
			<LabelWithIcon icon={FiInfo}>
				Details
			</LabelWithIcon>

			<hr/>

			<div className="row g-3">
				<div className="col col-12 col-md-6">
					<Label>
						Bundle Image
					</Label>
					{props.thumbnail ? (
						<div className="create-bundle-details_thumbnail-section">
							<div className="create-bundle-details_thumbnail-section_img-container">
								{isFileWithSRC(props.thumbnail) && (
									<img
										src={props.thumbnail.imageSRC as string}
										alt="bundle thumbnail"
									/>
								)}

								{isAsset(props.thumbnail) && (
									<img
										src={props.thumbnail.url}
										alt="bundle thumbnail"
									/>
								)}
							</div>
						</div>
					) : (
						<p className="empty-message my-3">No Thumbnail Chosen</p>
					)}

					<div className="d-flex flex-column align-items-center">
						<FileInputButton
							accept=".png,.jpeg,.gif"
							multiple={false}
							onChange={props.onChangeThumbnail}
						>
							<FrameButton
								color="darkPurple"
								outline={true}
							>
								{props.thumbnail ? "Change Thumbnail" : "Upload Thumbnail"}
							</FrameButton>
						</FileInputButton>

						{props.thumbnail && (
							<a
								href="#"
								onClick={props.onRemoveThumbnail}
								className="mt-2"
							>
								Remove Thumbnail
							</a>
						)}
					</div>
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Additional Bundle Gallery Images
					</Label>
					{(props.images && props.images.length > 0) ? (
						<div className="create-bundle-details_gallery-section">
							<CreateBundleAdditionalImagesGallery
								images={props.images}
								onClick={props.onRemoveAdditionalImage}
							/>
						</div>
					) : (
						<p className="empty-message my-3">
							No Additional Images.
						</p>
					)}

					<div className="d-flex justify-content-center">
						<FileInputButton
							accept=".png,.jpeg,.gif"
							multiple={true}
							onChange={props.onAdditionalImagesChange}
						>
							<FrameButton
								color="darkPurple"
								outline={true}
							>
								Add Images
							</FrameButton>
						</FileInputButton>
					</div>
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Bundle Name
					</Label>
					<Input
						placeholder="Bundle Name..."
						value={props.name}
						onChange={props.onChangeText("name")}
					/>
				</div>

				<div className="col col-12 col-md-6">
					<Label>
						Rental Duration (In Days)
					</Label>
					<NumberFormat
						placeholder="Number of Days..."
						value={props.days}
						customInput={Input}
						allowNegative={false}
						decimalScale={0}
						onValueChange={props.onChangeNumber("days")}
					/>
				</div>

				<div className="col col-12">
					<Label>
						Description
					</Label>
					<Input
						type="textarea"
						placeholder="Bundle Description..."
						value={props.description}
						onChange={props.onChangeText("description")}
					/>
				</div>

				<div className="col col-12">
					<Label>
						Active?
					</Label>
					<FrameOneCheckbox
						onToggle={props.onChangeCheckbox("active")}
						checked={props.active}
					>
						Bundle is visible to users.
					</FrameOneCheckbox>
				</div>
			</div>
		</FrameOneCard>
	);
};

export default CreateBundleDetails;
