import React from "react";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Bundle, BundlesApi, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import getConfig from "../../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
    onDone: () => void;
	bundle: Bundle;
}

const ManageBundlesDeleteModal: React.FC<IProps> = (props) => {

    /**
     * Call api to delete the bundle & then call the props function to handle closing the modal.
     *
     */
	async function confirmDeleteBundle(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new BundlesApi(getConfig(props.fullToken)).deleteBundle({
				id: props.bundle?._id,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title="Confirm Delete Bundle"
			/>

			<FrameModalBody>
				<p className="manage-delete-bundle-modal_message pb-3">
					Are you sure you want to delete the Bundle <b>{props.bundle?.name}</b>? This action cannot be undone.
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					outline={true}
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="red"
					onClick={confirmDeleteBundle}
				>
					Delete
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
}

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageBundlesDeleteModal);
