import React, {ReactNode, useEffect, useState} from "react";
import {connect} from "react-redux";
import {GetQuestionsResponse, Question, QuestionsApi, Token} from "client";
import {IStore} from "../redux/defaultStore";
import PageHeader from "../components/PageHeader";
import {Input} from "reactstrap";
import {Link} from "react-router-dom";
import FrameButton from "../components/buttons/FrameButton";
import {AiOutlinePlus} from "react-icons/all";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import ManageQuestionsAnswersCell from "../components/tables/cells/ManageQuestionsAnswersCell";
import ManageQuestionsDeleteCell from "../components/tables/cells/ManageQuestionsDeleteCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageQuestionnaire: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>(undefined);
	const [firstRender, setFirstRender] = useState(true);
	const [questions, SetQuestions] = useState<GetQuestionsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readQuestions().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input
	 * After timeout completes API is called with search property
	 *
	 */
	useEffect(() => {
		const searchTimeout = searchValue !== undefined ? setTimeout(() => {
			readQuestions().then().catch();
		}, 1000) : null;

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e.target.value);
	}

	/**
	 * Calling API to get Questions.
	 *
	 */
	async function readQuestions(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new QuestionsApi(getConfig(props.fullToken)).getQuestions({
				search: searchValue,
				...frontendPagination,
			});

			SetQuestions(res);

			// const res = await new BundlesApi(getConfig(props.fullToken)).getBundles({
			// 	search: searchValue,
			// 	...frontendPagination,
			// });
			//
			// setBundles(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the "Answer" column cells.
	 *
	 * @param value
	 * @param question
	 */
	function makeAnswerCell(value: never, question: Question): ReactNode {
		return (
			<ManageQuestionsAnswersCell question={question}/>
		);
	}

	/**
	 * Renderer for the "Delete" column cells.
	 *
	 * @param value
	 * @param question
	 */
	function makeDeleteCell(value: never, question: Question): ReactNode {
		return (
			<ManageQuestionsDeleteCell
				question={question}
				onDone={readQuestions}
			/>
		);
	}

	return (
		<div>
			<PageHeader>
				<h3>
					Manage Questionnaire
				</h3>

				<div className="row gy-3">
					<div className="col col-12 col-md-6">
						<label>
							Search
						</label>
						<Input
							placeholder="Search for Questions"
							value={searchValue}
							onChange={searchOnChange}
						/>
					</div>

					<div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
						<label className="ghost-label d-none d-md-block">Add</label>
						<Link
							to="/manage-questions/create"
							className="text-decoration-none"
						>
							<FrameButton
								color="darkPurple"
								icon={AiOutlinePlus}
								className="w-100"
							>
								Create New Question
							</FrameButton>
						</Link>
					</div>
				</div>
			</PageHeader>

			<div className="p-3">
				<FrameOneTableContainer
					data={questions?.questions}
					pagination={{
						...questions?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "question",
							headerValue: "Question",
							showSortIcons: false,
							sortable: false,
							rowCellClassName: "w-100",
						},
						{
							key: "",
							headerValue: "Answers",
							showSortIcons: false,
							sortable: false,
							cellRender: makeAnswerCell,
							headerCellClassName: "justify-content-end",
							rowCellClassName: "justify-content-end",
						},
						{
							key: "",
							headerValue: "Actions",
							showSortIcons: false,
							sortable: false,
							cellRender: makeDeleteCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageQuestionnaire);
