import React from "react";
import {Admin, Token, UsersApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	admin: Admin;
	onClose: () => void;
	onDone: () => void;
}

const ManageAdminsToggleEnableModal: React.FC<IProps> = (props) => {

	/**
	 * Dynamically call the enable or disable apis
	 * based on the admin's "disabled" status.
	 *
	 */
	async function toggleAdmin(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const apiBase = new UsersApi(getConfig(props.fullToken));

			if (props.admin?.disabled) {
				await apiBase.enableUser({
					id: props.admin?._id,
				});
			} else {
				await apiBase.disableUser({
					id: props.admin?._id,
				});
			}

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				toggle={props.onClose}
				title={props.admin?.disabled ? "Enable Admin" : "Disable Admin"}
			/>

			<FrameModalBody>
				<p className="mb-0">
					{props.admin?.disabled ? (
						"Enabling this admin will grant them all the ability to perform all tasks allowed by their current Admin Role."
					) : (
						"Disabling this admin will revoke their access to the system and they will not be allowed to log in or perform any of the actions they were previously able to."
					)}
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color={props.admin?.disabled ? "darkBlue" : "darkPurple"}
					onClick={toggleAdmin}
				>
					{props.admin?.disabled ? "Confirm, Enable Admin" : "Confirm, Disable Admin"}
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageAdminsToggleEnableModal);
