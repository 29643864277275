import React from "react";
import {PaginationInfo} from "client";
import FrameOnePaginationButtons from "./FrameOnePaginationButtons";
import {omit} from "lodash";
import FrameOnePaginatorLimiter, {paginationLimiterOptions} from "./FrameOnePaginatorLimiter";

interface IProps extends PaginationInfo {
	onPaginationChange: (newPagination: FrontendPagination) => void;
}

export type FrontendPagination = Pick<PaginationInfo, "offset" | "limit">;

export const defaultFrontendPagination: FrontendPagination = {
	offset: 0,
	limit: paginationLimiterOptions?.[0]?.value,
}

const FrameOnePaginator: React.FC<IProps> = (props) => {

	function onChangeLimit(limit): void {
		props.onPaginationChange(omit({
			offset: 0,
			limit,
		}, "onPaginationChange"));
	}

	function onChangeOffset(offset: number): void {
		props.onPaginationChange(omit({
			limit: props.limit,
			offset,
		}, "onPaginationChange"));
	}

	return (
		<div className="frame-one-paginator">
			<FrameOnePaginatorLimiter
				{...omit(props, "onPaginationChange")}
				onChangeLimit={onChangeLimit}
			/>

			<FrameOnePaginationButtons
				{...omit(props, "onPaginationChange")}
				onChangeOffset={onChangeOffset}
			/>
		</div>
	);
};

export default FrameOnePaginator;
