import React, {useEffect} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {logout} from "../redux/meta/MetaActions";
import {Token, TokensApi} from "client";
import getConfig from "../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const tokenCheckInterval: number = 60000;

const TokenManager: React.FC<IProps> = (props: IProps) => {

	useEffect(() => {
		checkTokenValid().then().catch();
		const interval = setInterval(checkTokenValid, tokenCheckInterval);
		return () => clearInterval(interval);
	}, [props.fullToken]);

	async function checkTokenValid(): Promise<void> {
		// don't need to check if token doesn't exist
		if (!props.fullToken) {
			return;
		}

		try {
			const res = await new TokensApi(getConfig(props.fullToken)).checkTokenExpiration({tokenBody: {token: props.fullToken?.token}});

			if (res.expired === true) {
				props.dispatch(logout());
			}
		} catch (e) {

		}
	}

	return null;
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(TokenManager);
