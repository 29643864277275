"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EquipmentToJSON = exports.EquipmentFromJSONTyped = exports.EquipmentFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function EquipmentFromJSON(json) {
    return EquipmentFromJSONTyped(json, false);
}
exports.EquipmentFromJSON = EquipmentFromJSON;
function EquipmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'partner': _1.PartnerFromJSON(json['partner']),
        'identifier': !runtime_1.exists(json, 'identifier') ? undefined : json['identifier'],
        'isConsumable': json['isConsumable'],
        'quantity': json['quantity'],
        'availability': (json['availability'].map(_1.TimeIntervalFromJSON)),
        'name': json['name'],
        'description': json['description'],
        'instructions': !runtime_1.exists(json, 'instructions') ? undefined : json['instructions'],
        'address': !runtime_1.exists(json, 'address') ? undefined : _1.AddressFromJSON(json['address']),
        'thumbnail': !runtime_1.exists(json, 'thumbnail') ? undefined : _1.AssetFromJSON(json['thumbnail']),
        'images': (json['images'].map(_1.AssetFromJSON)),
        'attributes': json['attributes'],
    };
}
exports.EquipmentFromJSONTyped = EquipmentFromJSONTyped;
function EquipmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'partner': _1.PartnerToJSON(value.partner),
        'identifier': value.identifier,
        'isConsumable': value.isConsumable,
        'quantity': value.quantity,
        'availability': (value.availability.map(_1.TimeIntervalToJSON)),
        'name': value.name,
        'description': value.description,
        'instructions': value.instructions,
        'address': _1.AddressToJSON(value.address),
        'thumbnail': _1.AssetToJSON(value.thumbnail),
        'images': (value.images.map(_1.AssetToJSON)),
        'attributes': value.attributes,
    };
}
exports.EquipmentToJSON = EquipmentToJSON;
