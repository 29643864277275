"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhoneNumberToJSON = exports.PhoneNumberFromJSONTyped = exports.PhoneNumberFromJSON = void 0;
function PhoneNumberFromJSON(json) {
    return PhoneNumberFromJSONTyped(json, false);
}
exports.PhoneNumberFromJSON = PhoneNumberFromJSON;
function PhoneNumberFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'normalized': json['normalized'],
        'countryCode': json['countryCode'],
        'nationalNumber': json['nationalNumber'],
    };
}
exports.PhoneNumberFromJSONTyped = PhoneNumberFromJSONTyped;
function PhoneNumberToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'normalized': value.normalized,
        'countryCode': value.countryCode,
        'nationalNumber': value.nationalNumber,
    };
}
exports.PhoneNumberToJSON = PhoneNumberToJSON;
