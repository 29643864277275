import React from "react";
import LoginProjectInfoLabel from "./LoginProjectInfoLabel";
import LoginFrameOneInfo from "./LoginFrameOneInfo";

const LoginFooter: React.FC = () => {

	return (
		<div className="login-footer">
			<LoginProjectInfoLabel
				name={process.env.REACT_APP_PROJECT_NAME}
				version={process.env.REACT_APP_VERSION}
				className="login-footer_project-info"
			/>

			<LoginFrameOneInfo className="login-footer_frame-one"/>
		</div>
	);
};

export default LoginFooter;
