import React, {useState} from "react";
import {Question} from "client";
import {FiTrash} from "react-icons/all";
import FrameButton from "../../buttons/FrameButton";
import ManageQuestionsDeleteModal from "../../modals/ManageQuestionsDeleteModal";

interface IProps {
	question: Question;
	onDone: () => Promise<void>;
}

const ManageQuestionsDeleteCell: React.FC<IProps> = (props) => {

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	/**
	 * Show or hide the Confirm Delete Modal.
	 *
	 */
	function toggleShowDeleteModal(): void {
		setShowDeleteModal(!showDeleteModal);
	}

	/**
	 * When finished deleting, hide the modal and call the props function to get updated table.
	 *
	 */
	function onDoneDelete(): void {
		setShowDeleteModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageQuestionsDeleteModal
				isOpen={showDeleteModal}
				question={props.question}
				onDone={onDoneDelete}
				onCancel={toggleShowDeleteModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					className="frame-one-button-small"
					color="red"
					icon={FiTrash}
					onClick={toggleShowDeleteModal}
				/>
			</div>
		</React.Fragment>
	);
};

export default ManageQuestionsDeleteCell;
