import React, {ReactNode} from "react";

interface IProps {
	children: ReactNode;
}

const FrameOneButtonActions: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-button-actions">
			{props.children}
		</div>
	);
};

export default FrameOneButtonActions;
