import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {
	Asset,
	AssetsApi,
	CreatePartnerRequest,
	DialCode,
	PartnerBody,
	PartnersApi,
	PhoneNumberBody,
	Token
} from "client";
import getConfig from "../../utils/getConfig";
import {Input, Label} from "reactstrap";
import {AiOutlineInfoCircle, AiOutlinePlus} from "react-icons/all";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameButton from "../buttons/FrameButton";
import LabelWithIcon from "../LabelWithIcon";
import GooglePlacesInput from "../inputs/GooglePlacesInput";
import CompanyPhoto from "../CompanyPhoto";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FileInputButton from "../inputs/FileInputButton";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import NumberFormat, {NumberFormatValues} from "react-number-format";

interface ICreatePartnerRequestFrontend extends Omit<PartnerBody, "image"> {
	image: FileWithSRC;
}

const defaultAddNewPartnerAdminForm: ICreatePartnerRequestFrontend = {
	name: "",
	phoneNumber: {
		countryCode: undefined,
		nationalNumber: "",
	},
	placeID: "",
	image: undefined,
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManagePartnersAddModal: React.FC<IProps> = (props: IProps) => {

	const [newPartnerForm, setNewPartnerForm] = useState<ICreatePartnerRequestFrontend>(defaultAddNewPartnerAdminForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setNewPartnerForm(defaultAddNewPartnerAdminForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ICreatePartnerRequestFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setNewPartnerForm({
				...newPartnerForm,
				[key]: e.target.value,
			})
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setNewPartnerForm({
				...newPartnerForm,
				phoneNumber: {
					...newPartnerForm.phoneNumber,
					[key]: dialCodePart,
				},
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setNewPartnerForm({
				...newPartnerForm,
				phoneNumber: {
					...newPartnerForm.phoneNumber,
					[key]: values.value,
				},
			});
		}
	}

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setNewPartnerForm({
			...newPartnerForm,
			image: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Custom onChange for the places autocomplete because of how we return the data from that component.
	 *
	 * @param placeID
	 */
	function setPlaceID(placeID: string): void {
		setNewPartnerForm({
			...newPartnerForm,
			placeID: placeID,
		});
	}

	/**
	 * Call api to create new Partner Admin, reset form & close modal on success.
	 *
	 */
	async function submitAddNewPartnerAdmin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let imageRes: Asset;
			if (newPartnerForm?.image) {
				imageRes = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: newPartnerForm?.image,
				});
			}

			await new PartnersApi(getConfig(props.fullToken)).createPartner({
				partnerBody: {
					name: newPartnerForm?.name || undefined,
					phoneNumber: (newPartnerForm?.phoneNumber?.countryCode || newPartnerForm?.phoneNumber?.nationalNumber) ? {
						countryCode: newPartnerForm?.phoneNumber?.countryCode,
						nationalNumber: newPartnerForm?.phoneNumber?.nationalNumber || undefined,
					} : undefined,
					placeID: newPartnerForm?.placeID || undefined,
					image: imageRes?._id,
				},
			});

			setNewPartnerForm(defaultAddNewPartnerAdminForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				title="Add New Partner"
				toggle={closeHelper}
			/>

			<form onSubmit={submitAddNewPartnerAdmin}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Partner Details
					</LabelWithIcon>

					<div className="mb-3">
						<label>
							Name
						</label>
						<Input
							placeholder="Name..."
							value={newPartnerForm.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Phone Number
						</label>

						<div className="row gx-1 gx-sm-3">
							<div className="col col-4 col-sm-3">
								<CountryCodeSelector
									value={newPartnerForm?.phoneNumber?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange("countryCode")}
								/>
							</div>

							<div className="col col-8 col-sm-9">
								<NumberFormat
									placeholder="(000) 000-0000"
									value={newPartnerForm.phoneNumber?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
								/>
							</div>
						</div>
					</div>

					<div className="mb-3">
						<Label>
							Location
						</Label>
						<GooglePlacesInput
							initialInputValue={newPartnerForm?.placeID}
							setPlaceID={setPlaceID}
						/>
					</div>

					<div>
						<Label>
							Partner Image
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={newPartnerForm.image && isFileWithSRC(newPartnerForm.image) && newPartnerForm.image.imageSRC as string}
								alt="Partner image"
								className="w-50 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onFileChange}
							>
								<FrameButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</FrameButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitAddNewPartnerAdmin}
					>
						Add Partner
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManagePartnersAddModal);
