import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Partner, Token} from "client";
import {FiEdit2, FiTrash} from "react-icons/all";
import FrameButton from "../../buttons/FrameButton";
import ManagePartnersDeleteModal from "../../modals/ManagePartnersDeleteModal";
import ManagePartnersEditModal from "../../modals/ManagePartnersEditModal";

interface IProps {
    partner: Partner;
    onDone: () => Promise<void>;
}

const ManagePartnersActionsCell: React.FC<IProps> = (props) => {

    const [viewEditModal, setViewEditModal] = useState(false);
    const [viewDeleteModal, setViewDeleteModal] = useState<boolean>(false);

    /**
     * Hide or show the edit
     *
     */
    function toggleEditModal(): void {
        setViewEditModal(!viewEditModal);
    }

    /**
     * Hide or show the delete modal.
     *
     */
    function toggleDeleteModal(): void {
        setViewDeleteModal(!viewDeleteModal);
    }

    /**
     * When edit or delete is successful, close modal and recall api.
     *
     */
    function onDoneEditOrDeleteModal() {
        setViewEditModal(false);
        setViewDeleteModal(false);
        props.onDone().then().catch();
    }

    return (
        <React.Fragment>
            <ManagePartnersEditModal
                partner={props.partner}
                isOpen={viewEditModal}
                onClose={toggleEditModal}
                onDone={onDoneEditOrDeleteModal}
            />

            {/*<ManagePartnersDeleteModal*/}
            {/*    partner={props.partner}*/}
            {/*    isOpen={viewDeleteModal}*/}
            {/*    onClose={toggleDeleteModal}*/}
            {/*    onDone={onDoneEditOrDeleteModal}*/}
            {/*/>*/}

            <div className="frame-one-table-cell">
                <FrameButton
                    className="frame-one-button-small"
                    color="mediumGray"
                    outline={true}
                    icon={FiEdit2}
                    onClick={toggleEditModal}
                />

                {/*<FrameButton*/}
                {/*    className="frame-one-button-small"*/}
                {/*    color="danger"*/}
                {/*    icon={FiTrash}*/}
                {/*    onClick={toggleDeleteModal}*/}
                {/*/>*/}
            </div>
        </React.Fragment>
    );
}

export default ManagePartnersActionsCell;
