import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";
import {User} from "client";
import ManageUsersToggleEnableModal from "../../modals/ManageUsersToggleEnableModal";

interface IProps {
	user: User;
	onDone: () => Promise<void>;
}

const ManageUsersToggleEnableCell: React.FC<IProps> = (props) => {

	const [showModal, setShowModal] = useState(false);

	/**
	 * Hide or Show the toggle modal.
	 *
	 */
	function toggleShowToggleModal(): void {
		setShowModal(!showModal);
	}

	/**
	 * Hide the toggle modal and call the
	 * props.onDone function to update the table.
	 *
	 */
	function onDoneToggleModal(): void {
		setShowModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			<ManageUsersToggleEnableModal
				isOpen={showModal}
				user={props.user}
				onClose={toggleShowToggleModal}
				onDone={onDoneToggleModal}
			/>

			<div className="frame-one-table-cell">
				<FrameButton
					color={props.user?.disabled ? "darkBlue" : "darkPurple"}
					onClick={toggleShowToggleModal}
				>
					{`${props.user?.disabled ? "Enable" : "Disable"} User`}
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default ManageUsersToggleEnableCell;
