import React, {ReactNode, useState} from "react";
import LabelWithIcon from "../LabelWithIcon";
import {FiPlus, VscSymbolMisc} from "react-icons/all";
import FrameOneCard from "../FrameOneCard";
import {IBundleItemBodyFrontend} from "../../pages/CreateBundle";
import FrameButton from "../buttons/FrameButton";
import CreateBundleAddItemModal from "./CreateBundleAddItemModal";
import FrameOneButtonActions from "../FrameOneButtonActions";
import {cloneDeep} from "lodash";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import {TableData} from "frame-one-table/build/contextTypes";
import CreateBundleItemsActionsCell from "../tables/cells/CreateBundleItemsActionsCell";
import {Equipment} from "client";

interface IProps {
	items: Array<IBundleItemBodyFrontend>;
	onChangeInventorySelections: (newSelections: Array<IBundleItemBodyFrontend>) => void;
}

const CreateBundleInventorySelection: React.FC<IProps> = (props) => {

	const [showAddModal, setShowAddModal] = useState(false);

	/**
	 * Hide or show the Add Item Modal.
	 *
	 */
	function toggleShowAddModal(): void {
		setShowAddModal(!showAddModal);
	}

	/**
	 * Handle adding a new item from the modal, and close it afterwards.
	 *
	 * @param item
	 */
	function onDoneAddingItem(item: IBundleItemBodyFrontend): void {
		const itemsCopy: Array<IBundleItemBodyFrontend> = cloneDeep(props.items);
		itemsCopy.push(item);
		props.onChangeInventorySelections(itemsCopy);
		setShowAddModal(false);
	}

	/**
	 * Renderer for the action button cells.
	 *
	 * @param value
	 * @param bundleItem
	 * @param key
	 * @param data
	 * @param column
	 * @param index
	 */
	function makeActionsCell(value: never, bundleItem: IBundleItemBodyFrontend, key: string | number, data: TableData, column: ColumnOption, index: number): ReactNode {
		function onDeleteHelper(): void {
			const itemsCopy: Array<IBundleItemBodyFrontend> = cloneDeep(props.items);
			itemsCopy.splice(index, 1);
			props.onChangeInventorySelections(itemsCopy);
		}

		function onUpdateHelper(item: IBundleItemBodyFrontend): void {
			const itemsCopy: Array<IBundleItemBodyFrontend> = cloneDeep(props.items);
			itemsCopy[index] = item;
			props.onChangeInventorySelections(itemsCopy);
		}

		return (
			<div className="frame-one-table-cell">
				<CreateBundleItemsActionsCell
					item={bundleItem}
					onUpdate={onUpdateHelper}
					onDelete={onDeleteHelper}
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<CreateBundleAddItemModal
				isOpen={showAddModal}
				onClose={toggleShowAddModal}
				onDone={onDoneAddingItem}
			/>

			<FrameOneCard>
				<LabelWithIcon icon={VscSymbolMisc}>
					Items
				</LabelWithIcon>

				<hr/>

				{(props.items && props.items.length > 0) ? (
					<div className="narrow-frame-one-table-container mb-4">
						<FrameOneTableContainer
							data={props.items}
							columnOptions={[
								{
									key: "name",
									headerValue: "Name",
									showSortIcons: false,
									sortable: false,
								},
								{
									key: "quantity",
									headerValue: "Quantity",
									showSortIcons: false,
									sortable: false,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
								{
									key: "equipmentOptions",
									headerValue: "Inventory",
									showSortIcons: false,
									sortable: false,
									valueFormatter: (e: Array<Equipment>) => e?.length !== undefined ? e.length + " item(s)" : "-",
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
								{
									key: undefined,
									headerValue: "",
									showSortIcons: false,
									sortable: false,
									cellRender: makeActionsCell,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "justify-content-end",
								},
							]}
						/>
					</div>
				) : (
					<div className="my-5">
						<p className="empty-message">
							No Items Yet.
						</p>
					</div>
				)}

				<FrameOneButtonActions>
					<FrameButton
						color="green"
						onClick={toggleShowAddModal}
						icon={FiPlus}
					>
						Add
					</FrameButton>
				</FrameOneButtonActions>
			</FrameOneCard>
		</React.Fragment>
	);
};

export default CreateBundleInventorySelection;
