"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordBodyToJSON = exports.ChangePasswordBodyFromJSONTyped = exports.ChangePasswordBodyFromJSON = void 0;
function ChangePasswordBodyFromJSON(json) {
    return ChangePasswordBodyFromJSONTyped(json, false);
}
exports.ChangePasswordBodyFromJSON = ChangePasswordBodyFromJSON;
function ChangePasswordBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oldPassword': json['oldPassword'],
        'newPassword': json['newPassword'],
        'confirmPassword': json['confirmPassword'],
    };
}
exports.ChangePasswordBodyFromJSONTyped = ChangePasswordBodyFromJSONTyped;
function ChangePasswordBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
        'confirmPassword': value.confirmPassword,
    };
}
exports.ChangePasswordBodyToJSON = ChangePasswordBodyToJSON;
