import React from "react";
import {Token, UsersApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {generateUserFullName} from "../../utils/generateUserFullName";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	companyAdmin: any;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCompanyAdminsEnableModal: React.FC<IProps> = (props) => {

	/**
	 * Call the api to enable the user and then close the modal and get the updated list of data.
	 *
	 */
	async function confirmEnable(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).enableUser({
				id: props.companyAdmin?._id,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
		>
			<FrameModalHeader
				title="Confirm Re-enable"
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<p>
					Are you sure you want to re-enable <b>{generateUserFullName(props.companyAdmin)}</b>?
				</p>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					outline={true}
					onClick={props.onClose}
				>
					Cancel
				</FrameButton>

				<FrameButton
					color="success"
					onClick={confirmEnable}
				>
					Enable
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCompanyAdminsEnableModal);
