import React, {ReactNode} from "react";
import {svgSizer} from "../../utils/svgSizer";
import {FiEdit2} from "react-icons/all";

interface IProps {
	size?: string | number;
	icon?: ReactNode;
	onClick: () => void;
}

const IconButtonMini: React.FC<IProps> = (props) => {

	const Icon: any = props.icon;

	return (
		<div className="icon-button-mini-container">
			<div
				className="icon-button-mini-container_button"
				onClick={props.onClick}
				style={svgSizer(props.size)}
			>
				<Icon className="icon-button-mini-container_button_icon"/>
			</div>
		</div>
	);
};

IconButtonMini.defaultProps = {
	size: 36,
	icon: FiEdit2,
};

export default IconButtonMini;
