import React, {ReactNode, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {CompaniesApi, Company, GetCompaniesResponse, Token} from "client";
import getConfig from "../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AiOutlinePlus} from "react-icons/all";
import {Input} from "reactstrap";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import ManageCompaniesAddModal from "../components/modals/ManageCompaniesAddModal";
import CompanyCard2 from "../components/CompanyCard2";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageCompanies: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>("");
	const [firstRender, setFirstRender] = useState(true);
	const [showAddNewCompanyModal, setShowAddNewCompanyModal] = useState<boolean>(false);
	const [companies, setCompanies] = useState<GetCompaniesResponse>(undefined);
    const [gotResponseOnce, setGotResponseOnce] = useState(false);

	const history = useHistory();

	/**
	 * Sets a timeout after the user stops typing in the search input
	 * After timeout completes API is called with search property
	 *
	 */
	useEffect(() => {
		if (firstRender) {
            setFirstRender(false);
            readCompanies().then().catch();
			return;
		}

		const searchTimeout = setTimeout(() => {
			readCompanies().then().catch();
		}, 500);

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	function searchOnChange(e?): void {
		setSearchValue(e.target.value);
	}

	/**
	 * Show or Hide the Create Company Modal
	 */
	function toggleAddNewCompanyModal(): void {
		setShowAddNewCompanyModal(!showAddNewCompanyModal);
	}

	/**
	 * Hide the Add New Company Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddNewCompanyModal(): void {
		setShowAddNewCompanyModal(false);
		readCompanies().then().catch();
	}

	async function readCompanies(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CompaniesApi(getConfig(props.fullToken)).getCompanies({
				search: searchValue,
                limit: 100000,
                offset: 0,
			});

			setCompanies(res);
		} catch (e) {
			props.dispatch(addError(e))
		} finally {
            setGotResponseOnce(true);
		    props.dispatch(decrementLoading());
        }

	}

    /**
     * Render each company card
     *
     * @param company
     * @param index
     */
	function createCompanyCard(company: Company, index: number): ReactNode {
		return (
			<div
				key={`manage-companies-${index}`}
				className="col col-12 col-md-6 col-lg-12 col-xxl-6"
			>
				<CompanyCard2
					title={company?.name}
					address={company?.address?.formattedAddress}
					phoneNumber={company?.phoneNumber?.normalized}
					img={company?.image?.url}
					alt="Company Picture"
					buttonLabel="Edit company"

                    company={company}
                    onDone={readCompanies}
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<ManageCompaniesAddModal
				isOpen={showAddNewCompanyModal}
				onClose={toggleAddNewCompanyModal}
				onDone={onDoneAddNewCompanyModal}
			/>

			<div>
                <PageHeader>
                    <h3>
                        Manage Companies
                    </h3>

                    <div className="row gy-3">
                        <div className="col col-12 col-md-6">
                            <label>
                                Search
                            </label>
                            <Input
                                placeholder="Search for Companies"
                                value={searchValue}
                                onChange={searchOnChange}
                            />
                        </div>

                        <div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
                            <label className="ghost-label d-none d-md-block">Add</label>
                            <FrameButton
                                color="darkPurple"
                                icon={AiOutlinePlus}
                                onClick={toggleAddNewCompanyModal}
                                className="w-100"
                            >
                                Create New Company
                            </FrameButton>
                        </div>
                    </div>
                </PageHeader>

                <div className="p-3">
                    {companies?.companies && companies?.companies?.length > 0 ? (
                        <div className="row g-2">
                            {companies?.companies?.map(createCompanyCard)}
                        </div>
                    ) : (
                        <React.Fragment>
                            {gotResponseOnce && (
                                <h4 className="text-center">
                                    No Companies
                                </h4>
                            )}
                        </React.Fragment>
                    )}
                </div>
			</div>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCompanies);
