"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleBodyToJSON = exports.BundleBodyFromJSONTyped = exports.BundleBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BundleBodyFromJSON(json) {
    return BundleBodyFromJSONTyped(json, false);
}
exports.BundleBodyFromJSON = BundleBodyFromJSON;
function BundleBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': json['active'],
        'name': json['name'],
        'description': json['description'],
        'thumbnail': !runtime_1.exists(json, 'thumbnail') ? undefined : json['thumbnail'],
        'images': json['images'],
        'requiredCharacteristics': json['requiredCharacteristics'],
        'blockingCharacteristics': json['blockingCharacteristics'],
        'bookingTime': json['bookingTime'],
        'items': (json['items'].map(_1.BundleItemBodyFromJSON)),
    };
}
exports.BundleBodyFromJSONTyped = BundleBodyFromJSONTyped;
function BundleBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'name': value.name,
        'description': value.description,
        'thumbnail': value.thumbnail,
        'images': value.images,
        'requiredCharacteristics': value.requiredCharacteristics,
        'blockingCharacteristics': value.blockingCharacteristics,
        'bookingTime': value.bookingTime,
        'items': (value.items.map(_1.BundleItemBodyToJSON)),
    };
}
exports.BundleBodyToJSON = BundleBodyToJSON;
