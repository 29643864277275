import React, {ReactNode, useEffect, useState} from "react";
import {Button} from "reactstrap";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {Admin, GetUsersResponse, Token, UsersApi, UserType} from "client";
import getConfig from "../utils/getConfig";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import AddAdminModal from "../components/modals/AddAdminModal";
import {parseUserType} from "../utils/parseUserType";
import ManageAdminsEditPasswordCell from "../components/tables/cells/ManageAdminsEditPasswordCell";
import ManageAdminsToggleEnableCell from "../components/tables/cells/ManageAdminsToggleEnableCell";
import {convertUserDisabledStatusToEnabledString} from "../utils/convertUserDisabledStatusToEnabledString";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageAdmins: React.FC<IProps> = (props: IProps) => {

	const [showNewAdminModal, setShowNewAdminModal] = useState(false);
	const [admins, setAdmins] = useState<GetUsersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		readAdmins().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Show or Hide the Create Admin Modal.
	 *
	 */
	function toggleNewAdminModal(): void {
		setShowNewAdminModal(!showNewAdminModal);
	}

	/**
	 * Hide the Add New Admin Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneNewAdminModal(): void {
		setShowNewAdminModal(false);
		readAdmins().then().catch();
	}

	/**
	 * Call api to get & save the list of admins.
	 *
	 */
	async function readAdmins(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getUsers({
				limit: frontendPagination?.limit,
				offset: frontendPagination?.offset,
				type: [UserType.GODADMIN, UserType.STANDARDADMIN],
			});

			setAdmins(res);
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}


	/**
	 * Renderer for the Edit Password Cells.
	 *
	 * @param value
	 * @param admin
	 */
	function makeEditPasswordCell(value: never, admin: Admin): ReactNode {
		return (
			<ManageAdminsEditPasswordCell
				admin={admin}
				onDone={readAdmins}
			/>
		);
	}

	/**
	 * Renderer for the Toggle Enabled Cells.
	 *
	 * @param value
	 * @param admin
	 */
	function makeToggleEnableCell(value: never, admin: Admin): ReactNode {
		return (
			<ManageAdminsToggleEnableCell
				admin={admin}
				onDone={readAdmins}
			/>
		);
	}

	return (
		<div>
			<AddAdminModal
				isOpen={showNewAdminModal}
				onClose={toggleNewAdminModal}
				onDone={onDoneNewAdminModal}
			/>

			<PageHeader>
				<div>
					<h3>
						Manage Admins
					</h3>

					<p>
						On this page you can see a list of all the current admins in the system, as well as toggle
						their status
						between enabled & disabled, or update their passwords. You can also add a new admin with the
						button below.
					</p>

					<Button
						color="darkPurple"
						onClick={toggleNewAdminModal}
					>
						Create New Admin
					</Button>
				</div>
			</PageHeader>

			<div className="p-3">
				<FrameOneTableContainer
					data={admins?.users}
					pagination={{
						...admins?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "username",
							headerValue: "Username",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "type",
							headerValue: "Type",
							showSortIcons: false,
							sortable: false,
							valueFormatter: parseUserType,
						},
						{
							key: "firstName",
							headerValue: "First Name",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "lastName",
							headerValue: "Last Name",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "disabled",
							headerValue: "Enabled",
							showSortIcons: false,
							sortable: false,
							valueFormatter: convertUserDisabledStatusToEnabledString,
						},
						{
							key: undefined,
							headerValue: "Edit Password",
							showSortIcons: false,
							sortable: false,
							cellRender: makeEditPasswordCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: undefined,
							headerValue: "Enable/Disable Admin",
							showSortIcons: false,
							sortable: false,
							cellRender: makeToggleEnableCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageAdmins);
