import React from "react";
import {Bundle} from "client";
import CompanyPhoto from "../../CompanyPhoto";

interface IProps {
	bundle: Bundle;
}

const ManageBundlesImageCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<div className="manage-bundles-image-cell">
				<CompanyPhoto
					src={props.bundle?.thumbnail?.url}
					alt="Bundle Picture"
					className="manage-bundles-image-cell_company-profile-picture"
				/>
			</div>
		</div>
	);
};

export default ManageBundlesImageCell;
