import InitialMetaStore, {IMetaStore} from "./InitialMetaStore";
import {IAction} from "../IAction";
import {cloneDeep} from "lodash";

export enum MetaAction {
	LOGIN = "LOGIN",
	LOGOUT = "LOGOUT",
	LOADING = "LOADING",
	ADD_ERROR = "ADD_ERROR",
	REMOVE_ERROR = "REMOVE_ERROR",
	SIDEBAR = "SIDEBAR",
	GlobalContactModal = "GlobalContactModal",
	UpdateCurrentUser = "UpdateCurrentUser",
}

export default function(store: IMetaStore = InitialMetaStore, a: IAction<MetaAction, any>): IMetaStore {

	const n: IMetaStore = cloneDeep(store);

	switch(a.type) {
		case MetaAction.LOGIN:
			n.fullToken = a.payload;
			break;
		case MetaAction.LOGOUT:
			delete n.fullToken;
			delete n.currentUser;
			break;
		case MetaAction.LOADING:
			if (n.loadingIncrement + a.payload >= 0) {
				n.loadingIncrement += a.payload;
			} else {
				n.loadingIncrement = 0;
			}
			break;
		case MetaAction.ADD_ERROR:
			n.errors.push(a.payload);
			break;
		case MetaAction.REMOVE_ERROR:
			n.errors.splice(a.payload, 1);
			break;
		case MetaAction.SIDEBAR:
			n.sidebarVisible = a.payload;
			break;
		case MetaAction.GlobalContactModal:
			n.globalContactModalVisible = a.payload;
			break;
		case MetaAction.UpdateCurrentUser:
			n.currentUser = a.payload;
			break;
		default:
			break;
	}

	return n;
}
