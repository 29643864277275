import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {Bundle, Characteristic, CharacteristicsApi, GetCharacteristicsResponse, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "../modals/modalComponents/FrameModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {defaultFrontendPagination, FrontendPagination} from "../tables/FrameOnePaginator";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import {Input} from "reactstrap";
import FrameButton from "../buttons/FrameButton";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import ManageBundlesImageCell from "../tables/cells/ManageBundlesImageCell";
import {cloneDeep, find, findIndex, indexOf} from "lodash";
import GenericCheckBoxTableCell from "../tables/cells/GenericCheckBoxTableCell";
import ManageCharacteristicsImageCell from "../tables/cells/ManageCharacteristicsImageCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	title: string;
	selectedCharacteristics: Array<Characteristic>;
	onChange: (selectedCharacteristics: Array<Characteristic>) => void;
	isOpen: boolean;
	onClose: () => void;
}

const CreateBundleBrowseCharacteristicsModal: React.FC<IProps> = (props) => {

	const [search, setSearch] = useState(undefined);
	const [characteristics, setCharacteristics] = useState<GetCharacteristicsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	/**
	 * Call the api to get characteristics when this modal opens, if there is no existing response
	 * (should only call on first open, and any subsequent calls will be the result of filtering or pagination changes).
	 *
	 */
	useEffect(() => {
		if (props.isOpen && characteristics === undefined) {
			getCharacteristics().then().catch();
		}
	}, [props.isOpen]);

	/**
	 * Handle the user typing in the search field.
	 *
	 * @param e
	 */
	function searchOnChange(e: ChangeEvent<HTMLInputElement>): void {
		setSearch(e.target.value);
	}

	/**
	 * Call the api with the specified search value & save the results.
	 *
	 */
	async function getCharacteristics(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new CharacteristicsApi(getConfig(props.fullToken)).getCharacteristics({
				search,
				...frontendPagination,
			});

			setCharacteristics(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Selection Cells.
	 * Adds the functionality for selecting or deselecting a characteristic.
	 *
	 * @param value
	 * @param characteristic
	 */
	function makeSelectionCell(value: never, characteristic: Characteristic): ReactNode {
		const selectionsCopy: Array<Characteristic> = cloneDeep(props.selectedCharacteristics);
		const selectedIndex: number = findIndex(selectionsCopy, ["_id", characteristic._id]);

		function onSelectHelper(): void {
			if (selectedIndex > -1) {
				selectionsCopy.splice(selectedIndex, 1);
			} else {
				selectionsCopy.push(characteristic);
			}

			props.onChange(selectionsCopy);
		}

		return (
			<GenericCheckBoxTableCell
				checked={selectedIndex > -1}
				onToggle={onSelectHelper}
				enableOnClickHelper={false}
			/>
		);
	}

	/**
	 * Renderer for the Bundle Image Cells.
	 *
	 * @param value
	 * @param characteristic
	 */
	function makeImageCell(value: never, characteristic: Characteristic): ReactNode {
		return (
			<ManageCharacteristicsImageCell characteristic={characteristic}/>
		);
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="xl"
		>
			<FrameModalHeader
				title={props.title}
				toggle={props.onClose}
			>
				<form onSubmit={getCharacteristics}>
					<div className="d-flex flex-column flex-sm-row col-12 col-lg-9 col-xl-6">
						<Input
							placeholder="Search Characteristics..."
							value={search}
							onChange={searchOnChange}
							className="mb-3 mb-sm-0 me-0 me-sm-3"
						/>

						<FrameButton
							type="submit"
							onClick={getCharacteristics}
							color="darkPurple"
						>
							Search
						</FrameButton>
					</div>
				</form>
			</FrameModalHeader>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container">
					<FrameOneTableContainer
						data={characteristics?.characteristics}
						pagination={{
							...characteristics?.paginationInfo,
							...frontendPagination,
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "",
								headerValue: "Select",
								showSortIcons: false,
								sortable: false,
								cellRender: makeSelectionCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "image",
								headerValue: "Picture",
								showSortIcons: false,
								sortable: false,
								cellRender: makeImageCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "name",
								headerValue: "Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "description",
								headerValue: "Description",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "minimumPointValue",
								headerValue: "Min. point value",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "maximumPointValue",
								headerValue: "Max. point value",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="darkBlue"
					onClick={props.onClose}
				>
					Done
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateBundleBrowseCharacteristicsModal);
