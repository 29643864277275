import React from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Token} from "client";
import LoginCard from "../components/LoginCard";
import LoginFooter from "../components/LoginFooter";
import {ReactComponent as BackgroundGraphic} from "../svgs/login-gradient_shapes.svg";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const Login: React.FC<IProps> = (props) => {

	return (
		<div className="login-page">
			<div className="login-page_background-container">
				<div className="login-page_background-container_graphic-container">
					<BackgroundGraphic className="login-page_background-container_graphic-container_graphic"/>
				</div>

				<div className="login-page_background-container_solid"/>
			</div>

			<div className="login-page_content">
				<div className="login-page_content_spacer"/>

				<div className="login-page_content_container">
					<LoginCard/>

					<div className="login-page_content_spacer"/>

					<LoginFooter/>
				</div>

				<div className="login-page_content_spacer"/>
			</div>
		</div>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		fullToken: store.metaStore.fullToken
	}
})(Login);
