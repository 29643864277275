import React, {useState} from "react";
import FrameButton from "./buttons/FrameButton";
import CompanyPhoto from "./CompanyPhoto";
import { Company } from "client";
import ManageCompaniesEditModal from "./modals/ManageCompaniesEditModal";

interface IProps {
	title: string;
	address?: string;
	phoneNumber?: string;
	img?: string;
	alt?: string;
	buttonLabel: string;

    company: Company;
    onDone: () => Promise<void>;
}

const CompanyCard2: React.FC<IProps> = (props) => {

    const [showEditModal, setShowEditModal] = useState(false);

    /**
     * Show or hide the edit modal.
     *
     */
    function toggleShowEditModal(): void {
        setShowEditModal(!showEditModal);
    }

    /**
     * When done editing, close modal & call api to get updated list.
     *
     */
    function onDoneEditModal(): void {
        setShowEditModal(false);
        props.onDone().then().catch();
    }

	return (
		<React.Fragment>
            <ManageCompaniesEditModal
                company={props.company}
                isOpen={showEditModal}
                onClose={toggleShowEditModal}
                onDone={onDoneEditModal}
            />

			<div className="company-card-2">
				<div className="company-card-2_info">
					<div className="company-card-2_info_image-spacer">
						<CompanyPhoto
							src={props.img}
							alt={props.alt}
							className="company-card-2_info_image-spacer_img"
						/>
					</div>

					<div className="company-card-2_info_content">
						<h5 className="company-card-2_info_content_title">
							{props.title}
						</h5>

						{props.address && (
							<p className="company-card-2_info_content_subtitle">
								{props.address}
							</p>
						)}

						{props.phoneNumber && (
							<p className="company-card-2_info_content_subtitle">
								{props.phoneNumber}
							</p>
						)}
					</div>
				</div>

				<div className="company-card-2_button-container">
					<FrameButton
						color="mediumGray"
						outline={true}
						onClick={toggleShowEditModal}
					>
						{props.buttonLabel}
					</FrameButton>
				</div>
			</div>
		</React.Fragment>
	);
}

export default CompanyCard2;
