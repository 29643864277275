"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingToJSON = exports.BookingFromJSONTyped = exports.BookingFromJSON = void 0;
const _1 = require("./");
function BookingFromJSON(json) {
    return BookingFromJSONTyped(json, false);
}
exports.BookingFromJSON = BookingFromJSON;
function BookingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'cancelled': json['cancelled'],
        'current': json['current'],
        'user': _1.StandardUserFromJSON(json['user']),
        'bundle': _1.BundleFromJSON(json['bundle']),
        'items': (json['items'].map(_1.EquipmentBookingFromJSON)),
        'times': _1.TimeIntervalFromJSON(json['times']),
    };
}
exports.BookingFromJSONTyped = BookingFromJSONTyped;
function BookingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'cancelled': value.cancelled,
        'current': value.current,
        'user': _1.StandardUserToJSON(value.user),
        'bundle': _1.BundleToJSON(value.bundle),
        'items': (value.items.map(_1.EquipmentBookingToJSON)),
        'times': _1.TimeIntervalToJSON(value.times),
    };
}
exports.BookingToJSON = BookingToJSON;
