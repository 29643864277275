import React, {useState} from "react";
import {Equipment} from "client";
import CreateBundleAddItemAttributesModal from "../../bundle/CreateBundleAddItemAttributesModal";

interface IProps {
	equipment: Equipment;
}

const CreateBundleAddItemAttributesCell: React.FC<IProps> = (props) => {

	const [showAttributesModal, setShowAttributesModal] = useState(false);

	/**
	 * Hides or shows the Attributes Modal.
	 *
	 */
	function toggleAttributesModal(): void {
		setShowAttributesModal(!showAttributesModal);
	}

	return (
		<React.Fragment>
			<CreateBundleAddItemAttributesModal
				isOpen={showAttributesModal}
				onClose={toggleAttributesModal}
				equipment={props.equipment}
			/>

			<div className="frame-one-table-cell">
				{Object.entries(props.equipment?.attributes)?.length > 0 ? (
					<a
						href="#"
						onClick={toggleAttributesModal}
					>
						{`${Object.entries(props.equipment?.attributes)?.length} (View)`}
					</a>
				) : (
					<span className="empty-message">
						No Attributes.
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default CreateBundleAddItemAttributesCell;
