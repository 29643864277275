import React, {ReactNode, useEffect, useState} from "react";
import {GeoJsonType, GetPartnersResponse, Partner, PartnersApi, Token} from "client";
import getConfig from "../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AiOutlinePlus} from "react-icons/all";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {Input} from "reactstrap";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import FrameButton from "../components/buttons/FrameButton";
import BreadCrumbs, {IBreadCrumbItem} from "../components/BreadCrumbs";
import ManagePartnersActionsCell from "../components/tables/cells/ManagePartnersActionsCell";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import formatAddress from "../utils/formatAddress";
import CompanyPhoto from "../components/CompanyPhoto";
import ManagePartnersImageCell from "../components/tables/cells/ManagePartnersImageCell";
import ManagePartnersAddModal from "../components/modals/ManagePartnersAddModal";
import {valueOrBustDynamic} from "../utils/valueOrBust";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManagePartners: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>("");
	const [firstRender, setFirstRender] = useState(true);
	const [showAddNewPartnerModal, setShowAddNewPartnerModal] = useState<boolean>(false);
	const [partners, setPartners] = useState<GetPartnersResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readPartners().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input.
	 * After timeout completes API is called with search property.
	 *
	 */
	useEffect(() => {
		if (firstRender) {
			return;
		}

		const searchTimeout = setTimeout(() => {
			readPartners().then().catch();
		}, 500);

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e?.target.value);
	}

	/**
	 * Show or Hide the Create Partners Modal
	 */
	function toggleAddPartnerModal(): void {
		setShowAddNewPartnerModal(!showAddNewPartnerModal);
	}

	/**
	 * Hide the Add New Partner Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddPartnerModal(): void {
		setShowAddNewPartnerModal(false);
		readPartners().then().catch();
	}

	/**
	 * Calling API to get all the Partners
	 *
	 */
	async function readPartners(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new PartnersApi(getConfig(props.fullToken)).getPartners({
				search: searchValue,
				...frontendPagination,
			});

			setPartners(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Profile Image Cells.
	 *
	 * @param value
	 * @param partner
	 */
	function makeImageCell(value: never, partner: Partner): ReactNode {
		return (
			<ManagePartnersImageCell partner={partner}/>
		);
	}

	/**
	 * Renderer for the Edit & Delete Partner Cells.
	 *
	 */
	function makeActionsCell(value: never, partner: Partner): ReactNode {
		return (
			<ManagePartnersActionsCell
				partner={partner}
				onDone={readPartners}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManagePartnersAddModal
				isOpen={showAddNewPartnerModal}
				onClose={toggleAddPartnerModal}
				onDone={onDoneAddPartnerModal}
			/>

			<div>
				<PageHeader>
					<h3>
						Manage Partners
					</h3>

					<div className="row gy-3">
						<div className="col col-12 col-md-6">
							<label>
								Search
							</label>
							<Input
								placeholder="Search for Partners"
								value={searchValue}
								onChange={searchOnChange}
							/>
						</div>

						<div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
							<label className="ghost-label d-none d-md-block">Add</label>
							<FrameButton
								color="darkPurple"
								icon={AiOutlinePlus}
								onClick={toggleAddPartnerModal}
								className="w-100"
							>
								Create New Partner
							</FrameButton>
						</div>
					</div>
				</PageHeader>

				<div className="p-3">
					<FrameOneTableContainer
						data={partners?.partners}
						pagination={{
							...partners?.paginationInfo,
							...frontendPagination,
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "image",
								headerValue: "Picture",
								showSortIcons: false,
								sortable: false,
								cellRender: makeImageCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "name",
								headerValue: "Name",
								showSortIcons: false,
								sortable: false,
								rowCellClassName: "w-100",
							},
							{
								key: "phoneNumber",
								headerValue: "Phone Number",
								showSortIcons: false,
								sortable: false,
								valueFormatter: valueOrBustDynamic(formatPhoneNumber),
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "address",
								headerValue: "Address",
								showSortIcons: false,
								sortable: false,
								valueFormatter: formatAddress,
							},
							{
								key: undefined,
								headerValue: "Actions",
								showSortIcons: false,
								sortable: false,
								cellRender: makeActionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManagePartners);
