import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {FiMail, FiPhoneCall} from "react-icons/all";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface IProps {
	isOpen: boolean;
	toggle: () => void;
}

const ContactModal: React.FC<IProps> = (props) => {

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.toggle}
		>
			<FrameModalHeader
				title="Contact Support"
				toggle={props.toggle}
			/>

			<FrameModalBody>
				<p>Need to get in touch with Frame One Software? Text, call, or email us any time.</p>

				<div className="mb-3">
					<a
						href="tel:6043195219"
						className="text-darkBlue text-decoration-none"
					>
						<FiPhoneCall size="1.5rem" style={{maxHeight: 55}} className="mr-3"/>
						(604) 319-5219
					</a>
				</div>

				<div>
					<a
						href="mailto:christopher@frameonesoftware.com?subject=Frame One Software Support"
						target="_blank"
						rel="noopener noreferrer"
						className="text-darkBlue text-decoration-none"
					>
						<FiMail size="1.5rem" style={{maxHeight: 55}} className="mr-3"/>
						Christopher@frameonesoftware.com
					</a>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<Button
					color="darkPurple"
					onClick={props.toggle}
				>
					Close
				</Button>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default ContactModal;
