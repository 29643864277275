import React, {useState} from "react";
import {Equipment} from "client";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import {getPaginationInfo} from "../../utils/paginationUtils";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {FrontendPagination} from "../tables/FrameOnePaginator";

interface IAttributesAsObject {
	key: string;
	value: string;
}

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	equipment: Equipment;
}

/**
 * Modal with table displaying attributes for the Equipment in question.
 * Uses purely frontend pagination instead of api calls for pagination changes.
 *
 * @param props
 * @constructor
 */
const CreateBundleAddItemAttributesModal: React.FC<IProps> = (props) => {

	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>({offset: 0, limit: 30});

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title={`Attributes of ${props.equipment?.partner?.name}'s ${props.equipment?.name}`}
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container">
					<FrameOneTableContainer
						data={Object.entries(props.equipment?.attributes).map((a): IAttributesAsObject => {
							return {
								key: a[0],
								value: a[1],
							}
						})}
						pagination={{
							...frontendPagination,
							...getPaginationInfo(frontendPagination, Object.entries(props.equipment?.attributes)?.length),
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "key",
								headerValue: "Attribute Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "value",
								headerValue: "Value",
								showSortIcons: false,
								sortable: false,
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default CreateBundleAddItemAttributesModal;
