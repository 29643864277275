import React, {useState} from "react";
import {Equipment} from "client";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import moment from "moment";
import {FrontendPagination} from "../tables/FrameOnePaginator";
import {getPaginationInfo} from "../../utils/paginationUtils";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	equipment: Equipment;
}

/**
 * Modal with table displaying availability for the Equipment in question.
 * Uses purely frontend pagination instead of api calls for pagination changes.
 *
 * @param props
 * @constructor
 */
const CreateBundleAddItemSchedulingModal: React.FC<IProps> = (props) => {

	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>({offset: 0, limit: 30});

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title={`Availability for ${props.equipment?.partner?.name}'s ${props.equipment?.name}`}
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container">
					<FrameOneTableContainer
						data={props.equipment?.availability}
						pagination={{
							...frontendPagination,
							...getPaginationInfo(frontendPagination, props.equipment?.availability?.length),
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
							{
								key: "start",
								headerValue: "Start",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (d: number) => moment(d).format("MMMM D YYYY"),
							},
							{
								key: "end",
								headerValue: "End",
								showSortIcons: false,
								sortable: false,
								valueFormatter: (d: number) => moment(d).format("MMMM D YYYY"),
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default CreateBundleAddItemSchedulingModal;
