import React, {ReactNode} from "react";
import {Col, Row} from "reactstrap";

interface IProps {
	title: string;
	children: ReactNode;
}

const SectionLabel: React.FC<IProps> = (props) => {

	return (
		<div className="section-label">
			<div className="row">
				<div className="col col-12 col-xl-3">
					<p className="section-label_title">
						{props.title}
					</p>
				</div>

				<div className="col col-12 col-xl-9">
					{props.children}
				</div>
			</div>
		</div>
	);
};

export default SectionLabel;
