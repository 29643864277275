import React, {ReactNode} from "react";
import classNames from "classnames";
import {Label} from "reactstrap";

interface IProps {
    icon: ReactNode;
    children: string;
    className?: string;
    iconClassName?: string;
}

const LabelWithIcon: React.FC<IProps> = (props) => {

    const Icon: any = props.icon;

    return (
        <div className={classNames("label-with-icon", props.className)}>
            {Icon && (
                <Icon className={props.iconClassName}/>
            )}

            <Label className="label-with-icon_label">
                {props.children}
            </Label>
        </div>
    );
};

export default LabelWithIcon;
