import React, {ReactNode, useEffect, useState} from "react";
import {Input} from "reactstrap";
import {Characteristic, CharacteristicsApi, GetCharacteristicsResponse, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AiOutlinePlus} from "react-icons/all";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import PageHeader from "../components/PageHeader";
import FrameButton from "../components/buttons/FrameButton";
import ManageCharacteristicsActionsCell from "../components/tables/cells/ManageCharacteristicsActionsCell";
import getConfig from "../utils/getConfig";
import ManageCharacteristicsAddModal from "../components/modals/ManageCharacteristicsAddModal";
import ManageCharacteristicsImageCell from "../components/tables/cells/ManageCharacteristicsImageCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageCharacteristics: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>(undefined);
	const [firstRender, setFirstRender] = useState(true);
	const [showAddNewCharacteristicModal, setShowAddNewCharacteristicModal] = useState(false);
	const [characteristics, setCharacteristics] = useState<GetCharacteristicsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readCharacteristics().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input
	 * After timeout completes API is called with search property
	 *
	 */
	useEffect(() => {
		if (firstRender) {
			return;
		}

		const searchTimeout = searchValue !== undefined ? setTimeout(() => {
			readCharacteristics().then().catch();
		}, 1000) : null;

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e.target.value);
	}

	/**
	 * Show or Hide the Add Characteristic Modal.
	 *
	 */
	function toggleAddNewCharacteristicModal(): void {
		setShowAddNewCharacteristicModal(!showAddNewCharacteristicModal);
	}

	/**
	 * Hide the Add Characteristic Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddNewCharacteristicModal(): void {
		setShowAddNewCharacteristicModal(false);
		readCharacteristics().then().catch();
	}

	/**
	 * Call to api to get Characteristics.
	 *
	 */
	async function readCharacteristics(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CharacteristicsApi(getConfig(props.fullToken)).getCharacteristics({
				search: searchValue,
				...frontendPagination,
			});

			setCharacteristics(res);
		} catch (e) {
			props.dispatch(addError(e))
		} finally {
			props.dispatch(decrementLoading());
		}
	}

    /**
     * Renderer for the Characteristic Image Cells.
     *
     * @param value
     * @param characteristic
     */
    function makeImageCell(value: never, characteristic: Characteristic): ReactNode {
        return (
            <ManageCharacteristicsImageCell characteristic={characteristic}/>
        );
    }

	/**
	 * Renderer for the Edit & Delete Characteristics Cell.
     *
	 */
	function makeActionsCell(value: never, characteristic: any): ReactNode {
		return (
			<ManageCharacteristicsActionsCell
				characteristic={characteristic}
				onDone={readCharacteristics}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageCharacteristicsAddModal
				isOpen={showAddNewCharacteristicModal}
				onClose={toggleAddNewCharacteristicModal}
				onDone={onDoneAddNewCharacteristicModal}
			/>

			<div>
				<PageHeader>
					<h3>
						Manage Characteristics
					</h3>

                    <div className="row gy-3">
                        <div className="col col-12 col-md-6">
                            <label>
                                Search
                            </label>
                            <Input
                                placeholder="Search for Characteristics"
                                value={searchValue}
                                onChange={searchOnChange}
                            />
                        </div>

                        <div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
                            <label className="ghost-label d-none d-md-block">Add</label>
                            <FrameButton
                                color="darkPurple"
                                icon={AiOutlinePlus}
                                onClick={toggleAddNewCharacteristicModal}
                                className="w-100"
                            >
                                Create New Characteristic
                            </FrameButton>
                        </div>
                    </div>
				</PageHeader>

				<div className="p-3">
					<FrameOneTableContainer
						data={characteristics?.characteristics}
						pagination={{
						    ...characteristics?.paginationInfo,
						    ...frontendPagination,
						}}
						onPaginationChange={setFrontendPagination}
						columnOptions={[
                            {
                                key: "image",
                                headerValue: "Picture",
                                showSortIcons: false,
                                sortable: false,
                                cellRender: makeImageCell,
	                            headerCellClassName: "justify-content-center",
	                            rowCellClassName: "justify-content-center",
                            },
                            {
                                key: "name",
                                headerValue: "Name",
                                showSortIcons: false,
                                sortable: false,
                            },
							{
								key: "description",
								headerValue: "Description",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "minimumPointValue",
								headerValue: "Minimum point value",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
                            {
                                key: "maximumPointValue",
                                headerValue: "Maximum point value",
                                showSortIcons: false,
                                sortable: false,
	                            headerCellClassName: "justify-content-end",
	                            rowCellClassName: "text-end",
                            },
							{
								key: undefined,
								headerValue: "Actions",
								showSortIcons: false,
								sortable: false,
								cellRender: makeActionsCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
						]}
					/>
				</div>
			</div>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCharacteristics);
