import React, {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {AssetsApi, CompaniesApi, Company, CompanyBody, PhoneNumberBody, Token, UpdateCompanyRequest} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, IFrontendFileType} from "../../utils/renderAssetsHelper";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameModalBody from "./modalComponents/FrameModalBody";
import {AiOutlineInfoCircle} from "react-icons/all";
import LabelWithIcon from "../LabelWithIcon";
import {Input, Label} from "reactstrap";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import GooglePlacesInput from "../inputs/GooglePlacesInput";
import CompanyPhoto from "../CompanyPhoto";
import FileInputButton from "../inputs/FileInputButton";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";

interface IUpdateCompanyRequestFrontend extends Omit<CompanyBody, "id" | "image"> {
	image: IFrontendFileType;
}

const defaultUpdateCompanyForm: IUpdateCompanyRequestFrontend = {
	name: "",
	phoneNumber: {
		countryCode: undefined,
		nationalNumber: "",
	},
	placeID: "",
	image: undefined,
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	company: Company;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCompaniesEditModal: React.FC<IProps> = (props) => {

	const [editForm, setEditForm] = useState<IUpdateCompanyRequestFrontend>(defaultUpdateCompanyForm);

	useEffect(() => {
		resetForm(props.company);
	}, [JSON.stringify(props.company)]);

	/**
	 * Reset the edit form to its default for this Company.
	 *
	 * @param _company
	 */
	function resetForm(_company: Company): void {
		setEditForm({
			name: _company?.name,
			phoneNumber: {
				countryCode: _company?.phoneNumber?.countryCode,
				nationalNumber: _company?.phoneNumber?.nationalNumber,
			},
			placeID: _company?.address?.placeID,
			image: _company?.image,
		});
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		resetForm(props.company);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IUpdateCompanyRequestFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEditForm({
				...editForm,
				[key]: e?.target.value,
			})
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setEditForm({
				...editForm,
				phoneNumber: {
					...editForm.phoneNumber,
					[key]: dialCodePart,
				},
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setEditForm({
				...editForm,
				phoneNumber: {
					...editForm.phoneNumber,
					[key]: values.value,
				},
			});
		}
	}

	/**
	 * Custom onChange for the places autocomplete because of how we return the data from that component.
	 *
	 * @param placeID
	 */
	function setPlaceID(placeID: string): void {
		setEditForm({
			...editForm,
			placeID: placeID,
		});
	}

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setEditForm({
			...editForm,
			image: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Create the request object, submit the api, and call the props function to close the modal & get new data.
	 *
	 */
	async function submitUpdatedCompany(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		const updateRequest: Partial<CompanyBody> = {
			name: editForm?.name || undefined,
			phoneNumber: (editForm?.phoneNumber?.countryCode || editForm?.phoneNumber?.nationalNumber) ? {
				countryCode: editForm?.phoneNumber?.countryCode,
				nationalNumber: editForm?.phoneNumber?.nationalNumber || undefined,
			} : undefined,
			placeID: editForm?.placeID || undefined,
		};

		// Check if the image in our edit form is the frontend type of file, in which case add it to the form
		// (Can't pass the Asset back in to the form if it existed when starting the edit).
		// Otherwise, check if the existing company has an image associated with them and use that image's _id for the imageID field
		if (isFileWithSRC(editForm.image)) {
			updateRequest.image = (await new AssetsApi(getConfig(props.fullToken)).createAsset({
				asset: editForm?.image,
			}))._id;
		} else if (props.company?.image) {
			updateRequest.image = props.company?.image?._id;
		}

		try {
			await new CompaniesApi(getConfig(props.fullToken)).updateCompany({
				id: props.company?._id,
				companyBody: updateRequest as CompanyBody,
			});
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={resetAndClose}
		>
			<FrameModalHeader
				title="Edit Company"
				toggle={resetAndClose}
			/>

			<form onSubmit={submitUpdatedCompany}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Editing Company
					</LabelWithIcon>

					<div className="mb-3">
						<Label>
							Name*
						</Label>
						<Input
							placeholder="Name..."
							value={editForm.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Phone Number
						</label>

						<div className="row gx-1 gx-sm-3">
							<div className="col col-4 col-sm-3">
								<CountryCodeSelector
									value={editForm?.phoneNumber?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange("countryCode")}
								/>
							</div>

							<div className="col col-8 col-sm-9">
								<NumberFormat
									placeholder="(000) 000-0000"
									value={editForm.phoneNumber?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
								/>
							</div>
						</div>
					</div>

					<div className="mb-3">
						<Label>
							Location
						</Label>
						<GooglePlacesInput
							initialInputValue={props.company?.address?.formattedAddress}
							setPlaceID={setPlaceID}
						/>
					</div>

					<div>
						<Label>
							Company Image
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={editForm.image && (isFileWithSRC(editForm.image) ? editForm.image.imageSRC as string : editForm.image?.url)}
								alt="Company image"
								className="w-50 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onFileChange}
							>
								<FrameButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</FrameButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="mediumGray"
						outline={true}
						onClick={resetAndClose}
					>
						Cancel
					</FrameButton>

					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitUpdatedCompany}
					>
						Update Company
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCompaniesEditModal);
