import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {Input, Label} from "reactstrap";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {
	CompaniesApi,
	Company,
	CompanyAdmin,
	CreateCompanyAdminBody,
	PartnersApi,
	PhoneNumberBody,
	Token,
	UsersApi
} from "client";
import getConfig from "../../utils/getConfig";
import {AiOutlineInfoCircle, AiOutlinePlus} from "react-icons/all";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameButton from "../buttons/FrameButton";
import LabelWithIcon from "../LabelWithIcon";
import {
    IReactSelectOption,
    makeReactSelectOptions,
    reverseLookUpReactSelectValue
} from "../../utils/reactSelectHelpers";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import CountryCodeSelector from "../inputs/CountryCodeSelector";

const defaultAddNewCompanyAdminForm: CreateCompanyAdminBody = {
	companyID: undefined,
	email: "",
	phoneNumberBody: {
		countryCode: undefined,
		nationalNumber: "",
	},
	firstName: "",
	lastName: "",
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCompanyAdminsAddModal: React.FC<IProps> = (props: IProps) => {

	const [companies, setCompanies] = useState<Array<Company>>(undefined);
	const [createForm, setCreateForm] = useState<CreateCompanyAdminBody>(defaultAddNewCompanyAdminForm);

	useEffect(() => {
		getCompanies().then().catch();
	}, []);

	/**
	 * Get the list of Companies for the dropdown.
	 *
	 */
	async function getCompanies(): Promise<void> {
		try {
			const res = await new CompaniesApi(getConfig(props.fullToken)).getCompanies({
				offset: 0,
				limit: 100000,
			});

			setCompanies(res.companies);
		} catch (e) {

		}
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setCreateForm(defaultAddNewCompanyAdminForm);
		props.onClose();
	}

	/**
	 * onChange event for the partner drop-down.
	 *
	 * @param v
	 */
	function handleSelectCompany(v: IReactSelectOption): void {
		setCreateForm({
			...createForm,
			companyID: v !== null ? v.value : undefined,
		});
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof CreateCompanyAdminBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateForm({
				...createForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setCreateForm({
				...createForm,
				phoneNumberBody: {
					...createForm.phoneNumberBody,
					[key]: dialCodePart,
				}
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setCreateForm({
				...createForm,
				phoneNumberBody: {
					...createForm.phoneNumberBody,
					[key]: values.value,
				}
			});
		}
	}

	/**
	 * Call api to create new Company Admin, reset form & close modal on success.
	 *
	 */
	async function submitAddNewCompanyAdmin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).createCompanyAdmin({
				createCompanyAdminBody: {
					companyID: createForm?.companyID,
					email: createForm?.email || undefined,
					phoneNumberBody: (createForm?.phoneNumberBody?.countryCode || createForm?.phoneNumberBody?.nationalNumber) ? {
						countryCode: createForm?.phoneNumberBody?.countryCode,
						nationalNumber: createForm?.phoneNumberBody?.nationalNumber || undefined,
					} : undefined,
					firstName: createForm?.firstName || undefined,
					lastName: createForm?.lastName || undefined,
				}
			});

			setCreateForm(defaultAddNewCompanyAdminForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add Company Admin"
			/>

			<form onSubmit={submitAddNewCompanyAdmin}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
                        className="mb-3"
                    >
						Company Admin Details
					</LabelWithIcon>

                    <div className="mb-3">
                        <FrameOneReactSelect
                            name="partners"
                            placeholder="Search Companies..."
                            isClearable={true}
                            isLoading={companies === undefined}
                            value={reverseLookUpReactSelectValue(companies, createForm.companyID, "name")}
                            onChange={handleSelectCompany}
                            options={makeReactSelectOptions(companies, "name")}
                        />
                    </div>

                    <div className="mb-3">
                        <label>
                            Email*
                        </label>
                        <Input
                            placeholder="Email..."
                            value={createForm.email}
                            onChange={dynamicOnChange("email")}
                            type="email"
                        />
                    </div>

                    <div className="mb-3">
                        <label>
                            First Name*
                        </label>
                        <Input
                            placeholder="First Name..."
                            value={createForm.firstName}
                            onChange={dynamicOnChange("firstName")}
                        />
                    </div>

                    <div className="mb-3">
                        <label>
                            Last Name*
                        </label>
                        <Input
                            placeholder="Last Name..."
                            value={createForm.lastName}
                            onChange={dynamicOnChange("lastName")}
                        />
                    </div>

					<div className="mb-3">
						<label>
							Phone Number
						</label>

						<div className="row gx-1 gx-sm-3">
							<div className="col col-4 col-sm-3">
								<CountryCodeSelector
									value={createForm?.phoneNumberBody?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange("countryCode")}
								/>
							</div>

							<div className="col col-8 col-sm-9">
								<NumberFormat
									placeholder="(000) 000-0000"
									value={createForm?.phoneNumberBody?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
								/>
							</div>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						icon={AiOutlinePlus}
						onClick={submitAddNewCompanyAdmin}
					>
						Save changes
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		currentUser: store.metaStore.currentUser,
		...props,
	}
})(ManageCompanyAdminsAddModal);
