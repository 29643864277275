/**
 * Convert the milliseconds returned on a Bundle's "bookingTime" field to a string like "n days".
 *
 * @param bookingTime
 */
export function convertBundleBookingTimeToDays(bookingTime: number = undefined): string {
	if (bookingTime === undefined) {
		return "-"
	}

	const abs = Math.abs(bookingTime);
	const days = (abs / 1000 / 60 / 60 / 24);

	return days + ` day${days === 1 ? "" : "s"}`;
}
