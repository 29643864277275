"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetNewQuestionBodyToJSON = exports.GetNewQuestionBodyFromJSONTyped = exports.GetNewQuestionBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
function GetNewQuestionBodyFromJSON(json) {
    return GetNewQuestionBodyFromJSONTyped(json, false);
}
exports.GetNewQuestionBodyFromJSON = GetNewQuestionBodyFromJSON;
function GetNewQuestionBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'exclude': !runtime_1.exists(json, 'exclude') ? undefined : json['exclude'],
    };
}
exports.GetNewQuestionBodyFromJSONTyped = GetNewQuestionBodyFromJSONTyped;
function GetNewQuestionBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'exclude': value.exclude,
    };
}
exports.GetNewQuestionBodyToJSON = GetNewQuestionBodyToJSON;
