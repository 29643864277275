import React, {ReactNode, useEffect, useState} from "react";
import {Company, CompanyAdmin, GetCompanyAdminsResponse, Token, UsersApi} from "client";
import getConfig from "../utils/getConfig";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {Input} from "reactstrap";
import {AiOutlinePlus} from "react-icons/all";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import ManageCompanyAdminsActionsCell from "../components/tables/cells/ManageCompanyAdminsActionsCell";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import FrameButton from "../components/buttons/FrameButton";
import formatPhoneNumber from "../utils/formatPhoneNumber";
import {generateUserFullName} from "../utils/generateUserFullName";
import {valueOrBustDynamic} from "../utils/valueOrBust";
import {convertDisabledStatusToString} from "../utils/convertDisabledStatusToString";
import ManageCompanyAdminsAddModal from "../components/modals/ManageCompanyAdminsAddModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageCompanyAdmins: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>("");
	const [firstRender, setFirstRender] = useState(true);
	const [showAddNewCompanyAdminModal, setShowAddNewCompanyAdminModal] = useState<boolean>(false);
	const [companyAdmins, setCompanyAdmins] = useState<GetCompanyAdminsResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readCompanyAdmins().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input.
	 * After timeout completes API is called with search property.
	 *
	 */
	useEffect(() => {
		if (firstRender) {
			return;
		}

		const searchTimeout = setTimeout(() => {
			readCompanyAdmins().then().catch();
		}, 500);

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e.target.value);
	}

	/**
	 * Show or Hide the Create Company Admin Modal.
	 *
	 */
	function toggleAddNewCompanyAdminModal(): void {
		setShowAddNewCompanyAdminModal(!showAddNewCompanyAdminModal);
	}

	/**
	 * Hide the Add New Company Admin Modal when finished,
	 * and call the api to get the updated list.
	 *
	 */
	function onDoneAddNewCompanyAdminModal(): void {
		setShowAddNewCompanyAdminModal(false);
		readCompanyAdmins().then().catch();
	}

	/**
	 * Call API to get all the Company Admins.
	 *
	 */
	async function readCompanyAdmins(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new UsersApi(getConfig(props.fullToken)).getCompanyAdmins({
				search: searchValue,
				...frontendPagination,
			});

			setCompanyAdmins(res);
		} catch (e) {
			props.dispatch(addError(e))
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Action Cells.
	 *
	 */
	function makeActionsCell(value: never, companyAdmin: any): ReactNode {
		return (
			<ManageCompanyAdminsActionsCell
				companyAdmin={companyAdmin}
				onDone={readCompanyAdmins}
			/>
		);
	}

	return (
		<React.Fragment>
			<ManageCompanyAdminsAddModal
				isOpen={showAddNewCompanyAdminModal}
				onClose={toggleAddNewCompanyAdminModal}
				onDone={onDoneAddNewCompanyAdminModal}
			/>

			<div>
                <PageHeader>
                    <h3>
                        Manage Company Admins
                    </h3>

                    <div className="row gy-3">
                        <div className="col col-12 col-md-6">
                            <label>
                                Search
                            </label>
                            <Input
                                placeholder="Search for Company Admins"
                                value={searchValue}
                                onChange={searchOnChange}
                            />
                        </div>

                        <div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
                            <label className="ghost-label d-none d-md-block">Add</label>
                            <FrameButton
                                color="darkPurple"
                                icon={AiOutlinePlus}
                                onClick={toggleAddNewCompanyAdminModal}
                                className="w-100"
                            >
                                Create New Company Admin
                            </FrameButton>
                        </div>
                    </div>
                </PageHeader>
			</div>

			<div className="p-3">
				<FrameOneTableContainer
					data={companyAdmins?.companyAdmins}
					pagination={{
						...companyAdmins?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
                        {
                            key: "",
                            headerValue: "Name",
                            showSortIcons: false,
                            sortable: false,
                            valueFormatter: (f: never, u: CompanyAdmin) => generateUserFullName(u),
                        },
						{
							key: "email",
							headerValue: "Email",
							showSortIcons: false,
							sortable: false,
						},
                        {
                            key: "company",
                            headerValue: "Company",
                            showSortIcons: false,
                            sortable: false,
                            valueFormatter: (c: Company) => c.name,
                        },
                        {
                            key: "phoneNumber",
                            headerValue: "Phone Number",
                            showSortIcons: false,
                            sortable: false,
                            valueFormatter: valueOrBustDynamic(formatPhoneNumber),
                        },
                        {
                            key: "disabled",
                            headerValue: "Status",
                            showSortIcons: false,
                            sortable: false,
                            valueFormatter: convertDisabledStatusToString,
                        },
						{
							key: undefined,
							headerValue: "Actions",
							showSortIcons: false,
							sortable: false,
							cellRender: makeActionsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCompanyAdmins);
