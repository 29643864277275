import React, {useState} from "react";
import FrameButton from "../../buttons/FrameButton";

interface IProps {
	onSelect: () => void;
}

const CreateBundleAddItemEquipmentSelectionCell: React.FC<IProps> = (props) => {

	return (
		<React.Fragment>
			<div className="frame-one-table-cell">
				<FrameButton
					color="darkPurple"
					outline={true}
					onClick={props.onSelect}
				>
					Select
				</FrameButton>
			</div>
		</React.Fragment>
	);
};

export default CreateBundleAddItemEquipmentSelectionCell;
