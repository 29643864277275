import React, {ReactNode} from "react";
import {BundleItem} from "client";
import LabelWithIcon from "../LabelWithIcon";
import {VscSymbolMisc} from "react-icons/all";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import FrameOneCard from "../FrameOneCard";
import ViewBundleInventorySelectionViewInventoryCell
	from "../tables/cells/ViewBundleInventorySelectionViewInventoryCell";
import ManageBundlesItemModalRequiredCell from "../tables/cells/ManageBundlesItemModalRequiredCell";

interface IProps {
	items: Array<BundleItem>;
}

const ViewBundleInventorySelection: React.FC<IProps> = (props) => {

	/**
	 * Renderer for the Required Cells.
	 *
	 * @param value
	 * @param bundleItem
	 */
	function makeRequiredCell(value: never, bundleItem: BundleItem): ReactNode {
		return (
			<ManageBundlesItemModalRequiredCell bundleItem={bundleItem}/>
		);
	}

	/**
	 * Renderer for the Inventory cell.
	 *
	 * @param value
	 * @param bundleItem
	 */
	function makeInventoryCell(value: never, bundleItem: BundleItem): ReactNode {
		return (
			<ViewBundleInventorySelectionViewInventoryCell bundleItem={bundleItem}/>
		);
	}

	return (
		<React.Fragment>
			<FrameOneCard>
				<LabelWithIcon icon={VscSymbolMisc}>
					Items
				</LabelWithIcon>

				<hr/>

				{(props.items && props.items.length > 0) ? (
					<div className="narrow-frame-one-table-container mb-4">
						<FrameOneTableContainer
							data={props.items}
							columnOptions={[
								{
									key: "name",
									headerValue: "Name",
									showSortIcons: false,
									sortable: false,
								},
								{
									key: "",
									headerValue: "Required",
									showSortIcons: false,
									sortable: false,
									cellRender: makeRequiredCell,
									headerCellClassName: "justify-content-center",
									rowCellClassName: "justify-content-center",
								},
								{
									key: "quantity",
									headerValue: "Quantity",
									showSortIcons: false,
									sortable: false,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
								{
									key: "equipmentOptions",
									headerValue: "Inventory",
									showSortIcons: false,
									sortable: false,
									cellRender: makeInventoryCell,
									headerCellClassName: "justify-content-end",
									rowCellClassName: "justify-content-end",
								},
							]}
						/>
					</div>
				) : (
					<div className="my-5">
						<p className="empty-message">
							No Items Added.
						</p>
					</div>
				)}
			</FrameOneCard>
		</React.Fragment>
	);
};

export default ViewBundleInventorySelection;
