import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	active: boolean;
	onClick?: () => void;
}

const FrameOnePaginatorButton: React.FC<IProps> = (props) => {

	function onClickHelper(): void {
		if (props.onClick) {
			props.onClick();
		}
	}

	return (
		<button
			disabled={props.onClick === undefined || props.active}
			onClick={onClickHelper}
			className={classNames("frame-one-paginator_buttons-container_buttons_button", {
				"frame-one-paginator_buttons-container_buttons_button_clickable": props.onClick !== undefined,
				"frame-one-paginator_buttons-container_buttons_button_active": props.active,
			})}
		>
			{props.children}
		</button>
	);
};

export default FrameOnePaginatorButton;
