import React, {ChangeEventHandler} from "react";
import FrameOneCard from "../FrameOneCard";
import LabelWithIcon from "../LabelWithIcon";
import {FiInfo} from "react-icons/all";
import {Input, Label} from "reactstrap";
import {Question} from "client";

interface IProps {
	questionString: string;
	onChange: (key: keyof Question) => ChangeEventHandler<HTMLInputElement>;
}

const CreateQuestionDetails: React.FC<IProps> = (props) => {

	return (
		<FrameOneCard>
			<LabelWithIcon icon={FiInfo}>
				Question Details
			</LabelWithIcon>

			<hr/>

			<div className="row g-3">
				<div className="col col-12 col-md-6">
					<Label>
						Question
					</Label>
					<Input
						type="textarea"
						placeholder="Enter Question..."
						value={props.questionString}
						onChange={props.onChange("question")}
					/>
				</div>
			</div>
		</FrameOneCard>
	);
};

export default CreateQuestionDetails;
