import React, {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {AssetsApi, Characteristic, CharacteristicBody, CharacteristicsApi, Partner, Token} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import FrameModal from "./modalComponents/FrameModal";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {addURLsToFiles, IFrontendFileType} from "../../utils/renderAssetsHelper";
import {isValidNumber} from "../../utils/isValidNumber";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import LabelWithIcon from "../LabelWithIcon";
import {AiOutlineInfoCircle} from "react-icons/all";
import {Input, Label} from "reactstrap";
import FrameButton from "../buttons/FrameButton";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import CompanyPhoto from "../CompanyPhoto";
import FileInputButton from "../inputs/FileInputButton";

interface IUpdateCharacteristicBodyFrontend extends Omit<CharacteristicBody, "image"> {
	image: IFrontendFileType;
}

const defaultUpdateCharacteristicBodyFrontend: IUpdateCharacteristicBodyFrontend = {
	name: "",
	description: "",
	minimumPointValue: undefined,
	maximumPointValue: undefined,
	image: undefined,
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	characteristic: Characteristic;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCharacteristicsEditModal: React.FC<IProps> = (props) => {

	const [editForm, setEditForm] = useState(defaultUpdateCharacteristicBodyFrontend);

	useEffect(() => {
		resetForm(props.characteristic);
	}, [JSON.stringify(props.characteristic)]);

	/**
	 * Reset the edit form to its default for this Characteristic.
	 *
	 * @param _characteristic
	 */
	function resetForm(_characteristic: Characteristic): void {
		setEditForm({
			name: _characteristic?.name,
			description: _characteristic?.description,
			minimumPointValue: _characteristic?.minimumPointValue,
			maximumPointValue: _characteristic?.maximumPointValue,
			image: _characteristic?.image,
		});
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		resetForm(props.characteristic);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IUpdateCharacteristicBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setEditForm({
				...editForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof IUpdateCharacteristicBodyFrontend): (values: NumberFormatValues) => void {
		return (values) => {
			setEditForm({
				...editForm,
				[key]: values.value,
			});
		}
	}

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setEditForm({
			...editForm,
			image: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Create the request object, submit the api, and call the props function to close the modal & get new data.
	 *
	 */
	async function submitUpdatedCharacteristic(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const updateRequest: Partial<CharacteristicBody> = {
				name: editForm?.name || undefined,
				description: editForm?.description || undefined,
				minimumPointValue: isValidNumber(editForm?.minimumPointValue) ? Number(editForm.minimumPointValue) : undefined,
				maximumPointValue: isValidNumber(editForm?.maximumPointValue) ? Number(editForm.maximumPointValue) : undefined,
			};

			// Check if the image in our edit form is the frontend type of file, in which case add it to the form
			// (Can't pass the Asset back in to the form if it existed when starting the edit).
			// Otherwise, check if the existing partner has an image associated with them and use that image's _id for the imageID field
			if (isFileWithSRC(editForm.image)) {
				updateRequest.image = (await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: editForm?.image,
				}))._id;
			} else if (props.characteristic?.image) {
				updateRequest.image = props.characteristic?.image?._id;
			}

			await new CharacteristicsApi(getConfig(props.fullToken)).updateCharacteristic({
				id: props.characteristic?._id,
				characteristicBody: updateRequest as CharacteristicBody,
			});

			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={resetAndClose}
		>
			<FrameModalHeader
				title="Edit Characteristic"
				toggle={resetAndClose}
			/>

			<form onSubmit={submitUpdatedCharacteristic}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Characteristic Details
					</LabelWithIcon>

					<div className="mb-3">
						<label>
							Name
						</label>
						<Input
							placeholder="Name..."
							value={editForm.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Description
						</Label>
						<Input
							placeholder="Description..."
							value={editForm.description}
							onChange={dynamicOnChange("description")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Minimum Point Value
						</Label>
						<NumberFormat
							placeholder="Minimum Points..."
							value={editForm.minimumPointValue}
							customInput={Input}
							allowNegative={false}
							decimalScale={0}
							onValueChange={numberFormatOnChange("minimumPointValue")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Maximum Point Value (Optional)
						</Label>
						<NumberFormat
							placeholder="Maximum Points..."
							value={editForm.maximumPointValue}
							customInput={Input}
							allowNegative={false}
							decimalScale={0}
							onValueChange={numberFormatOnChange("maximumPointValue")}
						/>
					</div>

					<div>
						<Label>
							Characteristic Image
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={editForm.image && (isFileWithSRC(editForm.image) ? editForm.image.imageSRC as string : editForm.image?.url)}
								alt="Characteristic image"
								className="w-50 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onFileChange}
							>
								<FrameButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</FrameButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitUpdatedCharacteristic}
					>
						Update Characteristic
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCharacteristicsEditModal);
