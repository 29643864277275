import React, {ChangeEvent, ChangeEventHandler, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import {Asset, AssetsApi, CompaniesApi, CompanyBody, CreateCompanyRequest, PhoneNumberBody, Token} from "client";
import {Input, Label} from "reactstrap";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import GooglePlacesInput from "../inputs/GooglePlacesInput";
import CompanyPhoto from "../CompanyPhoto";
import {addURLsToFiles, FileWithSRC} from "../../utils/renderAssetsHelper";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {AiOutlineInfoCircle} from "react-icons/all";
import LabelWithIcon from "../LabelWithIcon";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import {isFileWithSRC} from "../../utils/fileTypeChecks";
import FileInputButton from "../inputs/FileInputButton";

interface ICreateCompanyRequestFrontend extends Omit<CompanyBody, "image"> {
	image: FileWithSRC;
}

const defaultAddNewCompanyForm: ICreateCompanyRequestFrontend = {
	name: "",
	phoneNumber: {
		countryCode: undefined,
		nationalNumber: "",
	},
	placeID: "",
	image: undefined,
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManageCompaniesAddModal: React.FC<IProps> = (props: IProps) => {

	const [form, setForm] = useState<ICreateCompanyRequestFrontend>(defaultAddNewCompanyForm);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setForm(defaultAddNewCompanyForm);
		props.onClose();
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof ICreateCompanyRequestFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setForm({
				...form,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setForm({
				...form,
				phoneNumber: {
					...form.phoneNumber,
					[key]: dialCodePart,
				},
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setForm({
				...form,
				phoneNumber: {
					...form.phoneNumber,
					[key]: values.value,
				},
			});
		}
	}

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setForm({
			...form,
			image: (await addURLsToFiles(e?.target.files))[0],
		})
	}

	/**
	 * Custom onChange for the places autocomplete because of how we return the data from that component.
	 *
	 * @param placeID
	 */
	function setPlaceID(placeID: string): void {
		setForm({
			...form,
			placeID: placeID,
		});
	}

	/**
	 * Call api to create new company, reset form & close modal on success.
	 *
	 */
	async function submitNewCompany(e): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			let imageRes: Asset;
			if (form?.image) {
				imageRes = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: form?.image,
				});
			}

			await new CompaniesApi(getConfig(props.fullToken)).createCompany({
				companyBody: {
					name: form?.name || undefined,
					phoneNumber: (form?.phoneNumber?.countryCode || form?.phoneNumber?.nationalNumber) ? {
						countryCode: form?.phoneNumber?.countryCode,
						nationalNumber: form?.phoneNumber?.nationalNumber || undefined,
					} : undefined,
					placeID: form?.placeID || undefined,
					image: imageRes?._id,
				},
			});

			setForm(defaultAddNewCompanyForm);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				toggle={closeHelper}
				title="Add New Company"
			/>

			<form onSubmit={submitNewCompany}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Company Details
					</LabelWithIcon>

					<div className="mb-3">
						<Label>
							Name*
						</Label>
						<Input
							placeholder="Name..."
							value={form.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Phone Number
						</label>

						<div className="row gx-1 gx-sm-3">
							<div className="col col-4 col-sm-3">
								<CountryCodeSelector
									value={form.phoneNumber?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange("countryCode")}
								/>
							</div>

							<div className="col col-8 col-sm-9">
								<NumberFormat
									placeholder="(000) 000-0000"
									value={form.phoneNumber?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
								/>
							</div>
						</div>
					</div>

					<div className="mb-3">
						<Label>
							Location
						</Label>
						<GooglePlacesInput
							initialInputValue={form?.placeID}
							setPlaceID={setPlaceID}
						/>
					</div>

					<div>
						<Label>
							Company Image
						</Label>
						<div className="d-flex flex-column align-items-center">
							<CompanyPhoto
								src={form.image && isFileWithSRC(form.image) && form.image.imageSRC as string}
								alt="Company image"
								className="w-50 mb-3"
							/>

							<FileInputButton
								accept=".png,.jpeg,.gif"
								multiple={false}
								onChange={onFileChange}
							>
								<FrameButton
									color="darkPurple"
									outline={true}
								>
									Upload Image
								</FrameButton>
							</FileInputButton>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						color="darkPurple"
						onClick={submitNewCompany}
						type="submit"
					>
						Add Company
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageCompaniesAddModal);
