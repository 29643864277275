import React, {ChangeEventHandler, useEffect, useState} from "react";
import {CreatePartnerAdminBody, Partner, PartnersApi, PhoneNumberBody, Token, UsersApi} from "client";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import getConfig from "../../utils/getConfig";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {AiOutlineInfoCircle} from "react-icons/all";
import LabelWithIcon from "../LabelWithIcon";
import {Input} from "reactstrap";
import CountryCodeSelector from "../inputs/CountryCodeSelector";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";
import {
	IReactSelectOption,
	makeReactSelectOptions,
	reverseLookUpReactSelectValue
} from "../../utils/reactSelectHelpers";

const defaultCreatePartnerAdminBody: CreatePartnerAdminBody = {
	partnerID: undefined,
	email: "",
	phoneNumberBody: {
		countryCode: undefined,
		nationalNumber: ""
	},
	firstName: "",
	lastName: "",
}

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	isOpen: boolean;
	onClose: () => void;
	onDone: () => void;
}

const ManagePartnerAdminsAddModal: React.FC<IProps> = (props) => {

	const [partners, setPartners] = useState<Array<Partner>>(undefined);
	const [createForm, setCreateForm] = useState<CreatePartnerAdminBody>(defaultCreatePartnerAdminBody);

	useEffect(() => {
		getPartners().then().catch();
	}, []);

	/**
	 * Get the list of Partners for the dropdown.
	 *
	 */
	async function getPartners(): Promise<void> {
		try {
			const res = await new PartnersApi(getConfig(props.fullToken)).getPartners({
				offset: 0,
				limit: 100000,
			});

			setPartners(res.partners);
		} catch (e) {

		}
	}

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function closeHelper(): void {
		setCreateForm(defaultCreatePartnerAdminBody);
		props.onClose();
	}

	/**
	 * onChange event for the partner drop-down.
	 *
	 * @param v
	 */
	function handleSelectPartner(v: IReactSelectOption): void {
		setCreateForm({
			...createForm,
			partnerID: v !== null ? v.value : undefined,
		});
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof CreatePartnerAdminBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setCreateForm({
				...createForm,
				[key]: e.target.value,
			})
		}
	}

	/**
	 * onChange handler for our country code dropdown input.
	 *
	 * @param key
	 */
	function onCountryCodeChange(key: keyof PhoneNumberBody): (dialCodePart: string) => void {
		return (dialCodePart) => {
			setCreateForm({
				...createForm,
				phoneNumberBody: {
					...createForm.phoneNumberBody,
					[key]: dialCodePart,
				}
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof PhoneNumberBody): (values: NumberFormatValues) => void {
		return (values) => {
			setCreateForm({
				...createForm,
				phoneNumberBody: {
					...createForm.phoneNumberBody,
					[key]: values.value,
				}
			});
		}
	}

	async function submitNewPartnerAdmin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			await new UsersApi(getConfig(props.fullToken)).createPartnerAdmin({
				createPartnerAdminBody: {
					partnerID: createForm?.partnerID,
					email: createForm?.email || undefined,
					phoneNumberBody: (createForm?.phoneNumberBody?.countryCode || createForm?.phoneNumberBody?.nationalNumber) ? {
						countryCode: createForm?.phoneNumberBody?.countryCode,
						nationalNumber: createForm?.phoneNumberBody?.nationalNumber || undefined,
					} : undefined,
					firstName: createForm?.firstName || undefined,
					lastName: createForm?.lastName || undefined,
				},
			});

			setCreateForm(defaultCreatePartnerAdminBody);
			props.onDone();
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={closeHelper}
		>
			<FrameModalHeader
				title="Add Partner Admin"
				toggle={closeHelper}
			/>

			<form onSubmit={submitNewPartnerAdmin}>
				<FrameModalBody>
					<LabelWithIcon
						icon={AiOutlineInfoCircle}
						className="mb-3"
					>
						Partner Admin Details
					</LabelWithIcon>

					<div className="mb-3">
						<FrameOneReactSelect
							name="partners"
							placeholder="Search Partners..."
							isClearable={true}
							isLoading={partners === undefined}
							value={reverseLookUpReactSelectValue(partners, createForm.partnerID, "name")}
							onChange={handleSelectPartner}
							options={makeReactSelectOptions(partners, "name")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Email*
						</label>
						<Input
							placeholder="Email..."
							value={createForm.email}
							onChange={dynamicOnChange("email")}
							type="email"
						/>
					</div>

					<div className="mb-3">
						<label>
							First Name*
						</label>
						<Input
							placeholder="First Name..."
							value={createForm.firstName}
							onChange={dynamicOnChange("firstName")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Last Name*
						</label>
						<Input
							placeholder="Last Name..."
							value={createForm.lastName}
							onChange={dynamicOnChange("lastName")}
						/>
					</div>

					<div className="mb-3">
						<label>
							Phone Number
						</label>

						<div className="row gx-1 gx-sm-3">
							<div className="col col-4 col-sm-3">
								<CountryCodeSelector
									value={createForm?.phoneNumberBody?.countryCode}
									valueKey="code"
									onChange={onCountryCodeChange("countryCode")}
								/>
							</div>

							<div className="col col-8 col-sm-9">
								<NumberFormat
									placeholder="(000) 000-0000"
									value={createForm?.phoneNumberBody?.nationalNumber}
									customInput={Input}
									allowNegative={false}
									decimalScale={0}
									onValueChange={numberFormatOnChange("nationalNumber")}
								/>
							</div>
						</div>
					</div>
				</FrameModalBody>

				<FrameModalFooter>
					<FrameButton
						type="submit"
						color="darkPurple"
						onClick={submitNewPartnerAdmin}
					>
						Add Partner Admin
					</FrameButton>
				</FrameModalFooter>
			</form>
		</FrameModal>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManagePartnerAdminsAddModal);
