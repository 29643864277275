import React, {ReactNode} from "react";
import {BundleItem, Equipment, Partner} from "client";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import CreateBundleAddItemEquipmentImageCell from "../tables/cells/CreateBundleAddItemEquipmentImageCell";
import {TableData, TableDataEntryArray, TableDataEntryJSON} from "frame-one-table/build/contextTypes";
import {ColumnOption} from "frame-one-table/build/TableGenerator";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	bundleItem: BundleItem;
}

const ViewBundleInventorySelectionEquipmentOptionsModal: React.FC<IProps> = (props) => {

	/**
	 * Renderer for the Equipment Image Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function makeImageCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemEquipmentImageCell equipment={equipment}/>
		);
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title={`Viewing Equipment Options for ${props.bundleItem?.name}`}
				toggle={props.onClose}
			/>

			<FrameModalBody>
				{(props.bundleItem?.equipmentOptions && props.bundleItem?.equipmentOptions?.length > 0) ? (
					<div className="narrow-frame-one-table-container mb-4">
						<FrameOneTableContainer
							data={props.bundleItem.equipmentOptions}
							columnOptions={[
								{
									key: "",
									headerValue: "Picture",
									showSortIcons: false,
									sortable: false,
									cellRender: makeImageCell,
									headerCellClassName: "justify-content-center",
									rowCellClassName: "justify-content-center",
								},
								{
									key: "name",
									headerValue: "Equipment",
									showSortIcons: false,
									sortable: false,
									rowCellClassName: "w-50",
								},
								{
									key: "partner",
									headerValue: "Partner",
									showSortIcons: false,
									sortable: false,
									valueFormatter: (p: Partner) => p?.name,
									rowCellClassName: "w-50",
								},
								{
									key: "priority",
									headerValue: "Priority",
									showSortIcons: false,
									sortable: false,
									valueFormatter: (value: any, row: TableDataEntryArray | TableDataEntryJSON, key: string | number, data: TableData, column: ColumnOption, index?: number) => index + 1, // add 1, as we save priorities as array indexes; don't want to display "0" in the UI.
									headerCellClassName: "justify-content-end",
									rowCellClassName: "text-end",
								},
							]}
						/>
					</div>
				) : (
					<div className="my-5">
						<p className="empty-message">
							No Equipment Added.
						</p>
					</div>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default ViewBundleInventorySelectionEquipmentOptionsModal;
