"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestPasswordResetResponseToJSON = exports.RequestPasswordResetResponseFromJSONTyped = exports.RequestPasswordResetResponseFromJSON = void 0;
function RequestPasswordResetResponseFromJSON(json) {
    return RequestPasswordResetResponseFromJSONTyped(json, false);
}
exports.RequestPasswordResetResponseFromJSON = RequestPasswordResetResponseFromJSON;
function RequestPasswordResetResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'passwordResetRequestID': json['passwordResetRequestID'],
    };
}
exports.RequestPasswordResetResponseFromJSONTyped = RequestPasswordResetResponseFromJSONTyped;
function RequestPasswordResetResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'passwordResetRequestID': value.passwordResetRequestID,
    };
}
exports.RequestPasswordResetResponseToJSON = RequestPasswordResetResponseToJSON;
