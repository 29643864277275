"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InlineObjectToJSON = exports.InlineObjectFromJSONTyped = exports.InlineObjectFromJSON = void 0;
const runtime_1 = require("../runtime");
function InlineObjectFromJSON(json) {
    return InlineObjectFromJSONTyped(json, false);
}
exports.InlineObjectFromJSON = InlineObjectFromJSON;
function InlineObjectFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'asset': !runtime_1.exists(json, 'asset') ? undefined : json['asset'],
        'assetID': json['assetID'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'contentType': !runtime_1.exists(json, 'contentType') ? undefined : json['contentType'],
    };
}
exports.InlineObjectFromJSONTyped = InlineObjectFromJSONTyped;
function InlineObjectToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'asset': value.asset,
        'assetID': value.assetID,
        'name': value.name,
        'description': value.description,
        'contentType': value.contentType,
    };
}
exports.InlineObjectToJSON = InlineObjectToJSON;
