import React from "react";
import {Bundle} from "client";
import {FiCheck, FiX} from "react-icons/all";

interface IProps {
	bundle: Bundle;
}

const ManageBundlesActiveCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			{props.bundle.active ? (
				<FiCheck className="text-green"/>
			) : (
				<FiX className="text-red"/>
			)}
		</div>
	);
};

export default ManageBundlesActiveCell;
