import React from "react";
import {ColumnOption, IPaginatorProps, TableGenerator, TableGeneratorProps} from "frame-one-table";
import FrameOneTableHeaderCell from "./FrameOneTableHeaderCell";
import {valueOrBust} from "../../utils/valueOrBust";
import {PaginationInfo} from "client";
import FrameOnePaginator from "./FrameOnePaginator";

interface IProps extends Partial<TableGeneratorProps> {
	pagination?: PaginationInfo;
	onPaginationChange?: (newPagination: PaginationInfo) => void;
}

const FrameOneTableContainer: React.FC<IProps> = (props) => {

	return (
		<div>
			<div className="frame-one-table-container">
				<TableGenerator
					paginatorProps={{show: false} as IPaginatorProps}
					showSortIcons={false}
					showHeader={true}
					showBody={true}
					{...props}
					columnOptions={props.columnOptions?.map((column): ColumnOption => {
						return {
							...column,
							headerRender: FrameOneTableHeaderCell,
							valueFormatter: column?.valueFormatter || (!column.cellRender && valueOrBust),
						}
					})}
				/>
			</div>

			{props.pagination?.total !== undefined && props.onPaginationChange && (
				<FrameOnePaginator
					{...props.pagination}
					onPaginationChange={props.onPaginationChange}
				/>
			)}
		</div>
	);
};

export default FrameOneTableContainer;
