"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateEquipmentBodyToJSON = exports.CreateEquipmentBodyFromJSONTyped = exports.CreateEquipmentBodyFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CreateEquipmentBodyFromJSON(json) {
    return CreateEquipmentBodyFromJSONTyped(json, false);
}
exports.CreateEquipmentBodyFromJSON = CreateEquipmentBodyFromJSON;
function CreateEquipmentBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'identifier': !runtime_1.exists(json, 'identifier') ? undefined : json['identifier'],
        'isConsumable': json['isConsumable'],
        'quantity': json['quantity'],
        'availability': (json['availability'].map(_1.TimeIntervalFromJSON)),
        'name': json['name'],
        'description': json['description'],
        'instructions': !runtime_1.exists(json, 'instructions') ? undefined : json['instructions'],
        'placeID': !runtime_1.exists(json, 'placeID') ? undefined : json['placeID'],
        'thumbnailID': !runtime_1.exists(json, 'thumbnailID') ? undefined : json['thumbnailID'],
        'imageIDs': !runtime_1.exists(json, 'imageIDs') ? undefined : json['imageIDs'],
        'attributes': json['attributes'],
    };
}
exports.CreateEquipmentBodyFromJSONTyped = CreateEquipmentBodyFromJSONTyped;
function CreateEquipmentBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'identifier': value.identifier,
        'isConsumable': value.isConsumable,
        'quantity': value.quantity,
        'availability': (value.availability.map(_1.TimeIntervalToJSON)),
        'name': value.name,
        'description': value.description,
        'instructions': value.instructions,
        'placeID': value.placeID,
        'thumbnailID': value.thumbnailID,
        'imageIDs': value.imageIDs,
        'attributes': value.attributes,
    };
}
exports.CreateEquipmentBodyToJSON = CreateEquipmentBodyToJSON;
