import React, {ChangeEventHandler, ReactNode, useEffect, useState} from "react";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import {IBundleItemBodyFrontend} from "../../pages/CreateBundle";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {Input, Label} from "reactstrap";
import CreateBundleAddItemBrowseEquipmentModal from "./CreateBundleAddItemBrowseEquipmentModal";
import {cloneDeep, findIndex} from "lodash";
import FrameOneButtonActions from "../FrameOneButtonActions";
import {FiPlus, FiTrash} from "react-icons/all";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import {Characteristic, Equipment, Partner} from "client";
import CreateBundleAddItemEquipmentImageCell from "../tables/cells/CreateBundleAddItemEquipmentImageCell";
import FrameOneCheckbox from "../FrameOneCheckbox";
import CreateBundleAddItemEquipmentPriorityCell from "../tables/cells/CreateBundleAddItemEquipmentPriorityCell";
import {ColumnOption} from "frame-one-table/build/TableGenerator";
import {TableData} from "frame-one-table/build/contextTypes";

const defaultBundleItemBodyFrontend: IBundleItemBodyFrontend = {
	required: true,
	name: "",
	quantity: undefined,
	equipmentOptions: [],
}

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	onDone: (item: IBundleItemBodyFrontend) => void;
}

const CreateBundleAddItemModal: React.FC<IProps> = (props) => {

	const [showAddEquipmentModal, setShowAddEquipmentModal] = useState(false);
	const [itemForm, setItemForm] = useState<IBundleItemBodyFrontend>(defaultBundleItemBodyFrontend);

	useEffect(() => {
		if (props.isOpen) {
			setItemForm(defaultBundleItemBodyFrontend);
		}
	}, [props.isOpen]);

	/**
	 * Reset the form & close the modal.
	 *
	 */
	function resetAndClose(): void {
		setItemForm(defaultBundleItemBodyFrontend);
		props.onClose();
	}

	/**
	 * Hide or show the Equipment Selection Modal.
	 *
	 */
	function toggleShowAddEquipmentModal(): void {
		setShowAddEquipmentModal(!showAddEquipmentModal);
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IBundleItemBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setItemForm({
				...itemForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof IBundleItemBodyFrontend): (values: NumberFormatValues) => void {
		return (values) => {
			setItemForm({
				...itemForm,
				[key]: values.value,
			});
		}
	}

	/**
	 * onChange handler for toggling the checkbox value(s).
	 *
	 * @param key
	 */
	function checkBoxOnChange(key: keyof IBundleItemBodyFrontend): () => void {
		return () => {
			setItemForm({
				...itemForm,
				[key]: !itemForm[key],
			});
		}
	}

	/**
	 * Handle selecting/adding a new "Equipment with Priority".
	 * Checks if the selected equipment has already been used, in which case nothing happens.
	 *
	 * @param equipment
	 */
	function onAddSelectedEquipmentWithPriority(equipment: Equipment): void {
		const selectedEquipmentCopy: Array<Equipment> = cloneDeep(itemForm.equipmentOptions);
		const existingIndex: number = findIndex(selectedEquipmentCopy, ["_id", equipment?._id]);

		if (existingIndex > -1) {
			setShowAddEquipmentModal(false);
			return;
		} else {
			selectedEquipmentCopy.push(equipment);
		}

		setShowAddEquipmentModal(false);
		setItemForm({
			...itemForm,
			equipmentOptions: selectedEquipmentCopy,
		});
	}

	/**
	 * Pass the completed form to the parent component
	 *
	 * @param e
	 */
	function saveNewItemToBundle(e?): void {
		e?.preventDefault();
		props.onDone(itemForm);
	}

	/**
	 * Run some validation on the state of the form to determine if the save button should be disabled.
	 *
	 * @param _form
	 */
	function isSaveButtonDisabled(_form: IBundleItemBodyFrontend): boolean {
		if (
			!_form.name ||
			_form.name === "" ||
			(_form.quantity?.toString()) === "" ||
			_form.quantity === undefined ||
			_form.quantity === 0 ||
			isNaN(_form.quantity) ||
			typeof Number(_form.quantity) !== "number" ||
			!_form.equipmentOptions ||
			_form.equipmentOptions.length === 0
		) {
			return true;
		}

		return false;
	}

	/**
	 * Renderer for the Equipment Image Cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function makeImageCell(value: never, equipment: Equipment): ReactNode {
		return (
			<CreateBundleAddItemEquipmentImageCell equipment={equipment}/>
		);
	}

	/**
	 * Renderer for the Equipment Priority Cells, that handles swapping priorities (indexes).
	 *
	 * @param value
	 * @param equipment
	 * @param key
	 * @param data
	 * @param column
	 * @param index
	 */
	function renderPriorityCell(value: never, equipment: Equipment, key: string | number, data: TableData, column: ColumnOption, index?: number): ReactNode {
		function onChangePriorityHelper(up: boolean): () => void {
			return () => {
				if (
					(index === 0 && up) ||
					(index === itemForm.equipmentOptions?.length - 1 && !up)
				) {
					return
				}

				const equipmentOptionsClone: Array<Equipment> = cloneDeep(itemForm.equipmentOptions);
				const swappingIndex: number = up ? index - 1 : index + 1;

				const temp = equipmentOptionsClone[swappingIndex];
				equipmentOptionsClone[swappingIndex] = equipmentOptionsClone[index];
				equipmentOptionsClone[index] = temp;

				setItemForm({
					...itemForm,
					equipmentOptions: equipmentOptionsClone,
				});
			}
		}

		return (
			<CreateBundleAddItemEquipmentPriorityCell
				equipment={equipment}
				priority={index}
				onUp={onChangePriorityHelper(true)}
				onDown={onChangePriorityHelper(false)}
			/>
		);
	}

	/**
	 * Renderer for the remove button cells.
	 *
	 * @param value
	 * @param equipment
	 */
	function makeRemoveCell(value: never, equipment: Equipment): ReactNode {
		function onDeleteHelper(): void {
			const equipmentCopy: Array<Equipment> = cloneDeep(itemForm.equipmentOptions);
			equipmentCopy.splice(findIndex(equipmentCopy, ["_id", equipment?._id]), 1);

			setItemForm({
				...itemForm,
				equipmentOptions: equipmentCopy,
			});
		}

		return (
			<div className="frame-one-table-cell">
				<FrameButton
					className="frame-one-button-small"
					color="red"
					icon={FiTrash}
					onClick={onDeleteHelper}
				/>
			</div>
		);
	}

	return (
		<React.Fragment>
			<CreateBundleAddItemBrowseEquipmentModal
				isOpen={showAddEquipmentModal}
				onClose={toggleShowAddEquipmentModal}
				onAdd={onAddSelectedEquipmentWithPriority}
				equipmentOptions={itemForm?.equipmentOptions}
			/>

			<FrameModal
				isOpen={props.isOpen}
				toggle={resetAndClose}
				size="lg"
			>
				<FrameModalHeader
					title="Add Item"
					toggle={resetAndClose}
				/>

				<FrameModalBody>
					<div className="mb-3">
						<Label>
							Name
						</Label>
						<Input
							placeholder="Item Name..."
							value={itemForm?.name}
							onChange={dynamicOnChange("name")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Quantity
						</Label>
						<NumberFormat
							placeholder="Required Quantity..."
							value={itemForm?.quantity}
							customInput={Input}
							allowNegative={false}
							decimalScale={0}
							onValueChange={numberFormatOnChange("quantity")}
						/>
					</div>

					<div className="mb-3">
						<Label>
							Required?
						</Label>
						<FrameOneCheckbox
							onToggle={checkBoxOnChange("required")}
							checked={itemForm.required}
						>
							Item is required for the bundle
						</FrameOneCheckbox>
					</div>

					<Label>
						Equipment Options
					</Label>
					{(itemForm.equipmentOptions && itemForm.equipmentOptions.length > 0) ? (
						<div className="narrow-frame-one-table-container mb-4">
							<FrameOneTableContainer
								data={itemForm.equipmentOptions}
								columnOptions={[
									{
										key: "",
										headerValue: "Picture",
										showSortIcons: false,
										sortable: false,
										cellRender: makeImageCell,
										headerCellClassName: "justify-content-center",
										rowCellClassName: "justify-content-center",
									},
									{
										key: "name",
										headerValue: "Equipment",
										showSortIcons: false,
										sortable: false,
										rowCellClassName: "w-50",
									},
									{
										key: "partner",
										headerValue: "Partner",
										showSortIcons: false,
										sortable: false,
										valueFormatter: (p: Partner) => p?.name,
										rowCellClassName: "w-50",
									},
									{
										key: "priority",
										headerValue: "Priority",
										showSortIcons: false,
										sortable: false,
										cellRender: renderPriorityCell,
										headerCellClassName: "justify-content-end",
										rowCellClassName: "text-end",
									},
									{
										key: undefined,
										headerValue: "",
										showSortIcons: false,
										sortable: false,
										cellRender: makeRemoveCell,
										headerCellClassName: "justify-content-end",
										rowCellClassName: "justify-content-end",
									},
								]}
							/>
						</div>
					) : (
						<div className="mt-3 mb-5">
							<p className="empty-message">
								No Equipment Selected.
							</p>
						</div>
					)}

					<FrameOneButtonActions>
						<FrameButton
							color="green"
							onClick={toggleShowAddEquipmentModal}
							icon={FiPlus}
						>
							Add
						</FrameButton>
					</FrameOneButtonActions>
				</FrameModalBody>

				<hr/>

				<FrameModalFooter>
					<FrameButton
						color={isSaveButtonDisabled(itemForm) ? "mediumGray" : "darkBlue"}
						outline={isSaveButtonDisabled(itemForm)}
						onClick={saveNewItemToBundle}
						disabled={isSaveButtonDisabled(itemForm)}
					>
						Save
					</FrameButton>
				</FrameModalFooter>
			</FrameModal>
		</React.Fragment>
	);
};

export default CreateBundleAddItemModal;
