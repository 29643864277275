import React, {ChangeEventHandler} from "react";
import {Characteristic, CreateAnswerOptionBody} from "client";
import {Input, Label} from "reactstrap";
import NumberFormat, {NumberFormatValues} from "react-number-format";
import {
	IReactSelectOption,
	makeReactSelectOptions,
	reverseLookUpReactSelectValue
} from "../../utils/reactSelectHelpers";
import FrameOneReactSelect from "../inputs/FrameOneReactSelect";

interface IProps {
	answerOption: CreateAnswerOptionBody;
	onChange: (key: keyof CreateAnswerOptionBody, value) => void;
	characteristics: Array<Characteristic>;
	onRemove: () => void;
}

const CreateQuestionAnswerInput: React.FC<IProps> = (props) => {

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof CreateAnswerOptionBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			props.onChange(key, e.target.value);
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof CreateAnswerOptionBody): (values: NumberFormatValues) => void {
		return (values) => {
			props.onChange(key, values.floatValue);
		}
	}

	/**
	 * onChange event for the partner drop-down.
	 *
	 * @param v
	 */
	function handleSelectCharacteristic(v: IReactSelectOption): void {
		props.onChange("characteristic", props.answerOption.characteristic !== null ? v?.value : undefined);
	}

	/**
	 * onRemove helper to prevent the default <a> tag action
	 *
	 * @param e
	 */
	function onRemove(e): void {
		e.preventDefault();
		props.onRemove();
	}

	return (
		<div className="create-question-answer-input-container">
			<div className="create-question-answer-input">
				<div className="create-question-answer-input_answer-container">
					<Label>
						Answer
					</Label>
					<Input
						placeholder="Enter Answer..."
						value={props.answerOption.answer}
						onChange={dynamicOnChange("answer")}
					/>
				</div>

				<div className="create-question-answer-input_point-container">
					<Label>
						Point Value
					</Label>
					<NumberFormat
						placeholder="Point Value..."
						value={props.answerOption.points}
						customInput={Input}
						allowNegative={true}
						decimalScale={0}
						onValueChange={numberFormatOnChange("points")}
					/>
				</div>

				<div className="create-question-answer-input_characteristic-container">
					<Label>
						Associated Characteristic
					</Label>

					<FrameOneReactSelect
						name="characteristic"
						placeholder="Search Characteristics..."
						isClearable={true}
						isLoading={props.characteristics === undefined}
						value={reverseLookUpReactSelectValue(props.characteristics, props.answerOption.characteristic, "name")}
						onChange={handleSelectCharacteristic}
						options={makeReactSelectOptions(props.characteristics, "name")}
					/>
				</div>
			</div>

			<div className="create-question-answer-input-remove-container">
				<a
					href="#"
					onClick={onRemove}
				>
					Remove
				</a>
			</div>
		</div>
	);
};

export default CreateQuestionAnswerInput;
