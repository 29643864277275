import React from "react";

interface IProps {
	name: string;
	title: string;
}

const AdminInfo: React.FC<IProps> = (props) => {

	return (
		<div className="admin-info">
			<p className="admin-info_name">{props.name}</p>
			<p className="admin-info_title">{props.title}</p>
		</div>
	);
};

export default AdminInfo;
