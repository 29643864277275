import React from "react";
import CompanyPhoto from "../../CompanyPhoto";
import { Partner } from "client";

interface IProps {
	partner: Partner;
}

const ManagePartnersImageCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<div className="make-image-cell">
				<CompanyPhoto
					src={props.partner?.image?.url}
					alt="Partner Profile Picture"
					className="make-image-cell_company-profile-picture"
				/>
			</div>
		</div>
	);
};

export default ManagePartnersImageCell;
