import React, {ReactNode} from "react";
import {Bundle, BundleItem, Equipment} from "client";
import FrameModal from "../modals/modalComponents/FrameModal";
import FrameModalHeader from "../modals/modalComponents/FrameModalHeader";
import FrameModalBody from "../modals/modalComponents/FrameModalBody";
import FrameModalFooter from "../modals/modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import FrameOneTableContainer from "../tables/FrameOneTableContainer";
import ManageBundlesItemsModalInventoryItemsCell from "../tables/cells/ManageBundlesItemsModalInventoryItemsCell";
import ManageBundlesItemModalRequiredCell from "../tables/cells/ManageBundlesItemModalRequiredCell";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
	bundle: Bundle;
}

const ManageBundlesItemsModal: React.FC<IProps> = (props) => {

	/**
	 * Renderer for the Required Cells.
	 *
	 * @param value
	 * @param bundleItem
	 */
	function makeRequiredCell(value: never, bundleItem: BundleItem): ReactNode {
		return (
			<ManageBundlesItemModalRequiredCell bundleItem={bundleItem}/>
		);
	}

	/**
	 * Renderer for the Inventory Items Cells.
	 *
	 * @param value
	 * @param bundleItem
	 */
	function makeInventoryItemsCell(value: never, bundleItem: BundleItem): ReactNode {
		return (
			<ManageBundlesItemsModalInventoryItemsCell bundleItem={bundleItem}/>
		);
	}

	return (
		<FrameModal
			isOpen={props.isOpen}
			toggle={props.onClose}
			size="lg"
		>
			<FrameModalHeader
				title={`Viewing items for bundle "${props.bundle?.name}"`}
				toggle={props.onClose}
			/>

			<FrameModalBody>
				<div className="narrow-frame-one-table-container mb-4">
					<FrameOneTableContainer
						data={props.bundle?.items}
						columnOptions={[
							{
								key: "name",
								headerValue: "Name",
								showSortIcons: false,
								sortable: false,
							},
							{
								key: "",
								headerValue: "Required",
								showSortIcons: false,
								sortable: false,
								cellRender: makeRequiredCell,
								headerCellClassName: "justify-content-center",
								rowCellClassName: "justify-content-center",
							},
							{
								key: "quantity",
								headerValue: "Quantity",
								showSortIcons: false,
								sortable: false,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "text-end",
							},
							{
								key: "equipmentOptions",
								headerValue: "Inventory",
								showSortIcons: false,
								sortable: false,
								cellRender: makeInventoryItemsCell,
								headerCellClassName: "justify-content-end",
								rowCellClassName: "justify-content-end",
							},
						]}
					/>
				</div>
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="mediumGray"
					onClick={props.onClose}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

export default ManageBundlesItemsModal;
