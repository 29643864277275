import React, {useEffect, useState} from "react";
import FrameOneRoundPanel from "./FrameOneRoundPanel";
import {ReactComponent as UserSettings} from "../svgs/user-settings.svg";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	addError,
	decrementLoading,
	incrementLoading,
	login,
	logout,
	toggleGlobalContactModalVisible
} from "../redux/meta/MetaActions";
import {AdminLoginBody, AdminsApi, Token, TokensApi} from "client";
import {Input, Label} from "reactstrap";
import {genericOnChangeHelper} from "../utils/genericOnChangeHelper";
import FrameButton from "./buttons/FrameButton";
import classNames from "classnames";
import {useHistory} from "react-router";
import getConfig from "../utils/getConfig";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	className?: string;
}

const defaultLoginBody: AdminLoginBody = {
	username: "",
	password: "",
}

const LoginCard: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [loginForm, setLoginForm] = useState<AdminLoginBody>(defaultLoginBody);

	/**
	 * Used to check if a token exists in props
	 * when the page loads, or if it somehow gets
	 * added otherwise in the meantime.
	 *
	 */
	useEffect(() => {
		if (props.fullToken) {
			verifyTokenExpiry(props.fullToken).then().catch();
		}
	}, [JSON.stringify(props.fullToken)]);

	/**
	 * Checks if the existing token is expired.
	 * If it is, dispatch the redux logout action but don't
	 * do anything from the user's perspective - they must log in again.
	 *
	 * If their token ISN'T expired, replace current index in history with the dashboard route.
	 *
	 * @param fullToken
	 */
	async function verifyTokenExpiry(fullToken: Token): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new TokensApi(getConfig(fullToken)).checkTokenExpiration({
				tokenBody: {
					token: fullToken?.token,
				},
			});

			if (res.expired) {
				await props.dispatch(logout());
			} else {
				history.push("/dashboard");
			}
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	/**
	 * Submit credentials to try and log the user in.
	 * If successful, save token to redux & send to dashboard.
	 *
	 */
	async function submitLogin(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			const res = await new AdminsApi().adminLogin({
				adminLoginBody: {
					username: loginForm?.username,
					password: loginForm?.password,
				},
			});

			props.dispatch(login(res));
			history.push("/dashboard");
		} catch (e) {
			props.dispatch(addError(e));
		}

		props.dispatch(decrementLoading());
	}

	function onContact(e): void {
		e?.preventDefault();
		props.dispatch(toggleGlobalContactModalVisible(true));
	}

	return (
		<FrameOneRoundPanel className={classNames("login-card", props.className)}>
			<div className="login-card_form">
				<UserSettings className="login-card_form_user-icon"/>

				<h3 className="login-card_form_title">
					Admin login portal
				</h3>

				<form onSubmit={submitLogin}>
					<Label>Username</Label>
					<Input
						placeholder="Username..."
						value={loginForm?.username}
						onChange={genericOnChangeHelper(loginForm, setLoginForm, "username")}
					/>

					<Label>Password</Label>
					<Input
						placeholder="Password..."
						value={loginForm?.password}
						onChange={genericOnChangeHelper(loginForm, setLoginForm, "password")}
						type="password"
					/>

					<div className="login-card_form_button-container">
						<FrameButton
							type="submit"
							color="darkPurple"
							className="mb-3"
							onClick={submitLogin}
						>
							Log In
						</FrameButton>
					</div>
				</form>

				<p className="login-card_form_contact">
					Having problems? <a href="#" onClick={onContact}>Contact Support</a>
				</p>
			</div>

			<div className="login-card_graphic-container">
				<img
					src={process.env.PUBLIC_URL + "/images/login-bubbles.svg"}
					className="login-card_graphic-container_bubbles"
				/>

				<div className="login-card_graphic-container_computer-container">
					<img
						src={process.env.PUBLIC_URL + "/images/login-computer.svg"}
						className="login-card_graphic-container_computer-container_computer"
					/>
				</div>
			</div>
		</FrameOneRoundPanel>
	);
};

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(LoginCard);
