import React from "react";
import FrameOneCheckbox from "../../FrameOneCheckbox";
import {IProps as IFrameOneCheckboxProps} from "../../FrameOneCheckbox";

/**
 * Can be used anywhere you need a single, long checkbox in a table cell.
 * Make sure in your implementation to configure the...
 * `checked` prop,
 * `onToggle` prop with custom logic based on your use-case,
 * and see if you need `enableOnClickHelper` set to false (see FrameOneCheckbox IProps for more info).
 *
 * @param props
 * @constructor
 */
const GenericCheckBoxTableCell: React.FC<IFrameOneCheckboxProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<FrameOneCheckbox {...props}/>
		</div>
	);
};

export default GenericCheckBoxTableCell;
