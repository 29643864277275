import React from "react";
import CompanyPhoto from "../../CompanyPhoto";
import {Characteristic} from "client";

interface IProps {
	characteristic: Characteristic;
}

const ManageCharacteristicsImageCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			<div className="make-image-cell">
				<CompanyPhoto
					src={props.characteristic?.image?.url}
					alt="Characteristic Picture"
					className="make-image-cell_company-profile-picture"
				/>
			</div>
		</div>
	);
};

export default ManageCharacteristicsImageCell;
