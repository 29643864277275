import React, {ChangeEvent, ChangeEventHandler, useEffect, useState} from "react";
import {
	Asset, AssetsApi,
	BundleBody,
	BundleItemBody,
	BundlesApi,
	Characteristic,
	Equipment,
	Token
} from "client";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import FrameOneContainer from "../components/FrameOneContainer";
import PageHeader from "../components/PageHeader";
import BreadCrumbs from "../components/BreadCrumbs";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import CreateBundleDetails from "../components/bundle/CreateBundleDetails";
import CreateBundlesRequiredCharacteristics from "../components/bundle/CreateBundlesRequiredCharacteristics";
import CreateBundlesBlockerCharacteristics from "../components/bundle/CreateBundlesBlockerCharacteristics";
import CreateBundleInventorySelection from "../components/bundle/CreateBundleInventorySelection";
import {isValidNumber} from "../utils/isValidNumber";
import {useHistory} from "react-router-dom";
import FrameOneButtonActions from "../components/FrameOneButtonActions";
import FrameButton from "../components/buttons/FrameButton";
import {NumberFormatValues} from "react-number-format";
import {addURLsToFiles, FileWithSRC} from "../utils/renderAssetsHelper";
import {cloneDeep} from "lodash";

export interface IBundleItemBodyFrontend extends Omit<BundleItemBody, "equipmentOptions"> {
	equipmentOptions: Array<Equipment>;
}

export interface IBundleBodyFrontend extends Omit<BundleBody, "thumbnail" | "images" | "requiredCharacteristics" | "blockingCharacteristics" | "bookingTime" | "items"> {
	thumbnail: Asset | FileWithSRC;
	images: Array<Asset | FileWithSRC>;
	requiredCharacteristics: Array<Characteristic>;
	blockingCharacteristics: Array<Characteristic>;
	days: number;
	items: Array<IBundleItemBodyFrontend>;
}

export const defaultBundleForm: IBundleBodyFrontend = {
	active: false,
	name: "",
	description: "",
	thumbnail: undefined,
	images: [],
	requiredCharacteristics: [],
	blockingCharacteristics: [],
	days: undefined,
	items: [],
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const CreateBundle: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [bundleForm, setBundleForm] = useState<IBundleBodyFrontend>(defaultBundleForm);

	/**
	 * Custom onChange for the file input, so we can use our util to add a usable URL while we have the file on the frontend.
	 *
	 * @param e
	 */
	async function onFileChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		setBundleForm({
			...bundleForm,
			thumbnail: (await addURLsToFiles(e?.target.files))[0],
		});
	}

	/**
	 * Little helper to set the thumbnail to undefined (can technically be done by opening the file prompt and cancelling,
	 * but shouldn't rely on client knowing that; plus good to remember for user portal as well).
	 *
	 */
	function removeThumbnail(): void {
		setBundleForm({
			...bundleForm,
			thumbnail: undefined,
		});
	}

	/**
	 * Custom onChange for the additional images input, to add usable URL.
	 *
	 * @param e
	 */
	async function onAdditionalImagesChange(e: ChangeEvent<HTMLInputElement>): Promise<void> {
		const newImages = await addURLsToFiles(e?.target.files);

		setBundleForm({
			...bundleForm,
			images: bundleForm.images.concat(newImages),
		});
	}

	/**
	 * Splice the selected image from the array and update state.
	 *
	 * @param index
	 */
	function onRemoveAdditionalImage(index: number): void {
		const imagesCopy = cloneDeep(bundleForm?.images);
		imagesCopy.splice(index, 1);

		setBundleForm({
			...bundleForm,
			images: imagesCopy,
		});
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof IBundleBodyFrontend): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setBundleForm({
				...bundleForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * onChange handler for the Number Format input to grab the right value from the returned data.
	 *
	 * @param key
	 */
	function numberFormatOnChange(key: keyof IBundleBodyFrontend): (values: NumberFormatValues) => void {
		return (values) => {
			setBundleForm({
				...bundleForm,
				[key]: values.floatValue,
			});
		}
	}

	/**
	 * onChange handler for toggling the checkbox value(s).
	 *
	 * @param key
	 */
	function checkBoxOnChange(key: keyof IBundleBodyFrontend): () => void {
		return () => {
			setBundleForm({
				...bundleForm,
				[key]: !bundleForm[key],
			});
		}
	}

	/**
	 * onChange for both the required & blocking characteristics.
	 *
	 * @param requiredCharacteristics
	 * @param blockingCharacteristics
	 */
	function characteristicsOnChange(requiredCharacteristics: Array<Characteristic>, blockingCharacteristics: Array<Characteristic>): void {
		setBundleForm({
			...bundleForm,
			requiredCharacteristics,
			blockingCharacteristics,
		});
	}

	/**
	 * onChange for the Items section.
	 *
	 * @param newSelections
	 */
	function onChangeInventorySelections(newSelections: Array<IBundleItemBodyFrontend>): void {
		setBundleForm({
			...bundleForm,
			items: newSelections,
		});
	}

	/**
	 * Call the api with data formatted to match api interface and send to Manage Bundles Page after.
	 *
	 * @param e
	 */
	async function submitNewBundle(e?): Promise<void> {
		e?.preventDefault();
		props.dispatch(incrementLoading());

		try {
			// Upload the thumbnail image
			let thumbnailIdRes: Asset;
			if (bundleForm.thumbnail) {
				thumbnailIdRes = await new AssetsApi(getConfig(props.fullToken)).createAsset({
					asset: bundleForm.thumbnail as FileWithSRC,
				});
			}

			// Upload the additional gallery images
			let galleryAssets: Array<Asset> = [];
			if (bundleForm.images.length > 0) {
				galleryAssets = await Promise.all(bundleForm.images.map(async (asset): Promise<Asset> => {
					return await new AssetsApi(getConfig(props.fullToken)).createAsset({
						asset: asset as FileWithSRC,
					});
				}));
			}

			await new BundlesApi(getConfig(props.fullToken)).createBundle({
				bundleBody: {
					active: bundleForm.active,
					name: bundleForm?.name || undefined,
					description: bundleForm?.description || undefined,
					thumbnail: thumbnailIdRes._id,
					images: galleryAssets.map(a => a._id),
					requiredCharacteristics: bundleForm?.requiredCharacteristics.map(c => c._id),
					blockingCharacteristics: bundleForm?.blockingCharacteristics.map(c => c._id),
					bookingTime: bundleForm.days !== undefined ? (bundleForm.days * 24 * 60 * 60 * 1000) : undefined,
					items: bundleForm.items.map((item) => {
						return {
							required: item.required,
							name: item.name || undefined,
							quantity: isValidNumber(item.quantity) ? Number(item.quantity) : undefined,
							equipmentOptions: item.equipmentOptions.map(equipment => equipment._id),
						}
					}),
				},
			});

			history.push("/manage-bundles");
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<div>
			<PageHeader>
				<BreadCrumbs
					crumbs={[
						{
							label: "Manage Bundles",
							route: "/manage-bundles",
						},
						{
							label: "Create New Bundle",
							route: "/manage-bundles/create",
						},
					]}
				/>

				<h3 className="mt-3">
					Create New Bundle
				</h3>
			</PageHeader>

			<FrameOneContainer>
				<div className="row g-3">
					<div className="col col-12">
						<CreateBundleDetails
							thumbnail={bundleForm.thumbnail}
							images={bundleForm.images}
							name={bundleForm.name}
							days={bundleForm.days}
							description={bundleForm.description}
							active={bundleForm.active}
							onChangeThumbnail={onFileChange}
							onRemoveThumbnail={removeThumbnail}
							onAdditionalImagesChange={onAdditionalImagesChange}
							onRemoveAdditionalImage={onRemoveAdditionalImage}
							onChangeText={dynamicOnChange}
							onChangeNumber={numberFormatOnChange}
							onChangeCheckbox={checkBoxOnChange}
						/>
					</div>

					<div className="col col-12">
						<CreateBundlesRequiredCharacteristics
							requiredCharacteristics={bundleForm.requiredCharacteristics}
							blockedCharacteristics={bundleForm.blockingCharacteristics}
							onChangeCharacteristics={characteristicsOnChange}
						/>
					</div>

					<div className="col col-12">
						<CreateBundlesBlockerCharacteristics
							requiredCharacteristics={bundleForm.requiredCharacteristics}
							blockedCharacteristics={bundleForm.blockingCharacteristics}
							onChangeCharacteristics={characteristicsOnChange}
						/>
					</div>

					<div className="col col-12">
						<CreateBundleInventorySelection
							items={bundleForm.items}
							onChangeInventorySelections={onChangeInventorySelections}
						/>
					</div>
				</div>

				<div className="mt-3">
					<FrameOneButtonActions>
						<FrameButton
							color="darkPurple"
							onClick={submitNewBundle}
						>
							Save Bundle
						</FrameButton>
					</FrameOneButtonActions>
				</div>
			</FrameOneContainer>
		</div>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateBundle);
