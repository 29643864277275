import React, {ReactNode, useState} from "react";
import {removeError, toggleGlobalContactModalVisible} from "../../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";
import {APIError} from "client";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";

/* Main Error Modal Manager */

interface IProps {
	errors?: Array<APIError>;
}

const ErrorModalManager: React.FC<IProps> = (props: IProps) => {

	const modals: Array<ReactNode> = props.errors?.map((e: APIError, i: number) => {
		return (
			<ErrorModal
				key={`error-modal_${i}`}
				apiError={e}
				index={i}
			/>
		);
	});

	return (
		<React.Fragment>
			{modals}
		</React.Fragment>
	);
};

/* Individual Modals */

interface IErrorModalProps {
	apiError: APIError;
	index: number;
	dispatch?: any;
}

const _ErrorModal: React.FC<IErrorModalProps> = (props: IErrorModalProps) => {

	function dismiss(): void {
		props.dispatch(removeError(props.index));
	}

	function toggleContactModal(): void {
		props.dispatch(toggleGlobalContactModalVisible(true));
	}

	function createErrors(_error: APIError, i: number): ReactNode {
		return (
			<li key={`error_${i}`}>
				{_error.message}
				{Array.isArray(_error.errors) && (
					<ul>
						{_error.errors.map(createErrors)}
					</ul>
				)}
			</li>
		);
	}

	return (
		<FrameModal
			isOpen={true}
			toggle={dismiss}
		>
			<FrameModalHeader
				title="Error"
				toggle={dismiss}
			/>

			<FrameModalBody>
				<b>{props.apiError?.message}</b>
				{props.apiError?.errors?.length > 0 && (
					<ul>
						{props.apiError.errors.map(createErrors)}
					</ul>
				)}
			</FrameModalBody>

			<FrameModalFooter>
				<FrameButton
					color="darkBlue"
					onClick={toggleContactModal}
				>
					Contact Support
				</FrameButton>

				<FrameButton
					color="darkPurple"
					onClick={dismiss}
				>
					Dismiss
				</FrameButton>
			</FrameModalFooter>
		</FrameModal>
	);
};

const ErrorModal = connect()(_ErrorModal);

export default connect((store: IStore, props: IProps) => {
	return {
		...props,
		errors: store.metaStore.errors,
	}
})(ErrorModalManager);
