"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CharacteristicToJSON = exports.CharacteristicFromJSONTyped = exports.CharacteristicFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function CharacteristicFromJSON(json) {
    return CharacteristicFromJSONTyped(json, false);
}
exports.CharacteristicFromJSON = CharacteristicFromJSON;
function CharacteristicFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'disabled': json['disabled'],
        'name': json['name'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'minimumPointValue': json['minimumPointValue'],
        'maximumPointValue': !runtime_1.exists(json, 'maximumPointValue') ? undefined : json['maximumPointValue'],
        'image': !runtime_1.exists(json, 'image') ? undefined : _1.AssetFromJSON(json['image']),
    };
}
exports.CharacteristicFromJSONTyped = CharacteristicFromJSONTyped;
function CharacteristicToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'disabled': value.disabled,
        'name': value.name,
        'description': value.description,
        'minimumPointValue': value.minimumPointValue,
        'maximumPointValue': value.maximumPointValue,
        'image': _1.AssetToJSON(value.image),
    };
}
exports.CharacteristicToJSON = CharacteristicToJSON;
