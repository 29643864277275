import {Admin, APIError, Token} from "client";

export interface IMetaStore {
	fullToken?: Token;
	loadingIncrement?: number;
	errors?: Array<APIError>;
	sidebarVisible: boolean;
	globalContactModalVisible: boolean;
	currentUser: Admin;
}

export default {
	loadingIncrement: 0,
	errors: [],
	sidebarVisible: false,
	globalContactModalVisible: false,
	currentUser: undefined,
} as IMetaStore;
