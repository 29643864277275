import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {IStore} from "../../../redux/defaultStore";
import {Partner, PartnerAdmin, Token} from "client";
import {FiEdit2, FiRefreshCw, FiTrash} from "react-icons/all";
import FrameButton from "../../buttons/FrameButton";
import ManagePartnerAdminsEditModal from "../../modals/ManagePartnerAdminsEditModal";
import ManagePartnerAdminsDeleteModal from "../../modals/ManagePartnerAdminsDeleteModal";
import ManagePartnerAdminsEnableModal from "../../modals/ManagePartnerAdminsEnableModal";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
	partnerAdmin: PartnerAdmin;
	onDone: () => Promise<void>;
}

const ManagePartnerAdminsActionsCell: React.FC<IProps> = (props) => {

	const [viewEditModal, setViewEditModal] = useState(false);
	const [viewDeleteModal, setViewDeleteModal] = useState(false);
	const [viewEnableModal, setViewEnableModal] = useState(false);

	/**
	 * Hide or show the edit
	 *
	 */
	function toggleEditModal(): void {
		setViewEditModal(!viewEditModal);
	}

	/**
	 * Hide or show the delete modal.
	 *
	 */
	function toggleDeleteModal(): void {
		setViewDeleteModal(!viewDeleteModal);
	}

	/**
	 * Hide or show the delete modal.
	 *
	 */
	function toggleEnableModal(): void {
		setViewEnableModal(!viewEnableModal);
	}

	/**
	 * When edit or delete is successful, close modal and recall api.
	 *
	 */
	function onDoneModals() {
		setViewEditModal(false);
		setViewDeleteModal(false);
		setViewEnableModal(false);
		props.onDone().then().catch();
	}

	return (
		<React.Fragment>
			{/*<ManagePartnerAdminsEditModal*/}
			{/*	partnerAdmin={props.partnerAdmin}*/}
			{/*	isOpen={viewEditModal}*/}
			{/*	onClose={toggleEditModal}*/}
			{/*	onDone={onDoneEditOrDeleteModal}*/}
			{/*/>*/}

			<ManagePartnerAdminsDeleteModal
				partnerAdmin={props.partnerAdmin}
				isOpen={viewDeleteModal}
				onClose={toggleDeleteModal}
				onDone={onDoneModals}
			/>

			<ManagePartnerAdminsEnableModal
				partnerAdmin={props.partnerAdmin}
				isOpen={viewEnableModal}
				onClose={toggleEnableModal}
				onDone={onDoneModals}
			/>

			<div className="frame-one-table-cell">
				{/*<FrameButton*/}
				{/*	className="frame-one-button-small"*/}
				{/*	color="mediumGray"*/}
				{/*	outline={true}*/}
				{/*	icon={FiEdit2}*/}
				{/*	onClick={toggleEditModal}*/}
				{/*/>*/}

				{props.partnerAdmin?.disabled ? (
					<FrameButton
						className="frame-one-button-small"
						color="success"
						icon={FiRefreshCw}
						onClick={toggleEnableModal}
					/>
				) : (
					<FrameButton
					    className="frame-one-button-small"
					    color="red"
					    icon={FiTrash}
					    onClick={toggleDeleteModal}
					/>
				)}
			</div>
		</React.Fragment>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManagePartnerAdminsActionsCell);
