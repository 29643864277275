import React, {useState} from "react";
import {Bundle} from "client";
import ManageBundlesItemsModal from "../../bundle/ManageBundlesItemsModal";

interface IProps {
	bundle: Bundle;
}

const ManageBundlesItemsCell: React.FC<IProps> = (props) => {

	const [showItemsModal, setShowItemsModal] = useState(false);

	/**
	 * Hide or show the Items Modal.
	 *
	 */
	function toggleItemsModal(): void {
		setShowItemsModal(!showItemsModal);
	}

	return (
		<React.Fragment>
			<ManageBundlesItemsModal
				isOpen={showItemsModal}
				onClose={toggleItemsModal}
				bundle={props.bundle}
			/>

			<div className="frame-one-table-cell">
				{props.bundle?.items?.length > 0 ? (
					<a
						href="#"
						onClick={toggleItemsModal}
					>
						{`${props.bundle?.items?.length} (View)`}
					</a>
				) : (
					<span className="empty-message">
						No Items.
					</span>
				)}
			</div>
		</React.Fragment>
	);
};

export default ManageBundlesItemsCell;
