"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VerificationsApi = void 0;
const runtime = __importStar(require("../runtime"));
const models_1 = require("../models");
/**
 * no description
 */
class VerificationsApi extends runtime.BaseAPI {
    /**
     * Used to create an email verification and send a verification code to an email
     */
    async requestEmailVerificationRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/email/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to create an email verification and send a verification code to an email
     */
    async requestEmailVerification() {
        await this.requestEmailVerificationRaw();
    }
    /**
     * Used by users to request a password reset verification code
     */
    async requestPasswordResetRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/password-reset/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.RequestPasswordResetBodyToJSON(requestParameters.requestPasswordResetBody),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => models_1.RequestPasswordResetResponseFromJSON(jsonValue));
    }
    /**
     * Used by users to request a password reset verification code
     */
    async requestPasswordReset(requestParameters) {
        const response = await this.requestPasswordResetRaw(requestParameters);
        return await response.value();
    }
    /**
     * Used to create a phone number verification text
     */
    async requestPhoneNumberVerificationRaw() {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("User", []) : token;
            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/phone-number/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to create a phone number verification text
     */
    async requestPhoneNumberVerification() {
        await this.requestPhoneNumberVerificationRaw();
    }
    /**
     * Used to validate user\'s email.
     */
    async verifyEmailRaw(requestParameters) {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code', 'Required parameter requestParameters.code was null or undefined when calling verifyEmail.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/email/verify/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to validate user\'s email.
     */
    async verifyEmail(requestParameters) {
        await this.verifyEmailRaw(requestParameters);
    }
    /**
     * Used by users to reset their password after they get a verification code.
     */
    async verifyPasswordResetRaw(requestParameters) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/password-reset/verify`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: models_1.ResetPasswordBodyToJSON(requestParameters.resetPasswordBody),
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used by users to reset their password after they get a verification code.
     */
    async verifyPasswordReset(requestParameters) {
        await this.verifyPasswordResetRaw(requestParameters);
    }
    /**
     * Used to verify a phone number.
     */
    async verifyPhoneNumberRaw(requestParameters) {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code', 'Required parameter requestParameters.code was null or undefined when calling verifyPhoneNumber.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/phone-number/verify/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Used to verify a phone number.
     */
    async verifyPhoneNumber(requestParameters) {
        await this.verifyPhoneNumberRaw(requestParameters);
    }
}
exports.VerificationsApi = VerificationsApi;
