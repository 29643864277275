import React, {ReactNode, useEffect, useState} from "react";
import {Input} from "reactstrap";
import {defaultFrontendPagination, FrontendPagination} from "../components/tables/FrameOnePaginator";
import {Bundle, BundlesApi, GetBundlesResponse, Token} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {AiOutlinePlus} from "react-icons/all";
import PageHeader from "../components/PageHeader";
import FrameOneTableContainer from "../components/tables/FrameOneTableContainer";
import FrameButton from "../components/buttons/FrameButton";
import BreadCrumbs, {IBreadCrumbItem} from "../components/BreadCrumbs";
import AddNewBundleModal from "../components/bundle/AddNewBundleModal";
import ManageBundlesActionsCell from "../components/tables/cells/ManageBundlesActionsCell";
import CompanyPhoto from "../components/CompanyPhoto";
import getConfig from "../utils/getConfig";
import ManageBundlesImageCell from "../components/tables/cells/ManageBundlesImageCell";
import {Link} from "react-router-dom";
import {convertBundleBookingTimeToDays} from "../utils/convertBundleBookingTimeToDays";
import ManageBundlesItemsCell from "../components/tables/cells/ManageBundlesItemsCell";
import ManageBundlesActiveCell from "../components/tables/cells/ManageBundlesActiveCell";

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const ManageBundles: React.FC<IProps> = (props) => {

	const [searchValue, setSearchValue] = useState<string>(undefined);
	const [firstRender, setFirstRender] = useState(true);
	const [bundles, setBundles] = useState<GetBundlesResponse>(undefined);
	const [frontendPagination, setFrontendPagination] = useState<FrontendPagination>(defaultFrontendPagination);

	useEffect(() => {
		if (firstRender) {
			setFirstRender(false);
		}

		readBundles().then().catch();
	}, [JSON.stringify(frontendPagination)]);

	/**
	 * Sets a timeout after the user stops typing in the search input
	 * After timeout completes API is called with search property
	 *
	 */
	useEffect(() => {
		const searchTimeout = searchValue !== undefined ? setTimeout(() => {
			readBundles().then().catch();
		}, 1000) : null;

		return () => {
			clearTimeout(searchTimeout);
		}
	}, [searchValue]);

	/**
	 * Handle the search input onChange.
	 *
	 * @param e
	 */
	function searchOnChange(e?): void {
		setSearchValue(e.target.value);
	}

	/**
	 * Calling API to get Bundles.
	 *
	 */
	async function readBundles(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new BundlesApi(getConfig(props.fullToken)).getBundles({
				search: searchValue,
				...frontendPagination,
			});

			setBundles(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Renderer for the Bundle Image Cells.
	 *
	 * @param value
	 * @param bundle
	 */
	function makeImageCell(value: never, bundle: Bundle): ReactNode {
		return (
			<ManageBundlesImageCell bundle={bundle}/>
		);
	}

	/**
	 * Renderer for the Active Cells.
	 *
	 */
	function makeActiveCell(value: never, bundle: Bundle): ReactNode {
		return (
			<ManageBundlesActiveCell bundle={bundle}/>
		);
	}

	/**
	 * Renderer for the Items Cells.
	 *
	 * @param value
	 * @param bundle
	 */
	function makeItemsCell(value: never, bundle: Bundle): ReactNode {
		return (
			<ManageBundlesItemsCell bundle={bundle}/>
		);
	}

	/**
	 * Renderer for the Actions Cells.
	 *
	 * @param value
	 * @param bundle
	 */
	function makeActionsCell(value: never, bundle: Bundle): ReactNode {
		return (
			<ManageBundlesActionsCell
				bundle={bundle}
				onDone={readBundles}
			/>
		);
	}

	return (
		<div>
			<PageHeader>
				<h3>
					Manage Bundles
				</h3>

				<div className="row gy-3">
					<div className="col col-12 col-md-6">
						<label>
							Search
						</label>
						<Input
							placeholder="Search for Bundles"
							value={searchValue}
							onChange={searchOnChange}
						/>
					</div>

					<div className="col col-12 col-md-6 col-xl-4 offset-xl-2">
						<label className="ghost-label d-none d-md-block">Add</label>
						<Link
							to="/manage-bundles/create"
							className="text-decoration-none"
						>
							<FrameButton
								color="darkPurple"
								icon={AiOutlinePlus}
								className="w-100"
							>
								Create New Bundle
							</FrameButton>
						</Link>
					</div>
				</div>
			</PageHeader>

			<div className="p-3">
				<FrameOneTableContainer
					data={bundles?.bundles}
					pagination={{
						...bundles?.paginationInfo,
						...frontendPagination,
					}}
					onPaginationChange={setFrontendPagination}
					columnOptions={[
						{
							key: "image",
							headerValue: "Picture",
							showSortIcons: false,
							sortable: false,
							cellRender: makeImageCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: "name",
							headerValue: "Name",
							showSortIcons: false,
							sortable: false,
						},
						{
							key: "bookingTime",
							headerValue: "Duration",
							showSortIcons: false,
							sortable: false,
							valueFormatter: convertBundleBookingTimeToDays,
							headerCellClassName: "justify-content-end",
							rowCellClassName: "text-end"
						},
						{
							key: "description",
							headerValue: "Description",
							showSortIcons: false,
							sortable: false,
							rowCellClassName: "w-100",
						},
						{
							key: "",
							headerValue: "Active",
							showSortIcons: false,
							sortable: false,
							cellRender: makeActiveCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
						{
							key: "items",
							headerValue: "Items",
							showSortIcons: false,
							sortable: false,
							cellRender: makeItemsCell,
							headerCellClassName: "justify-content-end",
							rowCellClassName: "justify-content-end"
						},
						{
							key: undefined,
							headerValue: "Actions",
							showSortIcons: false,
							sortable: false,
							cellRender: makeActionsCell,
							headerCellClassName: "justify-content-center",
							rowCellClassName: "justify-content-center",
						},
					]}
				/>
			</div>
		</div>
	);
}

export default connect((store: IStore, props: IProps) => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(ManageBundles);
