import React from "react";
import {addError, decrementLoading, incrementLoading} from "../../redux/meta/MetaActions";
import {Token, UsersApi} from "client";
import FrameModal from "./modalComponents/FrameModal";
import FrameModalHeader from "./modalComponents/FrameModalHeader";
import FrameModalBody from "./modalComponents/FrameModalBody";
import FrameModalFooter from "./modalComponents/FrameModalFooter";
import FrameButton from "../buttons/FrameButton";
import getConfig from "../../utils/getConfig";
import {generateUserFullName} from "../../utils/generateUserFullName";
import {connect} from "react-redux";
import {IStore} from "../../redux/defaultStore";

interface IProps {
    dispatch?: any;
    fullToken?: Token;
    companyAdmin: any;
    isOpen: boolean;
    onClose: () => void;
    onDone: () => void;
}

const ManageCompanyAdminsDeleteModal: React.FC<IProps> = (props) => {

    /**
     * Call the api to disable the user and then close the modal and get the updated list of data.
     *
     */
    async function confirmDelete(): Promise<void> {
        props.dispatch(incrementLoading());

        try {
            await new UsersApi(getConfig(props.fullToken)).disableUser({
                id: props.companyAdmin?._id,
            });

            props.onDone();
        } catch (e) {
            props.dispatch(addError(e));
        } finally {
            props.dispatch(decrementLoading());
        }
    }

    return (
        <FrameModal
            isOpen={props.isOpen}
            toggle={props.onClose}
        >
            <FrameModalHeader
                title="Confirm Delete"
                toggle={props.onClose}
            />

            <FrameModalBody>
                <p>
                    Are you sure you want to delete <b>{generateUserFullName(props.companyAdmin)}</b>?
                </p>
            </FrameModalBody>

            <FrameModalFooter>
                <FrameButton
                    color="mediumGray"
                    outline={true}
                    onClick={props.onClose}
                >
                    Cancel
                </FrameButton>

                <FrameButton
                    color="red"
                    onClick={confirmDelete}
                >
                    Delete
                </FrameButton>
            </FrameModalFooter>
        </FrameModal>
    );
}

export default connect((store: IStore, props: IProps) => {
    return {
        fullToken: store.metaStore.fullToken,
        ...props,
    }
})(ManageCompanyAdminsDeleteModal);
