export function isValidNumber(value: unknown): boolean {
	if (
		value === undefined ||
		value === null ||
		value === ""
	) {
		return false;
	}

	return true;
}
