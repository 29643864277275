import React, {ReactNode} from "react";
import {FiBox} from "react-icons/all";
import classNames from "classnames";

export interface IIconWithLabelProps {
	icon?: ReactNode;
	label: string;
	className?: string;
}

const IconWithLabel: React.FC<IIconWithLabelProps> = (props) => {

	const Icon: any = props.icon;

	return (
		<div className={classNames("icon-with-label", props.className)}>
			<Icon className="icon-with-label_icon"/>

			<span className="icon-with-label_label">
				{props.label}
			</span>
		</div>
	);
};

IconWithLabel.defaultProps = {
	icon: FiBox,
};

export default IconWithLabel;
