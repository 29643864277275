"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeIntervalToJSON = exports.TimeIntervalFromJSONTyped = exports.TimeIntervalFromJSON = void 0;
const runtime_1 = require("../runtime");
function TimeIntervalFromJSON(json) {
    return TimeIntervalFromJSONTyped(json, false);
}
exports.TimeIntervalFromJSON = TimeIntervalFromJSON;
function TimeIntervalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'start': !runtime_1.exists(json, 'start') ? undefined : json['start'],
        'end': !runtime_1.exists(json, 'end') ? undefined : json['end'],
    };
}
exports.TimeIntervalFromJSONTyped = TimeIntervalFromJSONTyped;
function TimeIntervalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'start': value.start,
        'end': value.end,
    };
}
exports.TimeIntervalToJSON = TimeIntervalToJSON;
