import React from "react";
import SocialIconButton from "./SocialIconButton";
import {ReactComponent as F1} from "../svgs/frame-one-software_f1-white.svg";
import {ReactComponent as SocialHome} from "../svgs/social-home.svg";
import {ReactComponent as SocialLinkedIn} from "../svgs/social-linkedin.svg";
import {ReactComponent as SocialFacebook} from "../svgs/social-facebook.svg";
import {ReactComponent as SocialGithub} from "../svgs/social-github.svg";
import classNames from "classnames";

interface IProps {
	className?: string;
}

const LoginFrameOneInfo: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("login-frame-one-info", props.className)}>
			<F1 className="login-frame-one-info_f1-icon"/>

			<div className="login-frame-one-info_text-container">
				<span className="login-frame-one-info_text-container_powered-by">
					Powered by
				</span>

				<span className="login-frame-one-info_text-container_frame-one">
					Frame One Software
				</span>
			</div>

			<div className="login-frame-one-info_icons-container">
				<SocialIconButton href="https://frameonesoftware.com/">
					<SocialHome/>
				</SocialIconButton>

				<SocialIconButton href="https://www.linkedin.com/company/frame-one-software-inc/">
					<SocialLinkedIn/>
				</SocialIconButton>

				<SocialIconButton href="https://www.facebook.com/frameonesoftware/">
					<SocialFacebook/>
				</SocialIconButton>

				<SocialIconButton href="https://github.com/Frame-One-Software/">
					<SocialGithub/>
				</SocialIconButton>
			</div>
		</div>
	);
};

export default LoginFrameOneInfo;
