"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BundleToJSON = exports.BundleFromJSONTyped = exports.BundleFromJSON = void 0;
const runtime_1 = require("../runtime");
const _1 = require("./");
function BundleFromJSON(json) {
    return BundleFromJSONTyped(json, false);
}
exports.BundleFromJSON = BundleFromJSON;
function BundleFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        '_id': json['_id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'active': json['active'],
        'name': json['name'],
        'description': json['description'],
        'thumbnail': !runtime_1.exists(json, 'thumbnail') ? undefined : _1.AssetFromJSON(json['thumbnail']),
        'images': (json['images'].map(_1.AssetFromJSON)),
        'requiredCharacteristics': (json['requiredCharacteristics'].map(_1.CharacteristicFromJSON)),
        'blockingCharacteristics': (json['blockingCharacteristics'].map(_1.CharacteristicFromJSON)),
        'bookingTime': json['bookingTime'],
        'items': (json['items'].map(_1.BundleItemFromJSON)),
    };
}
exports.BundleFromJSONTyped = BundleFromJSONTyped;
function BundleToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        '_id': value._id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'active': value.active,
        'name': value.name,
        'description': value.description,
        'thumbnail': _1.AssetToJSON(value.thumbnail),
        'images': (value.images.map(_1.AssetToJSON)),
        'requiredCharacteristics': (value.requiredCharacteristics.map(_1.CharacteristicToJSON)),
        'blockingCharacteristics': (value.blockingCharacteristics.map(_1.CharacteristicToJSON)),
        'bookingTime': value.bookingTime,
        'items': (value.items.map(_1.BundleItemToJSON)),
    };
}
exports.BundleToJSON = BundleToJSON;
