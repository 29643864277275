import React from "react";
import {FiCheck, FiX} from "react-icons/all";
import {BundleItem} from "client";

interface IProps {
	bundleItem: BundleItem
}

const ManageBundlesItemModalRequiredCell: React.FC<IProps> = (props) => {

	return (
		<div className="frame-one-table-cell">
			{props.bundleItem.required ? (
				<FiCheck className="text-green"/>
			) : (
				<FiX className="text-red"/>
			)}
		</div>
	);
};

export default ManageBundlesItemModalRequiredCell;
