import React from "react";
import classNames from "classnames";

interface IProps {
	className?: string;
}

const SideBarVersionDisplay: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("side-bar-version-display", props.className)}>
			<h6 className="side-bar-version-display_label">
				Version
			</h6>

			<p className="side-bar-version-display_name">
				{process.env.REACT_APP_PROJECT_NAME}
			</p>

			<p className="side-bar-version-display_version">
				{process.env.REACT_APP_VERSION}
			</p>
		</div>
	);
};

export default SideBarVersionDisplay;
