import React, {ReactNode} from "react";
import classNames from "classnames";

interface IProps {
	children: ReactNode;
	className?: string;
}

const FrameOneRoundPanel: React.FC<IProps> = (props) => {

	return (
		<div className={classNames("frame-one-round-panel", props.className)}>
			{props.children}
		</div>
	);
};

export default FrameOneRoundPanel;
