import React, {ChangeEventHandler, useEffect, useState} from "react";
import {connect} from "react-redux";
import {IStore} from "../redux/defaultStore";
import {
	BundleBody, CharacteristicsApi,
	CreateAnswerOptionBody,
	CreateQuestionBody,
	GetCharacteristicsResponse,
	QuestionsApi,
	Token
} from "client";
import {addError, decrementLoading, incrementLoading} from "../redux/meta/MetaActions";
import getConfig from "../utils/getConfig";
import BreadCrumbs from "../components/BreadCrumbs";
import PageHeader from "../components/PageHeader";
import FrameOneContainer from "../components/FrameOneContainer";
import CreateQuestionDetails from "../components/question/CreateQuestionDetails";
import CreateQuestionAnswers from "../components/question/CreateQuestionAnswers";
import FrameOneButtonActions from "../components/FrameOneButtonActions";
import FrameButton from "../components/buttons/FrameButton";
import {useHistory} from "react-router-dom";

export const defaultCreateAnswerOptionBody: CreateAnswerOptionBody = {
	answer: "",
	points: undefined,
	characteristic: undefined,
};

const defaultCreateQuestionBody: CreateQuestionBody = {
	question: "",
	answerOptions: Array(2).fill(defaultCreateAnswerOptionBody),
};

interface IProps {
	dispatch?: any;
	fullToken?: Token;
}

const CreateQuestion: React.FC<IProps> = (props) => {

	const history = useHistory();
	const [questionForm, setQuestionForm] = useState<CreateQuestionBody>(defaultCreateQuestionBody);
	const [characteristics, setCharacteristics] = useState<GetCharacteristicsResponse>(undefined);

	useEffect(() => {
		getCharacteristics().then().catch();
	}, []);

	/**
	 * Call api to get and save list of characteristics for use in the drop-down inputs on a per-answer basis.
	 *
	 */
	async function getCharacteristics(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			const res = await new CharacteristicsApi(getConfig(props.fullToken)).getCharacteristics({
				limit: 1000000,
				offset: 0,
			});

			setCharacteristics(res);
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	/**
	 * Dynamic onChange for the form fields.
	 *
	 * @param key
	 */
	function dynamicOnChange(key: keyof CreateQuestionBody): ChangeEventHandler<HTMLInputElement> {
		return (e) => {
			setQuestionForm({
				...questionForm,
				[key]: e.target.value,
			});
		}
	}

	/**
	 * Simple onChange helper for the component that manages the Answer Inputs.
	 * @param newAnswers
	 */
	function onAnswersChange(newAnswers: Array<CreateAnswerOptionBody>): void {
		setQuestionForm({
			...questionForm,
			answerOptions: newAnswers,
		});
	}

	/**
	 * Call the api to submit the new Question, send user to Manage Questions/Table page on success.
	 *
	 */
	async function submitNewQuestion(): Promise<void> {
		props.dispatch(incrementLoading());

		try {
			await new QuestionsApi(getConfig(props.fullToken)).createQuestion({
				createQuestionBody: {
					question: questionForm.question || undefined,
					answerOptions: questionForm.answerOptions,
				},
			});

			history.push("/manage-questions")
		} catch (e) {
			props.dispatch(addError(e));
		} finally {
			props.dispatch(decrementLoading());
		}
	}

	return (
		<div>
			<PageHeader>
				<BreadCrumbs
					crumbs={[
						{
							label: "Manage Questions",
							route: "/manage-questions",
						},
						{
							label: "Create New Question",
							route: "/manage-questions/create",
						},
					]}
				/>

				<h3 className="mt-3">
					Create New Question
				</h3>
			</PageHeader>

			<FrameOneContainer>
				<div className="row g-3 mb-4">
					<div className="col col-12">
						<CreateQuestionDetails
							questionString={questionForm.question}
							onChange={dynamicOnChange}
						/>
					</div>

					<div className="col col-12">
						<CreateQuestionAnswers
							answers={questionForm.answerOptions}
							onAnswersChange={onAnswersChange}
							characteristics={characteristics?.characteristics}
						/>
					</div>
				</div>

				<FrameOneButtonActions>
					<FrameButton
						color="darkPurple"
						onClick={submitNewQuestion}
						disabled={questionForm.answerOptions.length < 1}
					>
						Submit Question
					</FrameButton>
				</FrameOneButtonActions>
			</FrameOneContainer>
		</div>
	);
};

export default connect((store: IStore, props: IProps): IProps => {
	return {
		fullToken: store.metaStore.fullToken,
		...props,
	}
})(CreateQuestion);
