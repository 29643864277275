import React, {ReactNode} from "react";
import {Characteristic, CreateAnswerOptionBody} from "client";
import LabelWithIcon from "../LabelWithIcon";
import {FiInfo, FiPlus} from "react-icons/all";
import {Input, Label} from "reactstrap";
import FrameOneCard from "../FrameOneCard";
import CreateQuestionAnswerInput from "./CreateQuestionAnswerInput";
import FrameButton from "../buttons/FrameButton";
import {cloneDeep} from "lodash";
import {defaultCreateAnswerOptionBody} from "../../pages/CreateQuestion";

interface IProps {
	answers: Array<CreateAnswerOptionBody>;
	onAnswersChange: (newAnswers: Array<CreateAnswerOptionBody>) => void;
	characteristics: Array<Characteristic>;
}

const CreateQuestionAnswers: React.FC<IProps> = (props) => {

	/**
	 * Render each answer input & add functionality for onChange & removing.
	 *
	 * @param answerOption
	 * @param i
	 */
	function renderAnswerInput(answerOption: CreateAnswerOptionBody, i: number): ReactNode {
		function onChangeHelper(key: keyof CreateAnswerOptionBody, value): void {
			const clonedAnswers: Array<CreateAnswerOptionBody> = cloneDeep(props.answers);

			// clonedAnswers[i][key] = value;

			clonedAnswers[i] = {
				...clonedAnswers[i],
				[key]: value,
			}
			// clonedAnswers[i]["answer"] = value

			props.onAnswersChange(clonedAnswers);
		}

		function onDeleteHelper(): void {
			const clonedAnswers: Array<CreateAnswerOptionBody> = cloneDeep(props.answers);
			clonedAnswers.splice(i, 1);
			props.onAnswersChange(clonedAnswers);
		}

		return (
			<React.Fragment>
				{i > 0 && (
					<hr/>
				)}

				<CreateQuestionAnswerInput
					key={`answer-option_${i}`}
					answerOption={answerOption}
					onChange={onChangeHelper}
					characteristics={props.characteristics}
					onRemove={onDeleteHelper}
				/>
			</React.Fragment>
		);
	}

	/**
	 * Adds a new default answer into the array.
	 *
	 */
	function onAddAnswer(): void {
		// todo grab the value of the previous answer's characteristic and set it for this new one
		const clonedAnswers: Array<CreateAnswerOptionBody> = cloneDeep(props.answers);
		clonedAnswers.push(defaultCreateAnswerOptionBody);
		props.onAnswersChange(clonedAnswers);
	}

	return (
		<FrameOneCard>
			<LabelWithIcon icon={FiInfo}>
				Answers
			</LabelWithIcon>

			<hr/>

			<div>
				{(props.answers && props.answers.length > 0) ? props.answers.map(renderAnswerInput) : (
					<div className="my-5">
						<p className="empty-message">
							No Answers.
						</p>
					</div>
				)}
			</div>

			<div className="d-flex justify-content-center mt-3">
				<FrameButton
					color="darkPurple"
					icon={FiPlus}
					onClick={onAddAnswer}
				>
					Add Answer
				</FrameButton>
			</div>
		</FrameOneCard>
	);
};

export default CreateQuestionAnswers;
