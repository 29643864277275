"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeoJsonTypeToJSON = exports.GeoJsonTypeFromJSONTyped = exports.GeoJsonTypeFromJSON = exports.GeoJsonType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GeoJsonType;
(function (GeoJsonType) {
    GeoJsonType["Point"] = "Point";
})(GeoJsonType = exports.GeoJsonType || (exports.GeoJsonType = {}));
function GeoJsonTypeFromJSON(json) {
    return GeoJsonTypeFromJSONTyped(json, false);
}
exports.GeoJsonTypeFromJSON = GeoJsonTypeFromJSON;
function GeoJsonTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GeoJsonTypeFromJSONTyped = GeoJsonTypeFromJSONTyped;
function GeoJsonTypeToJSON(value) {
    return value;
}
exports.GeoJsonTypeToJSON = GeoJsonTypeToJSON;
