"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Outside Activities
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: 1.0.3-production
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginatedResponseToJSON = exports.PaginatedResponseFromJSONTyped = exports.PaginatedResponseFromJSON = void 0;
const _1 = require("./");
function PaginatedResponseFromJSON(json) {
    return PaginatedResponseFromJSONTyped(json, false);
}
exports.PaginatedResponseFromJSON = PaginatedResponseFromJSON;
function PaginatedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paginationInfo': _1.PaginationInfoFromJSON(json['paginationInfo']),
    };
}
exports.PaginatedResponseFromJSONTyped = PaginatedResponseFromJSONTyped;
function PaginatedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paginationInfo': _1.PaginationInfoToJSON(value.paginationInfo),
    };
}
exports.PaginatedResponseToJSON = PaginatedResponseToJSON;
